import {
  GraphicLine,
  IGraphicLineConfig,
} from '../../GraphicEditor/graphics/Line'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type ILineConfig = IGraphicLineConfig & IMaterialConfig
export interface Line extends Material {}
export class Line extends GraphicLine {
  constructor(lineConfig: ILineConfig) {
    super({
      ...lineConfig,
    })
    mixinMaterial(this, lineConfig)
  }
}
