import { Node } from 'konva/lib/Node'
import { Transformer, TransformerConfig } from 'konva/lib/shapes/Transformer'

export interface IKonvaTransformerConfig extends TransformerConfig {}

// 锚点。
export enum EAnchor {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right',
  MIDDLE_LEFT = 'middle-left',
  MIDDLE_RIGHT = 'middle-right',
  ROTATE = 'rotater',
}

// 默认锚点大小。
export const DEFAULT_ANCHOR_SIZE = 8

// 水平方向锚点。
export const X_RESIZE_ANCHOR_LIST = [EAnchor.MIDDLE_LEFT, EAnchor.MIDDLE_RIGHT]

// 垂直方向锚点。
export const Y_RESIZE_ANCHOR_LIST = [EAnchor.TOP_CENTER, EAnchor.BOTTOM_CENTER]

// 对角缩放锚点列表。
export const ANGLE_RESIZE_ANCHOR_LIST = [
  EAnchor.TOP_LEFT,
  EAnchor.TOP_RIGHT,
  EAnchor.BOTTOM_LEFT,
  EAnchor.BOTTOM_RIGHT,
]

// 缩放锚点列表。
export const RESIZE_ANCHOR_LIST = [
  ...X_RESIZE_ANCHOR_LIST,
  ...Y_RESIZE_ANCHOR_LIST,
  ...ANGLE_RESIZE_ANCHOR_LIST,
]

// 顶部锚点列表。
export const TOP_ANCHOR_LIST = [
  EAnchor.TOP_CENTER,
  EAnchor.TOP_LEFT,
  EAnchor.TOP_RIGHT,
]

// 左侧锚点列表。
export const LEFT_ANCHOR_LIST = [
  EAnchor.TOP_LEFT,
  EAnchor.BOTTOM_LEFT,
  EAnchor.MIDDLE_LEFT,
]

// 右侧锚点列表。
export const RIGHT_ANCHOR_LIST = [
  EAnchor.TOP_RIGHT,
  EAnchor.BOTTOM_RIGHT,
  EAnchor.MIDDLE_RIGHT,
]

// 底部锚点列表。
export const BOTTOM_ANCHOR_LIST = [
  EAnchor.BOTTOM_CENTER,
  EAnchor.BOTTOM_LEFT,
  EAnchor.BOTTOM_RIGHT,
]

export class KonvaTransformer extends Transformer {
  constructor(transformerConfig: IKonvaTransformerConfig = {}) {
    super({
      resizeEnabled: false,
      borderEnabled: false,
      rotationSnaps: [0, 90, 180, 270],
      anchorSize: DEFAULT_ANCHOR_SIZE,
      rotateEnabled: false,
      anchorCornerRadius: 0,
      ignoreStroke: true,
      borderStrokeWidth: 1,
      anchorStrokeWidth: 1,
      rotateAnchorOffset: 20,
      flipEnabled: false,
      rotationSnapTolerance: 5,
      keepRatio: true,
      ...transformerConfig,
    })
  }

  // 添加节点。
  addNode(...nodes: Node[]) {
    const allNodes = this.nodes()
    nodes = nodes.filter((node) => !allNodes.includes(node))

    this.nodes([...allNodes, ...nodes])
  }

  // 是否包含节点。
  includes(node: Node) {
    return this.nodes().includes(node)
  }

  // 移除节点。
  removeNode(...nodes: Node[]) {
    nodes = this.nodes().filter((node) => !nodes.includes(node))

    this.nodes(nodes)
  }

  // 获取当前激活的锚点。
  getActiveAnchor() {
    return super.getActiveAnchor() as EAnchor
  }

  // 是否是 x 轴缩放
  get isResizeX() {
    return X_RESIZE_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是 y 轴缩放
  get isResizeY() {
    return Y_RESIZE_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是缩放。
  get isResize() {
    return RESIZE_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是在左上角缩放。
  get isResizeTopLeft() {
    return this.getActiveAnchor() === EAnchor.TOP_LEFT
  }

  // 是否是在右上角缩放。
  get isResizeTopRight() {
    return this.getActiveAnchor() === EAnchor.TOP_RIGHT
  }

  // 是否是在左下角缩放。
  get isResizeBottomLeft() {
    return this.getActiveAnchor() === EAnchor.BOTTOM_LEFT
  }

  // 是否是在右下角缩放。
  get isResizeBottomRight() {
    return this.getActiveAnchor() === EAnchor.BOTTOM_RIGHT
  }

  // 是否是对角缩放。
  get isResizeAngle() {
    return ANGLE_RESIZE_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是旋转。
  get isRotate() {
    return this.getActiveAnchor() === EAnchor.ROTATE
  }

  // 是否是顶部锚点。
  get isTopAnchor() {
    return TOP_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是左侧锚点。
  get isLeftAnchor() {
    return LEFT_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是右侧锚点。
  get isRightAnchor() {
    return RIGHT_ANCHOR_LIST.includes(this.getActiveAnchor())
  }

  // 是否是底部锚点。
  get isBottomAnchor() {
    return BOTTOM_ANCHOR_LIST.includes(this.getActiveAnchor())
  }
}
