import { first, last } from 'lodash-es'

// 监听dom尺寸变化
export const onResize = (dom: Element, cb: (rect: DOMRectReadOnly) => void) => {
  // 创建一个 ResizeObserver 实例
  const observer = new ResizeObserver((entries) => {
    cb(first(entries)!.contentRect)
  })

  observer.observe(dom)

  return observer.disconnect.bind(observer)
}

// 监听元素是否可见
export const onVisible = (
  el: Element,
  cb: (visible: boolean) => void,
  options?: IntersectionObserverInit,
) => {
  const observer = new IntersectionObserver((entries) => {
    const lastEntry = last(entries)

    cb(lastEntry?.isIntersecting ?? false)
  }, options)
  observer.observe(el)

  return observer.disconnect.bind(observer)
}

// 请求每帧执行回调
export const onRequestAnimationFrame = (
  cb: () => void,
  { count = Number.MAX_SAFE_INTEGER, immediate = false } = {},
) => {
  let id: number
  let _count = count
  let currentCount = 0

  const callback = () => {
    cb()
    currentCount += 1

    stop()
    if (currentCount <= _count) {
      id = requestAnimationFrame(callback)
    }
  }

  const start = (newCount = count) => {
    stop()

    _count = newCount
    currentCount = 0
    id = requestAnimationFrame(callback)
  }

  const stop = () => {
    cancelAnimationFrame(id)
  }

  if (immediate) {
    start()
  }

  return [start, stop]
}
