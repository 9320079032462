import { partialRight } from 'lodash-es'

export const clipboard = (string: string, op: 'copy' | 'paste') => {
  string = String(string)
  const fakeElem = document.createElement('textarea')
  fakeElem.style.position = 'absolute'
  fakeElem.style.left = '-999999px'
  fakeElem.value = string

  document.documentElement.appendChild(fakeElem)
  fakeElem.setAttribute('readonly', '')
  fakeElem.select()
  fakeElem.setSelectionRange(0, string.length)
  fakeElem.removeAttribute('readonly')
  const res = document.execCommand(op)

  document.documentElement.removeChild(fakeElem)

  return res
}

export const copy = partialRight(clipboard, 'copy')

export const paste = partialRight(clipboard, 'paste')
