import { mixinBackground } from './Background'
import { IRectConfig, Rect } from './Rect'

export type IPureBackgroundConfig = IRectConfig

export class PureBackground extends Rect {
  constructor(pureBackgroundConfig: IPureBackgroundConfig) {
    super({
      ...pureBackgroundConfig,
    })
    mixinBackground(this)
  }
}
