import { Nullable, Optional } from '@lanyan/type'
import { Util } from 'konva/lib/Util'
import { isEmpty, isEqual, pick, toArray } from 'lodash-es'

import { EAlign, EFillPriority, EVerticalAlign } from '../config'
import { getLinearGradientParamsByRect } from '../util'

const BREAK = '\n' // 换行符。
const SPACE = ' ' // 空格符。
const SPACE_FONT_SIZE_RATIO = 0.535 // 空白字符字体大小比例。
const TEXT_WIDTH_MAP: Record<string, Record<string, number>> = {} // 文字宽度映射。
let dummyContext: Nullable<CanvasRenderingContext2D>

// 求和。
const sum = (arr: number[]) => {
  let sum = 0
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i]
  }

  return sum
}
export enum ETextAttr {
  FILL = 'fill',
  FONT_FAMILY = 'fontFamily',
  FONT_SIZE = 'fontSize',
  FONT_STYLE = 'fontStyle',
  TEXT_DECORATION = 'textDecoration',
  LINE_HEIGHT = 'lineHeight',
  LETTER_SPACING = 'letterSpacing',
  LINEAR_GRADIENT_COLOR_STOPS = 'linearGradientColorStops',
  LINEAR_GRADIENT_DEGREE = 'linearGradientDegree',
  FILL_PRIORITY = 'fillPriority',
}

export interface TextListItem {
  text: string
  fill: string
  fontFamily: string
  fontSize: number
  fontStyle: string
  textDecoration: string
  lineHeight: number
  letterSpacing: number
  linearGradientColorStops: [number, string][]
  linearGradientDegree: number
  fillPriority: `${EFillPriority}`
}

export interface TextMatrixInfo {
  textItem: TextListItem
  baseline: number
  x: number
  y: number
  width: number
  lineHeightPX: number
  letterSpacingPX: number
  text: string
  index: number
}

// 获取文字矩阵。
export const getTextMatrix = (
  textList: TextListItem[],
  {
    containerWidth = 0,
    containerHeight = 0,
    align,
    verticalAlign,
    checkFont,
  }: {
    containerWidth?: number
    containerHeight?: number
    align: `${EAlign}`
    verticalAlign: `${EVerticalAlign}`
    checkFont?: (fontFamily: string, char: string) => boolean
  },
) => {
  const textMatrix: TextMatrixInfo[][] = []

  // 当前文本行。
  let curTextRow: (typeof textMatrix)[number][number][] = []

  // 当前文本行 x 值。
  let curTextRowX = 0
  for (let i = 0; i < textList.length; i++) {
    const textItem = textList[i]
    const { text, lineHeight, fontSize, letterSpacing } = textItem

    // 当前文字对象。
    const curText = {
      textItem,
      baseline: 0,
      width: getTextWidth(textItem, { checkFont }),
      lineHeightPX: fontSize * lineHeight,
      letterSpacingPX: /^\s*$/.test(text) ? 0 : letterSpacing * fontSize,
      x: curTextRowX,
      y: 0,
      text: textItem.text,
      index: i,
    }

    if (text === BREAK) {
      // 处理回车。

      // 当前文本行的最后一个字。
      const lastText = curTextRow[curTextRow.length - 1]
      if (lastText) {
        // 文本行的最后一个字不需要字间距。
        lastText.width -= lastText.letterSpacingPX

        // 当前回车字的 x 值也要减去最后一个字的字间距。
        curText.x -= lastText.letterSpacingPX
      }

      // 添加当前文本行到字矩阵。
      textMatrix.push([...curTextRow, curText])

      // 开始新的文本行。
      curTextRow = []

      // 新的文本行 x 值从 0 开始
      curTextRowX = 0
    } else if (containerWidth && curTextRowX + curText.width > containerWidth) {
      // 处理自动换行。

      // 当前文本行的最后一个字。
      const lastText = curTextRow[curTextRow.length - 1]
      if (lastText) {
        // 文本行的最后一个字不需要字间距。
        lastText.width -= lastText.letterSpacingPX

        // 添加当前文本行到字矩阵。
        textMatrix.push(curTextRow)

        // 添加自己到新的一行。
        curTextRow = [curText]

        // 因为是新的一行，所以当前字 x 值从 0 开始
        curText.x = 0
      } else {
        // 添加自己到新的文本行并添加到字矩阵。
        textMatrix.push([curText])
      }

      // 当前字是当前文本行第一个字需要加上字间距宽度。
      curText.width = curText.width + curText.letterSpacingPX

      // 当前文本行的 x 值从当前字的宽度开始
      curTextRowX = curText.width
    } else {
      // 正常情况。

      // 添加自己到当前文本行。
      curTextRow.push(curText)

      // 当前字宽度加上字间距宽度。
      curText.width += curText.letterSpacingPX

      // 更新当前文本行的 x 值
      curTextRowX += curText.width
    }
  }

  // 最后的文本行的最后一个字。
  let lastRowLastText = curTextRow[curTextRow.length - 1]
  if (lastRowLastText) {
    // 文本行的最后一个字不需要字间距。
    lastRowLastText.width -= lastRowLastText.letterSpacingPX

    // 添加当前行到文字矩阵。
    textMatrix.push(curTextRow)
  }

  // 最后的文本行的最后一个字。
  lastRowLastText =
    textMatrix[textMatrix.length - 1][
      textMatrix[textMatrix.length - 1].length - 1
    ]
  if (lastRowLastText.text === BREAK) {
    // 如果最后的文行的最后一个字是回车，需要在下一行添加一个空的字符串，用来让用户继续输入。

    // 获取最后一个字的样式。
    const emptyTextStyle = getTextStyleByIndex(textList, textList.length - 1)

    // 创建一个空的文字对象。
    const emptyText = {
      text: '',
      ...emptyTextStyle,
    }

    textMatrix.push([
      {
        textItem: emptyText,
        baseline: 0,
        width: 0,
        lineHeightPX: emptyText.lineHeight * emptyText.fontSize,
        letterSpacingPX: 0,
        x: 0,
        y: 0,
        text: emptyText.text,
        index: textList.length,
      },
    ])
  }

  // 文字容器宽度，如果没有指定则取文字矩阵中最宽的数据。
  containerWidth =
    containerWidth ||
    Math.max(...textMatrix.map((row) => sum(row.map(({ width }) => width))))

  const textHeight = sum(
    textMatrix.map(
      (row) => Math.max(...row.map(({ lineHeightPX }) => lineHeightPX))!,
    ),
  )

  // 当前文本行的 y 值。
  let curTextRowY = 0
  if (verticalAlign === EVerticalAlign.MIDDLE) {
    curTextRowY = Math.max(0, (containerHeight - textHeight) / 2)
  } else if (verticalAlign === EVerticalAlign.BOTTOM) {
    curTextRowY = Math.max(0, containerHeight - textHeight)
  }

  for (let i = 0; i < textMatrix.length; i++) {
    // 当前文本行。
    const curTextRow = textMatrix[i]

    // 当前文本行的宽度。
    const curTextRowWidth = sum(curTextRow.map(({ width }) => width))

    // 当前文本行的最大行高的文字。
    let maxLineHeightText: Optional<TextMatrixInfo> = undefined
    for (let j = 0; j < curTextRow.length; j++) {
      const curText = curTextRow[j]
      if (
        !maxLineHeightText ||
        curText.lineHeightPX > maxLineHeightText.lineHeightPX
      ) {
        maxLineHeightText = curText
      }
    }

    // 当前行文字的基线位置。
    const curRowBaseline =
      curTextRowY +
      (maxLineHeightText!.lineHeightPX / 2 +
        maxLineHeightText!.textItem.fontSize * 0.38)

    // 当前文本行的剩余间距。
    let curTextRowRemainSpacing = 0

    // 计算左右对齐时的剩余间距。
    if (align === EAlign.CENTER) {
      // 当居中时，计算当前文本行的剩余间距。
      curTextRowRemainSpacing = Math.max(
        0,
        (containerWidth - curTextRowWidth) / 2,
      )
    } else if (align === EAlign.RIGHT) {
      // 当右对齐时，计算当前文本行的剩余间距。
      curTextRowRemainSpacing = Math.max(0, containerWidth - curTextRowWidth)
    }

    for (let j = 0; j < curTextRow.length; j++) {
      // 当前文字对象。
      const curText = curTextRow[j]

      // 更新当前文本行的 x 值。
      curText.x += curTextRowRemainSpacing

      // 更新当前文本行的 y 值。
      curText.y = curTextRowY

      // 设置文字的基线。
      curText.baseline = curRowBaseline
    }

    // 更新当前文本行的 y 值。
    curTextRowY += maxLineHeightText!.lineHeightPX
  }

  return textMatrix
}

// 通过索引获取文字样式。
export const getTextStyleByIndex = (
  textList: TextListItem[],
  index: number,
  textStyle: Partial<Omit<TextListItem, 'text'>> = {},
) => {
  let textItem = textList[0]
  for (let i = index - 1; i > 0; i--) {
    const text = textList[i]?.text
    if (text === undefined) {
      break
    }

    if (text === BREAK) {
      continue
    }

    textItem = textList[i]

    break
  }

  return {
    fill: textItem.fill,
    fontFamily: textItem.fontFamily,
    fontSize: textItem.fontSize,
    fontStyle: textItem.fontStyle,
    textDecoration: textItem.textDecoration,
    lineHeight: textItem.lineHeight,
    letterSpacing: textItem.letterSpacing,
    linearGradientColorStops: textItem.linearGradientColorStops,
    linearGradientDegree: textItem.linearGradientDegree,
    fillPriority: textItem.fillPriority,
    ...textStyle,
  }
}

// 获取 canvas font 字符串
export const getContextFont = (textItem: TextListItem) => {
  return `${textItem.fontStyle} ${textItem.fontSize}px ${textItem.fontFamily}`
}

// 创建线性渐变。
export const createLinearGradient = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  colorStops: [number, string][],
) => {
  const ctx = dummyContext ?? Util.createCanvasElement().getContext('2d')!
  const grd = ctx.createLinearGradient(startX, startY, endX, endY)
  for (let n = 0; n < colorStops.length; n += 1) {
    grd.addColorStop(colorStops[n][0], colorStops[n][1])
  }

  return grd
}

// 获取文字宽度。
const getTextWidth = (
  textItem: TextListItem,
  {
    checkFont,
  }: {
    checkFont?: (fontFamily: string, char: string) => boolean
  } = {},
) => {
  const { text, fontFamily, fontSize, fontStyle } = textItem

  // 是否已经加载字体。
  const isLoad = checkFont?.(fontFamily, text) ?? true

  // 是否是斜体。
  const isItalic = fontStyle.includes('italic')

  // 如果是换行则直接返回 0。
  if (text === BREAK) {
    return 0
  }

  if (text === SPACE) {
    // 如果是空格则返回空格固定宽度。
    return fontSize * SPACE_FONT_SIZE_RATIO
  }

  const cacheKey = `${isLoad}${fontSize}${fontFamily}${isItalic}`
  let width = TEXT_WIDTH_MAP[text]?.[cacheKey]

  if (!width) {
    const ctx = dummyContext ?? Util.createCanvasElement().getContext('2d')!
    ctx.save()
    ctx.font = getContextFont(textItem)
    const metrics = ctx.measureText(text)
    ctx.restore()

    if (isItalic) {
      width = Math.max(
        metrics.width,
        metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight,
      )
    } else {
      width = metrics.width
    }
  }

  TEXT_WIDTH_MAP[text] = { [cacheKey]: width }

  return width
}

// 获取字体和文字的映射。
export const getFontFamilyTextMap = (textList: TextListItem[]) => {
  return textList.reduce<Record<string, string>>(
    (fontFamilyTextMap, textItem) => {
      fontFamilyTextMap[textItem.fontFamily!] ??= ''
      fontFamilyTextMap[textItem.fontFamily!] += textItem.text

      return fontFamilyTextMap
    },
    {},
  )
}

// 扁平化文字列表。
export const flatTextList = (textList: TextListItem[]) => {
  return textList.reduce<TextListItem[]>((textList, t) => {
    return [
      ...textList,
      ...(t.text ? toArray(t.text) : [t.text]).map((text) => {
        return {
          ...t,
          text,
        }
      }),
    ]
  }, [])
}

// 获取线性渐变字符串。
export const getLinearGradientString = (textMatrixInfo: TextMatrixInfo) => {
  if (isEmpty(textMatrixInfo.textItem.linearGradientColorStops)) {
    return ''
  }

  return JSON.stringify(
    pick(textMatrixInfo.textItem, [
      'linearGradientColorStops',
      'linearGradientDegree',
    ]),
  )
}

// 分割文字矩阵，目的是将连续且相同的线性渐变文字分割成一组。
export const splitArray = (textMatrix: TextMatrixInfo[]) => {
  if (textMatrix.length === 0) {
    return []
  }

  const result: TextMatrixInfo[][] = []
  let currentArray = [textMatrix[0]]

  for (let i = 1; i < textMatrix.length; i++) {
    if (
      isEqual(
        textMatrix[i].textItem.linearGradientColorStops,
        textMatrix[i - 1].textItem.linearGradientColorStops,
      ) &&
      textMatrix[i].textItem.linearGradientDegree ===
        textMatrix[i - 1].textItem.linearGradientDegree &&
      textMatrix[i].textItem.fillPriority === EFillPriority.LINEAR_GRADIENT &&
      textMatrix[i - 1].textItem.fillPriority === EFillPriority.LINEAR_GRADIENT
    ) {
      currentArray.push(textMatrix[i])
    } else {
      result.push(currentArray)
      currentArray = [textMatrix[i]]
    }
  }

  result.push(currentArray)

  return result
}

const MAP1: Record<
  string,
  {
    startX: number
    startY: number
    endX: number
    endY: number
    colorStops: [number, string][]
  }
> = {}

const MAP2: Record<string, CanvasGradient> = {}

// 获取文字索引和线性渐变映射。
export const getTextIndexLinearGradientMap = (
  textMatrix: TextMatrixInfo[][],
) => {
  const width = Math.max(
    ...textMatrix.map((row) => sum(row.map(({ width }) => width))),
  )
  const splitted = textMatrix.map((row) => splitArray(row))

  const textIndexGradientMap: Record<string, CanvasGradient> = {}
  for (let i = 0; i < splitted.length; i++) {
    const row = splitted[i]
    for (let j = 0; j < row.length; j++) {
      const { linearGradientColorStops, linearGradientDegree } =
        row[j][0].textItem
      const indexStr = row[j].map(({ index }) => index).toString()
      if (
        !isEmpty(linearGradientColorStops) &&
        !textIndexGradientMap[indexStr]
      ) {
        const rect = {
          x: 0,
          y: 0,
          width,
          height: Math.max(...row[j].map(({ lineHeightPX }) => lineHeightPX)),
          degree: linearGradientDegree,
          colorStops: linearGradientColorStops,
        }
        let cacheKey = JSON.stringify(rect)
        const { startX, startY, endX, endY, colorStops } =
          MAP1[cacheKey] ?? getLinearGradientParamsByRect(rect)
        MAP1[cacheKey] = { startX, startY, endX, endY, colorStops }
        const params = {
          startX,
          startY,
          endX,
          endY,
          colorStops,
        }
        cacheKey = JSON.stringify(params)
        textIndexGradientMap[indexStr] =
          MAP2[cacheKey] ??
          createLinearGradient(startX, startY, endX, endY, colorStops)
        MAP2[cacheKey] = textIndexGradientMap[indexStr]
      }
    }
  }

  return textIndexGradientMap
}
