import { EExtension } from './mime'

// 字体格式
export enum EFontFormat {
  TTF = EExtension.TTF,
  WOFF = EExtension.WOFF,
  WOFF2 = EExtension.WOFF2,
  OTF = EExtension.OTF,
}

// 字体风格
export enum EFontStyle {
  YUAN = 'yuanti',
  HEI = 'heiti',
  KAI = 'kaiti',
  SONG = 'songti',
  FANG_SONG = 'fangsong',
  XING = 'xingshu',
  LI = 'lishu',
  CAO = 'caoshu',
  SHOU_XIE = 'shouxieti',
  CHUANG_YI = 'chuangyiziti',
  OTHER = 'other',
}

// 字体风格数组
export const FONT_STYLES = [
  EFontStyle.YUAN,
  EFontStyle.HEI,
  EFontStyle.KAI,
  EFontStyle.SONG,
  EFontStyle.FANG_SONG,
  EFontStyle.XING,
  EFontStyle.LI,
  EFontStyle.CAO,
  EFontStyle.SHOU_XIE,
  EFontStyle.CHUANG_YI,
  EFontStyle.OTHER,
]

// 字体风格文字映射
export const FONT_STYLE_TEXT_MAP = {
  [EFontStyle.YUAN]: '圆体',
  [EFontStyle.HEI]: '黑体',
  [EFontStyle.KAI]: '楷体',
  [EFontStyle.SONG]: '宋体',
  [EFontStyle.FANG_SONG]: '仿宋',
  [EFontStyle.XING]: '行书',
  [EFontStyle.LI]: '隶书',
  [EFontStyle.CAO]: '草书',
  [EFontStyle.SHOU_XIE]: '手写体',
  [EFontStyle.CHUANG_YI]: '创意字体',
  [EFontStyle.OTHER]: '其他',
} as const

// 字体语言
export enum EFontLanguage {
  ZH_CN = 'zh-cn',
  EN = 'en',
  ZH_TW = 'zh-tw',
  JA = 'ja',
  KO = 'ko',
  OTHER = 'other',
}

// 字体语言数组
export const FONT_LANGUAGES = [
  EFontLanguage.ZH_CN,
  EFontLanguage.EN,
  EFontLanguage.ZH_TW,
  EFontLanguage.JA,
  EFontLanguage.KO,
  EFontLanguage.OTHER,
]

// 字体语言文字映射
export const FONT_LANGUAGE_TEXT_MAP = {
  [EFontLanguage.ZH_CN]: '简体中文',
  [EFontLanguage.ZH_TW]: '繁体中文',
  [EFontLanguage.EN]: '英文',
  [EFontLanguage.JA]: '日文',
  [EFontLanguage.KO]: '韩文',
  [EFontLanguage.OTHER]: '其他',
} as const

// 字体格式列表
export const FONT_FORMATS = [
  EFontFormat.TTF,
  EFontFormat.OTF,
  EFontFormat.WOFF,
  EFontFormat.WOFF2,
]

// OSS字体文件目录
export const FONT_OSS_FOLDER = 'assets/fonts'

// 字体列表元数据文件名
export const FONT_META_NAME = 'meta.json'
