import { EExtension, EXTENSION_MIME_MAP } from '@lanyan/constant/src/mime'
import { Optional } from '@lanyan/type'
import to from 'await-to-js'
import axios from 'axios'
import { saveAs } from 'file-saver'

import { getFileExtension, usePromise } from './helper'
import { isUrl } from './predicate'

// 读取文件
export const readFile = ({
  accept = '',
  multiple = false,
}: {
  accept: string
  multiple?: boolean
}) => {
  const INPUT_ID = 'input-for-read-file'
  const { promise, resolve } = usePromise<File[]>()
  let $input = document.getElementById(
    `#${INPUT_ID}`,
  ) as Optional<HTMLInputElement>

  if (!$input) {
    $input = document.createElement('input')

    $input.style.display = 'none'

    $input.id = INPUT_ID

    $input.type = 'file'

    $input.setAttribute('type', 'file')

    const onChange = () => {
      const files = ($input!.files ?? []) as File[]

      resolve(files)

      document.body.removeChild($input!)

      $input!.removeEventListener('change', onChange)
    }

    $input.addEventListener('change', onChange)

    document.body.appendChild($input)
  }

  $input.accept = accept

  $input.multiple = multiple

  $input.click()

  return promise
}

// 下载文件
export const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a')

  link.href = url

  link.target = '_blank'

  link.download = fileName

  document.body.append(link)

  link.click()

  document.body.removeChild(link)
}

// 从base64格式的数据url中获取MIME类型
const getMimeTypeFromBase64Url = (base64DataUrl: string) => {
  const matches = base64DataUrl.match(/^data:([A-Za-z-+/]+);base64/)

  if (matches && matches.length > 1) {
    return matches[1]
  }

  return null
}

// 从base64Url中获取base64数据
const getBase64FromBase64Url = (base64DataUrl: string) => {
  return base64DataUrl.split(',').pop()
}

// 将base64格式的数据转换为Uint8Array类型
const base64ToUnit8Arr = (base64Data: string) => {
  const binaryStr = window.atob(base64Data)
  const bytes = Array.from(binaryStr, (char) => char.charCodeAt(0))

  return new Uint8Array(bytes)
}

// 将base64格式的数据url转换为File类型
export const base64UrlToFile = ({
  base64DataUrl,
  filename = `${+new Date()}`,
}: {
  base64DataUrl: string
  filename: string
}) => {
  // 从base64格式的数据url中获取MIME类型
  const mimeType = getMimeTypeFromBase64Url(base64DataUrl)

  // 从base64Url中获取base64数据
  const base64Data = getBase64FromBase64Url(base64DataUrl)

  if (mimeType && base64Data) {
    // 将base64格式的数据转换为Uint8Array类型
    const unit8Arr = base64ToUnit8Arr(base64Data)

    // 获取文件扩展名
    const extension = mimeType.split('/').pop()

    // 拼接文件名和扩展名
    filename = [filename, extension].join('.')

    // 返回转换后的File类型
    return new File([unit8Arr], filename, { type: mimeType })
  }
}

// 将File类型的文件转换为URL
export const fileToUrl = (file: File) => {
  return URL.createObjectURL(file)
}

// 保存文件
export const saveFile = async (
  file: File | string | Blob,
  { filename }: { filename?: string },
) => {
  if (typeof file === 'string') {
    // url
    if (isUrl(file)) {
      // 是URL且是视频格式的需要发一个请求（业务需要）
      const ext = getFileExtension(file)
      if (ext === EExtension.MP4) {
        const [err, res] = await to(
          axios.get(file, {
            responseType: 'blob',
          }),
        )

        if (err) {
          return
        }

        saveAs(res.data, filename)
      } else {
        saveAs(file, filename)
      }
    } else {
      // 不是url就创建一个blob然后保存
      const blob = new Blob([file], { type: 'text/plain;charset=utf-8' })

      saveAs(blob, filename)
    }
  } else if (file instanceof Blob) {
    // blob就直接调用下载
    saveAs(file, filename)
  } else {
    // 不是string或者blob就报错
    throw new Error('file is not a string or blob')
  }
}

// 字符串转文件
export const stringToFile = (
  str: string,
  {
    fileName = 'file',
    ext = 'txt',
    mime = 'text/plain',
  }: {
    fileName?: string
    ext?: string
    mime?: (typeof EXTENSION_MIME_MAP)[EExtension]
  } = {},
) => {
  const blob = new Blob([str])

  const file = new File([blob], `${fileName}.${ext}`, { type: mime })

  return file
}
