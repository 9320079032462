import { EFileUploadSizeLimit } from '@lanyan/constant/src/common'
import {
  AUDIO_EXTENSIONS,
  AUDIO_MIME,
  EExtension,
  IMAGE_EXTENSIONS,
  IMAGE_MIME,
  PPT_EXTENSIONS,
  PPT_MIME,
  VIDEO_EXTENSIONS,
  VIDEO_MIME,
} from '@lanyan/constant/src/mime'
import { URL_REG } from '@lanyan/constant/src/regExp'
import { AnyObject } from '@lanyan/type'
import {
  ary,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isString,
  negate,
  partial,
} from 'lodash-es'

import {
  isSupportImageBitmap,
  isSupportOffscreenCanvas,
  isSupportWorker,
} from './env'
import { getFileExtension } from './helper'

// 断言函数。
const predicate = <
  T1 extends (src: T2, dst: T3) => boolean,
  T2 extends any,
  T3 extends any,
>(
  fn: T1,
  src: T2,
  dst: T3,
) => fn(isFunction(src) ? src() : src, isFunction(dst) ? dst() : dst)

// 检查两个值是否相等。
export const checkEqual = partial(predicate, isEqual)

// 检查两个值是否不等。
export const checkUnequal = partial(predicate, negate(isEqual))

// 检查两个值是否相等高阶函数版本，接受一个被对比值，返回一个函数，调用这个函数传递需要对比的值。
export const equal = <T extends any>(src: T) => {
  return ary(partial(checkEqual, src), 1)
}

// 检查两个值是否不等高阶函数版本，接受一个被对比值，返回一个函数，调用这个函数传递需要对比的值。
export const unequal = <T extends any>(src: T) => {
  return ary(partial(checkUnequal, src), 1)
}

// 两个数字在一定误差范围内是否相等。
export const isApproximatelyEqual = (
  a: number,
  b: number,
  tolerance: number,
) => {
  return Math.abs(a - b) <= tolerance
}

// 是否是视频。
export const isVideo = (source: string | File | Blob) => {
  if (isString(source)) {
    const fileExtension = getFileExtension(source)

    return VIDEO_EXTENSIONS.includes(
      fileExtension.toLocaleLowerCase() as EExtension,
    )
  }

  return VIDEO_MIME.includes(source.type)
}

// 是否是音频。
export const isAudio = (source: string | File | Blob) => {
  if (isString(source)) {
    const fileExtension = getFileExtension(source)

    return AUDIO_EXTENSIONS.includes(
      fileExtension.toLocaleLowerCase() as EExtension,
    )
  }

  return AUDIO_MIME.includes(source.type)
}

// 是否是图片。
export const isImage = (source: string | File | Blob) => {
  if (isString(source)) {
    const fileExtension = getFileExtension(source)

    return IMAGE_EXTENSIONS.includes(
      fileExtension.toLocaleLowerCase() as EExtension,
    )
  }

  return IMAGE_MIME.includes(source.type)
}

// 是否是图片。
export const isPPT = (source: string | File | Blob) => {
  if (isString(source)) {
    const fileExtension = getFileExtension(source)

    return PPT_EXTENSIONS.includes(fileExtension as EExtension)
  }

  return PPT_MIME.includes(source.type)
}

// 判断两个 url 是否是同一种类的文件
export const areSameFileTypeByUrl = (
  source1: string | File,
  source2: string | File,
) => {
  return (
    (isImage(source1) && isImage(source2)) ||
    (isVideo(source1) && isVideo(source2)) ||
    (isAudio(source1) && isAudio(source2))
  )
}

// 检查媒体某个时间点是否已经缓冲。
export const mediaIsBufferedAtPoint = (
  media: HTMLVideoElement | HTMLAudioElement,
  point: number,
  offset = 2,
) => {
  const { buffered } = media

  for (let i = 0; i < buffered.length; i++) {
    const start = buffered.start(i)
    const end = buffered.end(i)

    if (point >= start && point + offset <= end) {
      return true
    }
  }

  return false
}

// 检查是否是 null，undefined，空对象或空数组
export const isNilOrEmpty = (obj?: AnyObject) => {
  return isNil(obj) || isEmpty(obj)
}

// 检查一个文件大小是否超出限制。
export const checkFileSizeExceeded = (file: File) => {
  let isExceeded = false

  switch (true) {
    case isImage(file): {
      isExceeded = file.size > EFileUploadSizeLimit.IMAGE

      break
    }

    case isVideo(file): {
      isExceeded = file.size > EFileUploadSizeLimit.VIDEO

      break
    }

    case isPPT(file): {
      isExceeded = file.size > EFileUploadSizeLimit.PPT

      break
    }

    default: {
      break
    }
  }

  return isExceeded
}

// 判断是否是 url
export const isUrl = (url: string) => {
  return !!url.match(URL_REG)
}

// 判断是否可以使用 worker 编辑器
export const canUseWorkerEditor = () => {
  return (
    isSupportImageBitmap() && isSupportOffscreenCanvas() && isSupportWorker()
  )
}
