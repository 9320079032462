// 手机号正则。
export const PHONE_REG = /^1[3-9][0-9]{9}$/

// url 正则
export const URL_REG = /^(http|https|ftp):\/\/[^\s/$.?#].[^\s]*$/

// br 标签
export const BR_REG = /<br\s*\/?>/g

// 匹配中文。
export const CHINESE_REG = /[\u4e00-\u9fa5]/g

// emoji 表情
export const EMOJI_REG =
  /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\u2300-\u23FF]|[\u2B50]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g
