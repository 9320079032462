import { isNil } from 'lodash-es'

import { EGraphicEvent } from '../config'
import { KonvaEllipse } from '../konva/Ellipse'
import { roundTo1 } from '../util'
import {
  GraphicCenterBasedShape,
  IGraphicCenterBasedShapeConfig,
} from './CenterBasedShape'

export type IGraphicDissymmetryShapeConfig = IGraphicCenterBasedShapeConfig
export type IGraphicDissymmetryShape = KonvaEllipse

export abstract class GraphicDissymmetryShape<
  T extends IGraphicDissymmetryShape,
> extends GraphicCenterBasedShape<T> {
  constructor(
    graphicDissymmetryShapeConfig: IGraphicDissymmetryShapeConfig & {
      graphic: T
    },
  ) {
    super({
      ...graphicDissymmetryShapeConfig,
    })

    // 处理拖拽。
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      const { x, y } = this.box.position()
      const radius = this.graphic.radius()
      this.graphic.position({
        x: x + radius.x,
        y: y + radius.y,
      })
    })

    // 处理缩放。
    this.on(EGraphicEvent.RESIZE, () => {
      const { width, height } = this.calcActualRectBox
      const radiusX = this.graphicEditor!.actualSizeToLogicalSize(width) / 2
      const radiusY = this.graphicEditor!.actualSizeToLogicalSize(height) / 2
      this.graphic.setAttrs({
        x: this.box.x() + radiusX,
        y: this.box.y() + radiusY,
        scaleX: 1,
        scaleY: 1,
        radiusX,
        radiusY,
      })
    })

    this.on(EGraphicEvent.ADD_TO, () => {
      const { width = 0, height = 0 } = graphicDissymmetryShapeConfig
      const radiusX = width / 2
      const radiusY = height / 2
      this.graphic.setAttrs({
        x: radiusX,
        y: radiusY,
        radiusX,
        radiusY,
      })
    })
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      return super.x()
    }

    x = super.x(x)
    this.graphic.x(this.box.x() + this.graphic.radiusX())

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      return super.y()
    }

    y = super.y(y)
    this.graphic.y(this.box.y() + this.graphic.radiusY())

    return y
  }

  // 重写父类的 width 方法。
  width(width?: number) {
    if (isNil(width)) {
      return roundTo1(this.graphic.radiusX() * 2)
    }

    super.width(width)
    const radiusX = width / 2
    this.graphic.radiusX(radiusX)
    this.graphic.x(this.box.x() + radiusX)

    return width
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    if (isNil(height)) {
      return roundTo1(this.graphic.radiusY() * 2)
    }

    super.height(height)
    const radiusY = height / 2
    this.graphic.radiusY(radiusY)
    this.graphic.y(this.box.y() + radiusY)

    return height
  }
}
