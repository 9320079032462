import 'dayjs/locale/zh-cn'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.locale('zh-cn')

// 日期单位枚举
enum DateUnit {
  YEAR = 'YYYY',
  MONTH = 'MM',
  DAY = 'DD',
  HOUR = 'HH',
  MINUTE = 'mm',
  SECOND = 'ss',
}

// 日期单位到 dayjs duration函数名称的映射
const DATE_UNIT_DURATION_CALL_NAME_MAP = {
  [DateUnit.YEAR]: 'years',
  [DateUnit.MONTH]: 'months',
  [DateUnit.DAY]: 'days',
  [DateUnit.HOUR]: 'hours',
  [DateUnit.MINUTE]: 'minutes',
  [DateUnit.SECOND]: 'seconds',
} as const

// 格式化毫秒数为时长
export const formatMSToDuration = (
  ms: number,
  { format = 'mm:ss', leadingZero = true } = {},
) => {
  const duration = dayjs.duration(ms)

  return Object.entries(DATE_UNIT_DURATION_CALL_NAME_MAP).reduce(
    (format, [dateUnit, durationCallName]) => {
      let durationStr = `${duration[durationCallName]()}`

      if (leadingZero) {
        switch (dateUnit) {
          case DateUnit.YEAR: {
            break
          }

          default: {
            if (durationStr.length === 1) {
              durationStr = `0${durationStr}`
            }
          }
        }
      }

      return format.replace(dateUnit, durationStr)
    },
    format,
  )
}
