// 键盘修饰符
export enum EKeyboardModifier {
  CTRL = 'ctrl',
  SHIFT = 'shift',
  ALT = 'alt',
  COMMAND = 'command',
}

// 键盘修饰符与鼠标事件key的映射
export const KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP = {
  [EKeyboardModifier.CTRL]: 'ctrlKey',
  [EKeyboardModifier.SHIFT]: 'shiftKey',
  [EKeyboardModifier.ALT]: 'altKey',
  [EKeyboardModifier.COMMAND]: 'metaKey',
} as const

// 鼠标按钮
export enum EMouse {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  AUXILIARY = 'auxiliary',
  BACK = 'back',
  FORWARD = 'forward',
  NONE = 'none',
}

export const MOUSE_BUTTON_MAP = {
  [EMouse.PRIMARY]: 0,
  [EMouse.AUXILIARY]: 1,
  [EMouse.SECONDARY]: 2,
  [EMouse.BACK]: 3,
  [EMouse.FORWARD]: 4,
} as const
export const MOUSE_BUTTONS_MAP = {
  [EMouse.NONE]: 0,
  [EMouse.PRIMARY]: 1,
  [EMouse.AUXILIARY]: 4,
  [EMouse.SECONDARY]: 2,
  [EMouse.BACK]: 8,
  [EMouse.FORWARD]: 16,
} as const
