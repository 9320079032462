import {
  GraphicEllipse,
  IGraphicEllipseConfig,
} from '../../GraphicEditor/graphics/Ellipse'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type IEllipseConfig = IGraphicEllipseConfig & IMaterialConfig
export interface Ellipse extends Material {}
export class Ellipse extends GraphicEllipse {
  constructor(ellipseConfig: IEllipseConfig) {
    super({
      ...ellipseConfig,
    })
    mixinMaterial(this, ellipseConfig)
  }
}
