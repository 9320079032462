import { isNil } from 'lodash-es'

import { EGraphicEvent } from '../config'
import { IKonvaArrowConfig, KonvaArrow } from '../konva/Arrow'
import { GraphicLineShape, IGraphicLineShapeConfig } from './LineShape'

export type IGraphicArrowConfig = IGraphicLineShapeConfig & IKonvaArrowConfig

export class GraphicArrow extends GraphicLineShape<KonvaArrow> {
  constructor(graphicArrowConfig: IGraphicArrowConfig) {
    super({
      ...graphicArrowConfig,
      graphic: new KonvaArrow({
        ...graphicArrowConfig,
      }),
    })

    let pointerAtBeginning = false
    let pointerAtEnding = false
    let points: number[] = []
    this.on(EGraphicEvent.DRAG_POINT_START, () => {
      pointerAtBeginning = this.pointerAtBeginning()
      pointerAtEnding = this.pointerAtEnding()
      points = this.points()
    })
    this.on(EGraphicEvent.DRAG_POINT, () => {
      const [x1, y1, x2, y2] = this.points()
      if (
        (x1 === points[2] && y1 === points[3]) ||
        (x2 === points[0] && y2 === points[1])
      ) {
        points = [x1, y1, x2, y2]
        pointerAtBeginning = this.pointerAtBeginning(!pointerAtBeginning)
        pointerAtEnding = this.pointerAtEnding(!pointerAtEnding)
      }
    })
  }

  points(points?: number[]) {
    if (isNil(points)) {
      return super.points()
    }

    super.points(points)

    return points
  }

  // 设置或获取 pointerAtBeginning
  pointerAtBeginning(pointerAtBeginning?: boolean) {
    if (isNil(pointerAtBeginning)) {
      return this.graphic.pointerAtBeginning()
    }

    this.graphic.pointerAtBeginning(pointerAtBeginning)

    return pointerAtBeginning
  }

  // 设置或获取 pointerAtEnding
  pointerAtEnding(pointerAtEnding?: boolean) {
    if (isNil(pointerAtEnding)) {
      return this.graphic.pointerAtEnding()
    }

    this.graphic.pointerAtEnding(pointerAtEnding)

    return pointerAtEnding
  }

  // 箭头属性。
  get attrs() {
    return {
      ...super.attrs,
      points: this.points(),
      pointerAtBeginning: this.graphic.pointerAtBeginning(),
      pointerAtEnding: this.graphic.pointerAtEnding(),
    }
  }
}
