export interface Background {}

export const mixinBackground = <T extends Background>(target: T) => {
  Object.defineProperty(target, 'operateAttrs', {
    get() {
      return {
        ...target.constructor.prototype.operateAttrs,
        croppable: false,
        draggable: false,
        resizeEnabled: false,
        selectable: false,
      }
    },
    configurable: true,
    enumerable: true,
  })
}
