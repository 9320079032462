import { AxiosRequestConfig } from 'axios'

import { ISystem } from '@/types/system'

import { baseRequest } from '.'

// 使用密码登录
export const loginWithPassword = (
  data: {
    phone: string
    password: string
  },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.post<{ info: ISystem.User; token: string }>(
    '/login/using-password',
    {
      data,
      ...config,
      needAuth: false,
    },
  )
}

// 退出登录
export const logout = (config: Partial<AxiosRequestConfig> = {}) => {
  return baseRequest.put<void>('/logout', {
    ...config,
  })
}
