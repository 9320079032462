import {
  GraphicRect,
  IGraphicRectConfig,
} from '../../GraphicEditor/graphics/Rect'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type IRectConfig = IGraphicRectConfig & IMaterialConfig
export interface Rect extends Material {}
export class Rect extends GraphicRect {
  constructor(rectConfig: IRectConfig) {
    super({
      ...rectConfig,
    })
    mixinMaterial(this, rectConfig)
  }
}
