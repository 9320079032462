import { message } from 'ant-design-vue'
import { defineStore } from 'pinia'

import { loginWithPassword, logout } from '@/api/auth'
import router from '@/router'
import { ISystem } from '@/types/system'
import { schoolUserInfo } from '@/utils/localforage'
import { token } from '@/utils/token'

interface IUserState {
  userInfo?: ISystem.User
}
export const useUserStore = defineStore('user', {
  state: (): IUserState => {
    return {
      userInfo: undefined,
    }
  },
  getters: {},
  actions: {
    async logout() {
      const [loginOutErr] = await logout()

      if (loginOutErr) {
        return message.error(loginOutErr.response?.data.message)
      }

      token.clear()
      this.userInfo = undefined
      schoolUserInfo.clear()
      message.success('退出登录!')
      router.push('/login')
    },
    async loginWithPassword(
      loginForm: Parameters<typeof loginWithPassword>[0],
      redirect?: string,
    ) {
      const [loginWithPasswordErr, loginWithPasswordRes] =
        await loginWithPassword(loginForm)

      if (loginWithPasswordErr) {
        return message.error(loginWithPasswordErr.response?.data.message)
      }

      const tokenStr = loginWithPasswordRes.data.token // 在请求头中获取token
      this.userInfo = loginWithPasswordRes.data.info // 获取用户信息

      await schoolUserInfo.setItem(
        'schoolUserInfo',
        JSON.stringify(this.userInfo),
      ) // 将用户信息存储到本地

      token.set(tokenStr)

      message.success('登录成功!')
      router.push(redirect ?? '/')
    },

    async getUserInfo() {
      const schoolUserInfoStr = (await schoolUserInfo.getItem(
        'schoolUserInfo',
      )) as string

      if (schoolUserInfoStr) {
        this.userInfo = JSON.parse(schoolUserInfoStr)
      }
    },
  },
})
