import { EKeyboardModifier } from '@lanyan/constant'

import { isWindows } from '../env'

// 尺寸类型。
export enum ESizeType {
  HIT = 'hit',
  HOVER = 'hover',
  CALC = 'calc',
  BASE = 'base',
}

// 尺寸类型和实际尺寸信息字段映射。
export const SIZE_TYPE_ACTUAL_RECT_BOX_KEY_MAP = {
  [ESizeType.HIT]: 'hitActualRectBox',
  [ESizeType.HOVER]: 'hoverActualRectBox',
  [ESizeType.CALC]: 'calcActualRectBox',
  [ESizeType.BASE]: 'actualRectBox',
} as const

// 尺寸类型和实际点坐标字段映射。
export const SIZE_TYPE_ACTUAL_POINTS_KEY_MAP = {
  [ESizeType.HIT]: 'hitActualPoints',
  [ESizeType.HOVER]: 'hoverActualPoints',
  [ESizeType.CALC]: 'calcActualPoints',
  [ESizeType.BASE]: 'actualPoints',
} as const

// 图形类型。
export enum EGraphicType {
  IMAGE = 'image',
  VIDEO = 'video',
  RECT = 'rect',
  TEXT = 'text',
}

// 图形事件。
export enum EGraphicEvent {
  // konva 事件。
  DRAG_START = 'dragstart',
  DRAG_MOVE = 'dragmove',
  DRAG_END = 'dragend',
  MOUSE_ENTER = 'mouseenter',
  MOUSE_LEAVE = 'mouseleave',
  MOUSE_DOWN = 'mousedown',
  MOUSE_OVER = 'mouseover',
  MOUSE_MOVE = 'mousemove',
  MOUSE_OUT = 'mouseout',
  MOUSE_UP = 'mouseup',
  CLICK = 'click',
  DOUBLE_CLICK = 'dblclick',
  WHEEL = 'wheel',
  CONTEXTMENU = 'contextmenu',
  TRANSFORM_START = 'transformstart',
  TRANSFORM = 'transform',
  TRANSFORM_END = 'transformend',

  // 自定义事件。

  // 图形层级相关。
  MOVE_UP = 'moveUp', // 移动到上一层。
  MOVE_DOWN = 'moveDown', // 移动到下一层。
  MOVE_TO_BOTTOM = 'moveToBottom', // 移动到底部。
  MOVE_TO_TOP = 'moveToTop', // 移动到顶部。
  INDEX = 'index', // 图形层级变化。

  // 图形缩放或旋转相关。
  RESIZE = 'resize', // 缩放中。
  RESIZE_START = 'resizeStart', // 缩放开始。
  RESIZE_END = 'resizeEnd', // 缩放结束。
  ROTATE_START = 'rotateStart', // 旋转开始。
  ROTATE = 'rotate', // 旋转中。
  ROTATE_END = 'rotateEnd', // 旋转结束。
  DRAG_POINT_START = 'dragPointStart', // 拖拽锚点开始。
  DRAG_POINT = 'dragPoint', // 拖拽锚点。
  DRAG_POINT_END = 'dragPointEnd', // 拖拽锚点结束。

  // 图形基本操作。
  SELECT = 'select', // 选中。
  DESELECT = 'deselect', // 移除选中。
  ADD_SELECTED = 'addSelected', // 继续添加选中。
  REMOVE_SELECTED = 'removeSelected', // 移除已有选中。
  SELECTION_CHANGE = 'selectionChange', // 素材选中状态变化。
  REMOVED = 'removed', // 已删除图形。
  ADDED = 'added', // 已添加图形。
  BEFORE_REMOVE = 'beforeRemove', // 删除图形之前。
  STEP_LEFT = 'stepLeft', // 通过方向键向左移动图形。
  STEP_RIGHT = 'stepRight', // 通过方向键向右移动图形。
  STEP_UP = 'stepUp', // 通过方向键向上移动图形。
  STEP_DOWN = 'stepDown', // 通过方向键向下移动图形。
  STEP_END = 'stepEnd', // 通过方向键移动图形结束。
  COPY = 'copy', // 复制。
  PASTE = 'paste', // 粘贴。
  CUT = 'cut', // 剪切。
  GRAPHIC_SELECTION_CHANGE = 'graphicSelectionChange', // 选中图形变化。
  GROUP = 'group', // 组合。
  UNGROUP = 'ungroup', // 解组。

  // 图形属性变化。
  DIMENSION_CHANGE = 'dimensionChange', // 尺寸变化。
  WIDTH_CHANGE = 'widthChange', // 宽度变化。
  HEIGHT_CHANGE = 'heightChange', // 高度变化。
  POSITION_CHANGE = 'positionChange', // 位置变化。
  X_CHANGE = 'xChange', // x 变化。
  Y_CHANGE = 'yChange', // y 变化。
  CHANGE_CORNER_RADIUS_START = 'changeCornerRadiusStart', // 改变圆角开始。
  CHANGE_CORNER_RADIUS = 'changeCornerRadius', // 改变圆角。
  CHANGE_CORNER_RADIUS_END = 'changeCornerRadiusEnd', // 改变圆角结束。

  // 视频相关。
  PLAYING = 'playing', // 视频播放中。
  WAITING = 'waiting', // 视频等待中。
  CAN_PLAY = 'canplay', // 视频可以播放。
  PAUSE = 'pause', // 视频暂停。
  PLAY = 'play', // 视频播放中。
  SEEKING = 'seeking', // 视频跳转中。
  SEEKED = 'seeked', // 视频跳转结束。
  PROGRESS = 'progress', // 视频加载进度。
  ENDED = 'ended', // 视频播放结束。

  // 媒体相关。
  START_LOAD_MEDIA = 'startLoadMedia', // 开始加载媒体数据。
  LOAD_MEDIA_SUCCESS = 'loadMediaSuccess', // 媒体数据加载完成。
  REPLACE = 'replace', // 替换媒体 url
  DRAW = 'draw', // 绘制。
  GET_URL_SUCCESS = 'getUrlSuccess', // 获取图片或视频的 url 成功。
  GET_URL_FAIL = 'getUrlFail', // 获取图片或视频的 url 失败。
  GETTING_URL = 'gettingUrl', // 正在获取图片或视频的 url
  URL_CHANGE = 'urlChange', // 图片或视频的 url 变化。

  // 文字相关。
  TEXT_CHANGE = 'textChange', // 文本内容改变，每次修改都会触发。
  TEXT_CHANGED = 'textChanged', // 文本内容改变。
  TEXT_CHANGING = 'textChanging', // 文本内容改变，但是触发记录文字操作记录时才触发。
  TEXT_ATTR_CHANGED = 'textATtrChanged', // 文本属性改变。
  TEXT_START_EDIT = 'textStartEdit', // 文本开始编辑。
  TEXT_EXIT_EDIT = 'textExitEdit', // 文本编辑结束。
  TEXT_SELECTION_CHANGE = 'textSelectionChange', // 文字选区变化。
  TEXT_SELECTING = 'textSelecting', // 文字选区正在变化。
  AUTO_HEIGHT = 'autoHeight', // 自动高度。
  AUTO_WIDTH = 'autoWidth', // 自动宽度。
  START_LOAD_FONT = 'startLoadFont', // 开始加载字体。
  LOAD_FONT_SUCCESS = 'loadFontSuccess', // 加载字体成功。
  PASTE_TEXT = 'pasteText', // 粘贴文字。

  // 键盘事件。
  KEY_DOWN = 'keydown', // 键盘按下。
  KEY_UP = 'keyup', // 键盘弹起。
  COPY_STYLE = 'copyStyle', // 复制样式。
  PASTE_STYLE = 'pasteStyle', // 粘贴样式。

  // 编辑器相关。
  EDITOR_SIZE_CHANGE = 'editorResize', // 编辑器尺寸变化。
  ADD_TO = 'addTo', // 添加到编辑器。

  // 图形绘制相关。
  DRAW_COMPLETE = 'drawComplete', // 绘制完成。
  FIRST_DRAW_COMPLETE = 'firstDrawComplete', // 初次绘制完成。

  // 图片相关。
  START_CROP = 'startCrop', // 开始截取。
  EXIT_CROP = 'exitCrop', // 退出截取。
  CROP_RESIZE_START = 'cropResizeStart', // 截取缩放开始。
  CROP_RESIZE = 'cropResize', // 截取缩放中。
  CROP_RESIZE_END = 'cropResizeEnd', // 截取缩放结束。
  CROP_DRAG_START = 'cropDragStart', // 截取拖拽开始。
  CROP_DRAG_MOVE = 'cropDragMove', // 截取拖拽中。
  CROP_DRAG_END = 'cropDragEnd', // 截取拖拽结束。
}

// 参考线。
export enum EAlignmentGuide {
  X = 'X',
  X1 = 'X1',
  X2 = 'X2',
  Y = 'Y',
  Y1 = 'Y1',
  Y2 = 'Y2',
}

// 参考线列表。
export const ALIGNMENT_GUIDES = [
  EAlignmentGuide.X,
  EAlignmentGuide.X1,
  EAlignmentGuide.X2,
  EAlignmentGuide.Y,
  EAlignmentGuide.Y1,
  EAlignmentGuide.Y2,
] as const

// 文字对齐方式。
export enum EAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

// 文字垂直对齐方式。
export enum EVerticalAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

// 文风格字。
export enum EFontStyle {
  BOLD = 'bold',
  UNDER_LINE = 'underline',
  ITALIC = 'italic',
}

// 填充优先级。
export enum EFillPriority {
  COLOR = 'color',
  LINEAR_GRADIENT = 'linear-gradient',
  MIXED = 'mixed',
}

// 填充优先级文本映射。
export const FILL_PRIORITY_TEXT_MAP = {
  [EFillPriority.MIXED]: '混合填充',
  [EFillPriority.COLOR]: '颜色',
  [EFillPriority.LINEAR_GRADIENT]: '线性渐变',
}

// transformer 边框宽度。
export const TRANSFORMER_BORDER_WIDTH = 1

// 锚点大小。
export const ANCHOR_SIZE = 10

// 最小尺寸。
export const MIN_SIZE = 1

// 键盘按键。
export enum EKeyboardKey {
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  ENTER = 'Enter',
  C = 'c',
  X = 'x',
  V = 'v',
  Z = 'z',
  Y = 'y',
  A = 'a',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  ESC = 'Escape',
  G = 'g',
  H = 'h',
  W = 'w',
  F = 'f',
}

// 图形键盘操作。
export enum EKeyboardOperation {
  DELETE = 'delete',
  ENTER = 'enter',
  COPY = 'copy',
  PASTE = 'paste',
  CUT = 'cut',
  UNDO = 'undo',
  REDO = 'redo',
  LEFT = 'left',
  UP = 'up',
  DOWN = 'down',
  RIGHT = 'right',
  ESC = 'esc',
  ALL = 'all',
  SELECT_LEFT = 'selectLeft',
  SELECT_RIGHT = 'selectRight',
  SELECT_UP = 'selectUp',
  SELECT_DOWN = 'selectDown',
  GROUP = 'group',
  UNGROUP = 'ungroup',
  AUTO_HEIGHT = 'autoHeight',
  AUTO_WIDTH = 'autoWidth',
  COPY_STYLE = 'copyStyle',
  PASTE_STYLE = 'pasteStyle',
}

// 键盘操作列表。
export const KEYBOARD_OPERATIONS = Object.values(
  EKeyboardOperation,
) as EKeyboardOperation[]

// 键盘操作与键盘按键的映射。
export const KEYBOARD_OPERATION_KEY_MAP = {
  [EKeyboardOperation.DELETE]: [EKeyboardKey.DELETE, EKeyboardKey.BACKSPACE],
  [EKeyboardOperation.ENTER]: [EKeyboardKey.ENTER],
  [EKeyboardOperation.COPY]: [EKeyboardKey.C],
  [EKeyboardOperation.PASTE]: [EKeyboardKey.V],
  [EKeyboardOperation.CUT]: [EKeyboardKey.X],
  [EKeyboardOperation.UNDO]: [EKeyboardKey.Z],
  [EKeyboardOperation.REDO]: [EKeyboardKey.Y],
  [EKeyboardOperation.LEFT]: [EKeyboardKey.LEFT],
  [EKeyboardOperation.RIGHT]: [EKeyboardKey.RIGHT],
  [EKeyboardOperation.UP]: [EKeyboardKey.UP],
  [EKeyboardOperation.DOWN]: [EKeyboardKey.DOWN],
  [EKeyboardOperation.ESC]: [EKeyboardKey.ESC],
  [EKeyboardOperation.ALL]: [EKeyboardKey.A],
  [EKeyboardOperation.SELECT_LEFT]: [EKeyboardKey.LEFT],
  [EKeyboardOperation.SELECT_RIGHT]: [EKeyboardKey.RIGHT],
  [EKeyboardOperation.SELECT_UP]: [EKeyboardKey.UP],
  [EKeyboardOperation.SELECT_DOWN]: [EKeyboardKey.DOWN],
  [EKeyboardOperation.GROUP]: [EKeyboardKey.G],
  [EKeyboardOperation.UNGROUP]: [EKeyboardKey.G],
  [EKeyboardOperation.AUTO_HEIGHT]: [EKeyboardKey.F],
  [EKeyboardOperation.AUTO_WIDTH]: [EKeyboardKey.A],
  [EKeyboardOperation.COPY_STYLE]: [EKeyboardKey.C],
  [EKeyboardOperation.PASTE_STYLE]: [EKeyboardKey.V],
}

const CTRL = isWindows() ? EKeyboardModifier.CTRL : EKeyboardModifier.COMMAND

// 图形键盘操作与键盘修饰符的映射。
export const KEYBOARD_OPERATION_MODIFIER_LIST_MAP = {
  [EKeyboardOperation.DELETE]: [],
  [EKeyboardOperation.ENTER]: [],
  [EKeyboardOperation.COPY]: [[CTRL]],
  [EKeyboardOperation.PASTE]: [[CTRL]],
  [EKeyboardOperation.CUT]: [[CTRL]],
  [EKeyboardOperation.UNDO]: [[CTRL]],
  [EKeyboardOperation.REDO]: [[CTRL]],
  [EKeyboardOperation.LEFT]: [],
  [EKeyboardOperation.RIGHT]: [],
  [EKeyboardOperation.UP]: [],
  [EKeyboardOperation.DOWN]: [],
  [EKeyboardOperation.ESC]: [],
  [EKeyboardOperation.ALL]: [[CTRL]],
  [EKeyboardOperation.SELECT_LEFT]: [[EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.SELECT_RIGHT]: [[EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.SELECT_UP]: [[EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.SELECT_DOWN]: [[EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.GROUP]: [[CTRL]],
  [EKeyboardOperation.UNGROUP]: [[CTRL, EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.AUTO_HEIGHT]: [[CTRL, EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.AUTO_WIDTH]: [[CTRL, EKeyboardModifier.SHIFT]],
  [EKeyboardOperation.COPY_STYLE]: [[CTRL, EKeyboardModifier.ALT]],
  [EKeyboardOperation.PASTE_STYLE]: [[CTRL, EKeyboardModifier.ALT]],
}

// 鼠标默认样式。
export const DEFAULT_CURSOR = 'default'

// 误差值。
export const TOLERANCE = 5

// 参考线向外延伸距离，这个值是控制编辑区域 6 根参考线显示时向外延伸的距离。
export const ALIGNMENT_GUIDE_EXTEND_DISTANCE = 50

export const COMPARE_CONFIG_FOR_RESIZE = [
  {
    targetPos: 'x',
    comparePos: 'x',
  },
  {
    targetPos: 'x',
    comparePos: 'x2',
  },
  {
    targetPos: 'x1',
    comparePos: 'x',
  },
  {
    targetPos: 'x1',
    comparePos: 'x2',
  },
  {
    targetPos: 'x2',
    comparePos: 'x',
  },
  {
    targetPos: 'x2',
    comparePos: 'x2',
  },
  {
    targetPos: 'y',
    comparePos: 'y',
  },
  {
    targetPos: 'y',
    comparePos: 'y2',
  },
  {
    targetPos: 'y1',
    comparePos: 'y',
  },
  {
    targetPos: 'y1',
    comparePos: 'y2',
  },
  {
    targetPos: 'y2',
    comparePos: 'y',
  },
  {
    targetPos: 'y2',
    comparePos: 'y2',
  },
] as const

export const COMPARE_CONFIG_FOR_DRAG = [
  {
    targetPos: 'x',
    comparePos: 'x',
  },
  {
    targetPos: 'x',
    comparePos: 'x1',
  },
  {
    targetPos: 'x',
    comparePos: 'x2',
  },
  {
    targetPos: 'x1',
    comparePos: 'x',
  },
  {
    targetPos: 'x1',
    comparePos: 'x1',
  },
  {
    targetPos: 'x1',
    comparePos: 'x2',
  },
  {
    targetPos: 'x2',
    comparePos: 'x',
  },
  {
    targetPos: 'x2',
    comparePos: 'x1',
  },
  {
    targetPos: 'x2',
    comparePos: 'x2',
  },
  {
    targetPos: 'y',
    comparePos: 'y',
  },
  {
    targetPos: 'y',
    comparePos: 'y1',
  },
  {
    targetPos: 'y',
    comparePos: 'y2',
  },
  {
    targetPos: 'y1',
    comparePos: 'y',
  },
  {
    targetPos: 'y1',
    comparePos: 'y1',
  },
  {
    targetPos: 'y1',
    comparePos: 'y2',
  },
  {
    targetPos: 'y2',
    comparePos: 'y',
  },
  {
    targetPos: 'y2',
    comparePos: 'y1',
  },
  {
    targetPos: 'y2',
    comparePos: 'y2',
  },
] as const

// 方向列表。
export const DIRECTION_LIST = ['x', 'y'] as const

// 线列表。
export const LINE_LIST = ['', '1', '2'] as const

// 相反的方向映射。
export const REVERSE_DIRECTION_MAP = {
  x: 'y',
  y: 'x',
} as const

// 方向和大小映射。
export const DIRECTION_SIZE_MAP = {
  x: 'width',
  y: 'height',
} as const

// 相反方向和大小映射。
export const REVERSE_DIRECTION_SIZE_MAP = {
  x: 'height',
  y: 'width',
} as const

// 圆角锚点位置。
export enum ECornerRadiusAnchorPosition {
  TOP_LEFT = 0,
  TOP_RIGHT = 1,
  BOTTOM_RIGHT = 2,
  BOTTOM_LEFT = 3,
}

// 圆角锚点和标签的距离。
export const CORNER_RADIUS_ANCHOR_LABEL_DISTANCE = 10
