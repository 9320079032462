import { ECacheTime, FONT_META_NAME, FONT_OSS_FOLDER } from '@lanyan/constant'
import { useUnrepeatable } from '@lanyan/hook/src/useUnrepeatable'
import { Font, IVideo, Optional } from '@lanyan/type'
import to from 'await-to-js'
import axios from 'axios'
import { toArray } from 'lodash-es'

import { isWebWorker } from '../env'
import { usePromise } from '../helper'

// 字体加载方式。
export enum EFontLoadMode {
  WHOLE = 'whole',
  NEEDED = 'needed',
}

// unicodeRange 字符和数组映射。
const unicodeRangeStringArrayMap: Record<
  string,
  Record<string, { start: number; end: number }[]>
> = {}

// 解析 unicodeRange
const parseUnicodeRange = (rule: IFontFaceRule) => {
  // 获取字体规则序号。
  const fontRuleNum = getFontRuleNum(rule.src)
  if (unicodeRangeStringArrayMap[rule.family]?.[fontRuleNum!]) {
    return unicodeRangeStringArrayMap[rule.family][fontRuleNum!]
  }

  const res = rule.range.split(/,\s?/).map((range) => {
    const [start, end] = range
      .trim()
      .split('-')
      .map((part) => parseInt(part.replaceAll('U+', ''), 16))

    return { start, end: end ?? start }
  })

  unicodeRangeStringArrayMap[rule.family] ??= {}
  unicodeRangeStringArrayMap[rule.family][fontRuleNum!] = res

  return res
}

// 检查字符是否在 unicodeRange 中。
export const inUnicodeRange = (char: string, rule: IFontFaceRule) => {
  const unicode = char.codePointAt(0)!

  const res = parseUnicodeRange(rule).some(({ start, end }) => {
    return unicode >= start && unicode <= end
  })

  return res
}

// 获取字体列表 json 文件 url
const getFontsJSONFileUrl = (ossDomain: string) => {
  return [ossDomain, FONT_OSS_FOLDER, FONT_META_NAME].join('/')
}

// 获取全部字体列表。
export const [getAllFonts] = useUnrepeatable(
  async ({ ossDomain }: { ossDomain: string }) => {
    const [, res] = await to(
      axios.get<{
        fonts: Font[]
      }>(getFontsJSONFileUrl(ossDomain), {
        params: {
          t: +new Date(),
        },
        responseType: 'json',
      }),
    )

    return res?.data?.fonts ?? []
  },
  {
    cacheId: 'allFonts',
    cacheTime: ECacheTime.MEMORY,
  },
)

// 获取 FontFace source，之所以需要获取字体规则对应的二进制数据，是为了缓存在内存中，加快字体加载速度。
const [getFontFaceSource] = useUnrepeatable(
  async (url: string) => {
    const [, res] = await to(
      axios.get<ArrayBuffer>(url, {
        responseType: 'arraybuffer',
      }),
    )

    return res?.data ?? `url(${url})`
  },
  {
    cacheId: 'fontFaceSource',
    cacheTime: ECacheTime.MEMORY,

    // loggable: true,
  },
)

// 获取 @font-face 规则列表。
const [getFontFaceRules] = useUnrepeatable(
  async (url: string) => {
    const res = await axios.get<string>(url, {
      responseType: 'text',
    })

    const content = res.data ?? ''
    const fontFaceRules = parseFontFaceRules(content)

    return fontFaceRules
  },
  {
    cacheId: 'fontFaceSource',
    cacheTime: ECacheTime.MEMORY,

    // loggable: true,
  },
)

type IFontFaceRule = {
  display: string
  family: string
  range: string
  src: string
  style: string
  weight: string
}

// 获取 FontFace 规则列表。
const parseFontFaceRules = (fontFaceRuleContent: string) => {
  const fontFaceRules: IFontFaceRule[] = []

  // 匹配 @font-face
  const fontFaceRegex = /@font-face\s*{([^}]+)}/g

  // 匹配 @font-face 规则中的属性。
  const fontFaceAttributesRegex = /(\w+)\s*:\s*([^;]+)/g

  let fontFaceMatch
  while ((fontFaceMatch = fontFaceRegex.exec(fontFaceRuleContent))) {
    const fontFaceRule: Partial<IFontFaceRule> = {}
    const fontFaceDeclaration = fontFaceMatch[1]

    let fontFaceAttributeMatch
    while (
      (fontFaceAttributeMatch =
        fontFaceAttributesRegex.exec(fontFaceDeclaration))
    ) {
      const attributeName =
        fontFaceAttributeMatch[1].trim() as keyof IFontFaceRule
      const attributeValue = fontFaceAttributeMatch[2].trim()
      fontFaceRule[attributeName] = attributeValue
    }

    fontFaceRules.push(fontFaceRule as IFontFaceRule)
  }

  return fontFaceRules.reverse()
}

// 获取场景的字体和文字映射。
const getScenesFontTextMap = (scenes: IVideo.Scene[]) => {
  return scenes
    .map(({ video: { materials } }) => materials)
    .flat()
    .reduce(
      (fontFamilyTextMap, { attrs, text, text_list }) => {
        ;(
          text_list ?? [{ text: text ?? '', font_family: attrs.font_family }]
        ).forEach(({ text, font_family }) => {
          const fontFamily = font_family || attrs.font_family
          if (fontFamily) {
            fontFamilyTextMap[fontFamily] ??= ''
            fontFamilyTextMap[fontFamily] =
              fontFamilyTextMap[fontFamily] + text.trim()
          }
        })

        return fontFamilyTextMap
      },
      {} as Record<string, string>,
    )
}

// 获取场景中字体列表。
export const getSceneFontFamilyList = ({
  scenes,
}: {
  scenes: IVideo.Scene[]
}) => {
  return Object.keys(getScenesFontTextMap(scenes))
}

// 加载场景中所有文本的字体。
export const loadScenesTextFont = async ({
  ossDomain,
  scenes,
}: {
  ossDomain: string
  scenes: IVideo.Scene[]
}) => {
  const scenesFontTextMap = getScenesFontTextMap(scenes)
  const fontFamilies = Object.keys(scenesFontTextMap)
  const res = await Promise.all(
    fontFamilies.map((fontFamily) => {
      return loadTexFont(fontFamily, scenesFontTextMap[fontFamily], {
        ossDomain,
      })
    }),
  )

  return res.every(Boolean)
}

// 获取字体规则序号。
const getFontRuleNum = (src: string) => {
  const fontRuleNum = src.match(/\d+\.woff2/)?.[0].replace('.woff2', '')

  return fontRuleNum
}

// 加载整个字体列表。
export const loadWholeFonts = ({
  ossDomain,
  fontFamilies,
}: {
  ossDomain: string
  fontFamilies: string[]
}) => {
  return Promise.all(
    fontFamilies.map((fontFamily) => {
      return loadWholeFont({
        fontFamily,
        ossDomain,
      })
    }),
  )
}

// 加载 FontFace 规则。
const loadFontFaceRule = async (fontFaceRule: IFontFaceRule, font: Font) => {
  // 获取字体规则地址。
  const fontRuleSrc = getFontRuleSrc(fontFaceRule, font.url)

  // 获取 FontFace source。
  const source = await getFontFaceSource(fontRuleSrc)

  // 创建 FontFace 实例。
  const fontFace = new FontFace(fontFaceRule.family, source, {
    style: fontFaceRule.style,
    weight: fontFaceRule.weight,
    display: fontFaceRule.display as FontDisplay,
    unicodeRange: fontFaceRule.range,
  })

  // 获取全局作用域。
  const globalScope = isWebWorker() ? (self as WorkerGlobalScope) : document

  // 添加 FontFace 实例到 fonts
  globalScope.fonts.add(fontFace)

  // 加载 FontFace
  const [err] = await to(fontFace.load())

  return !err
}

// 字体已加载规则映射。
const fontRuleLoadedMap: Record<
  string,
  Optional<Record<string, Optional<Promise<boolean>>>>
> = {}

// 加载字体规则。
const loadFontRule = async (fontFaceRule: IFontFaceRule, font: Font) => {
  // 获取字体规则序号。
  const fontRuleNum = getFontRuleNum(fontFaceRule.src)

  // 规则序号不存在，直接返回 false。
  if (fontRuleNum === undefined) {
    return false
  }

  // 如果字符对应的字体规则已有加载结果，则直接返回。
  const fontRuleLoaded = fontRuleLoadedMap[font.font]?.[fontRuleNum]
  if (fontRuleLoaded) {
    return fontRuleLoaded
  }

  fontRuleLoadedMap[font.font] ??= {} // 初始化规则映射。
  // 加载字体规则。
  fontRuleLoadedMap[font.font]![fontRuleNum] = loadFontFaceRule(
    fontFaceRule,
    font,
  )

  // 获取加载结果。
  const success = await fontRuleLoadedMap[font.font]![fontRuleNum]!

  return success
}

// 完整的字体加载状态映射。
const wholeFontLoadedMap: Record<
  string,
  Optional<Promise<boolean> | boolean>
> = {}

// 加载整个字体。
export const loadWholeFont = async ({
  ossDomain,
  fontFamily,
}: {
  ossDomain: string
  fontFamily: string
}) => {
  // 如果该字体已有完整的加载结果，则直接返回。
  if (wholeFontLoadedMap[fontFamily] !== undefined) {
    return wholeFontLoadedMap[fontFamily]!
  }

  const { promise, resolve } = usePromise<boolean>()
  wholeFontLoadedMap[fontFamily] = promise

  const allFonts = await getAllFonts({ ossDomain })
  const font = allFonts.find(({ font }) => {
    return fontFamily === font
  })
  if (!font) {
    wholeFontLoadedMap[fontFamily] = false
    resolve(false)

    return false
  }

  // 获取 @font-face 规则列表。
  const fontFaceRules = await getFontFaceRules(font.url)
  const promises = fontFaceRules.map((fontFaceRule) => {
    return loadFontRule(fontFaceRule, font)
  })

  const success = (await Promise.all(promises)).every(Boolean)
  wholeFontLoadedMap[fontFamily] = success
  resolve(success)

  return success
}

// 获取字体规则地址。
const getFontRuleSrc = (fontFaceRule: IFontFaceRule, url: string) => {
  let [fontFilePath] = (fontFaceRule.src as String).split(' ')
  fontFilePath = fontFilePath
    .replace(/^url\("/, '')
    .replace(/"\)$/, '')
    .replace(/^\.\//, '')
  const src = `${url.replace(/\/[^/]*$/, '/')}${fontFilePath}`

  return src
}

// 字体已加载字符映射。
const fontCharLoadedMap: Record<
  string,
  Optional<Record<string, Optional<Promise<boolean> | boolean>>>
> = {}

// 加载字符对应的字体。
export const loadCharFont = async (font: Font, char: string) => {
  // 如果该字符有加载结果，则直接返回。
  if (fontCharLoadedMap[font.font]?.[char] !== undefined) {
    return fontCharLoadedMap[font.font]![char]!
  }

  const { promise, resolve } = usePromise<boolean>()
  fontCharLoadedMap[font.font] ??= {} // 初始化字符加载映射。
  fontCharLoadedMap[font.font]![char] = promise // 设置 promise。

  // 获取 @font-face 规则列表。
  const fontFaceRules = await getFontFaceRules(font.url)

  // 获取字符对应的规则。
  const fontFaceRule = fontFaceRules.find((rule) => {
    return inUnicodeRange(char, rule)
  })

  // 字符对应的规则不存在，直接返回 false。
  if (!fontFaceRule) {
    fontCharLoadedMap[font.font]![char] = false
    resolve(false)

    return false
  }

  // 加载字体规则。
  const success = await loadFontRule(fontFaceRule, font)
  fontCharLoadedMap[font.font]![char] = success
  resolve(success)

  return success
}

// 加载文本对应的字体。
export const loadTexFont = async (
  fontFamily: string,
  text: string,
  {
    fontLoadMode,
    ossDomain,
  }: {
    ossDomain: string
    fontLoadMode?: `${EFontLoadMode}`
  },
) => {
  const textList = [...new Set(toArray(text))]

  // 非重复未加载的字符列表。
  const unloadTextList = textList.filter(
    (char) => !checkTexFont(fontFamily, char),
  )

  // 如果没有未加载的字符，则直接返回 true。
  if (unloadTextList.length === 0) {
    return true
  }

  // 如果加载方式为整个字体，则直接加载整个字体。
  if (fontLoadMode === EFontLoadMode.WHOLE) {
    const success = await loadWholeFont({
      fontFamily,
      ossDomain,
    })

    // 如果整个字体都加载失败了，则直接返回 false。
    if (!success) {
      return false
    }
  }

  // 获取全部字体列表。
  const allFonts = await getAllFonts({ ossDomain })

  // 查找对应字体。
  const font = allFonts.find(({ font }) => {
    return fontFamily === font
  })

  // 字体不存在，直接返回。
  if (!font) {
    return false
  }

  // 加载字符列表并返回未加载的字符。
  const loadResList = await Promise.all(
    unloadTextList.map(async (char) => {
      const success = await loadCharFont(font, char)
      if (success) {
        return ''
      }

      return char
    }),
  )

  // 如果所有字符都未加载，则返回 false。
  if (loadResList.length === textList.length && loadResList.every(Boolean)) {
    return false
  }

  // 返回加载失败的字符列表。
  const loadFailedTextList = loadResList.filter(Boolean)

  // 如果没有加载失败的字符，则返回 true。
  if (loadFailedTextList.length === 0) {
    return true
  }

  // 返回加载失败的字符列表。
  return loadFailedTextList
}

// 检查字体是否加载。
export const checkTexFont = (fontFamily: string, text: string) => {
  if (/^\s+$/.test(text)) {
    return true
  }

  // 非重复的字符列表。
  const textList = text.length === 1 ? [text] : [...new Set(toArray(text))]

  // 如果发现有未加载的字符，则直接返回 false。
  for (let i = 0; i < textList.length; i++) {
    const char = textList[i]
    if (fontCharLoadedMap[fontFamily]?.[char] !== true) {
      return false
    }
  }

  return true
}
