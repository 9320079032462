import { Context } from 'konva/lib/Context'
import { Text, TextConfig } from 'konva/lib/shapes/Text'

import { EFillPriority } from '../config'
import {
  getContextFont,
  getTextIndexLinearGradientMap,
  TextMatrixInfo,
} from './util'

export interface IKonvaTextConfig extends TextConfig {}

export class KonvaText extends Text {
  textMatrix: TextMatrixInfo[][] = []

  constructor(textConfig: IKonvaTextConfig) {
    super(textConfig)
  }

  _sceneFunc(context: Context) {
    if (this.textMatrix.length === 0) {
      return
    }

    context.setAttr('textBaseline', 'alphabetic')
    context.setAttr('textAlign', 'left')
    context.translate(0, 0)

    for (let i = 0; i < this.textMatrix.length; i++) {
      const row = this.textMatrix[i]
      const { baseline } = row[0]

      for (let j = 0; j < row.length; j++) {
        context.save()
        const { textItem, x, text, width, lineHeightPX, index } = row[j]
        const shouldUnderline = textItem.textDecoration === 'underline'
        const shouldLineThrough = textItem.textDecoration === 'line-through'
        const lineWidth = textItem.fontSize / 15 // TODO:根据字体 weight 计算线宽。
        const font = getContextFont(textItem)
        context.setAttr('font', font)

        let fillStyle: string | CanvasGradient | CanvasPattern = 'transparent'
        switch (textItem.fillPriority) {
          case EFillPriority.COLOR: {
            fillStyle = textItem.fill

            break
          }

          case EFillPriority.LINEAR_GRADIENT: {
            const textIndexLinearGradientMap = getTextIndexLinearGradientMap(
              this.textMatrix,
            )
            const indexArr = Object.keys(textIndexLinearGradientMap).find(
              (indexStr) => {
                return indexStr.split(',').includes(`${index}`)
              },
            )
            if (indexArr) {
              fillStyle = textIndexLinearGradientMap[indexArr]
            }
          }

          default: {
            break
          }
        }

        // 绘制下划线。
        if (shouldUnderline) {
          const underLineY = baseline + textItem.fontSize * 0.12

          context.save()
          context.beginPath()
          context.moveTo(x, underLineY)
          context.lineTo(x + width, underLineY)
          context.lineWidth = lineWidth
          context.strokeStyle = fillStyle

          context.stroke()
          context.restore()
        }

        // 绘制删除线。
        if (shouldLineThrough) {
          const lineThroughY = lineHeightPX / 2

          context.save()
          context.beginPath()
          context.moveTo(x, lineThroughY)
          context.lineTo(x + width, lineThroughY)
          context.lineWidth = lineWidth
          context.strokeStyle = fillStyle

          context.stroke()
          context.restore()
        }

        context.fillStyle = fillStyle
        context.fillText(text, x, baseline)
        context.restore()
      }
    }
  }
}
