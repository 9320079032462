import { isNil } from 'lodash-es'

import { KonvaCircle } from '../konva/Circle'
import { KonvaEllipse } from '../konva/Ellipse'
import { KonvaRegularPolygon } from '../konva/RegularPolygon'
import { IPos } from '../type'
import { GraphicShape, IGraphicShapeConfig } from './Shape'

export type IGraphicCenterBasedShapeConfig = IGraphicShapeConfig
export type IGraphicCenterBasedShape =
  | KonvaRegularPolygon
  | KonvaCircle
  | KonvaEllipse

export abstract class GraphicCenterBasedShape<
  T extends IGraphicCenterBasedShape,
> extends GraphicShape<T> {
  constructor(
    graphicCenterBasedShapeConfig: IGraphicCenterBasedShapeConfig & {
      graphic: T
    },
  ) {
    super({
      ...graphicCenterBasedShapeConfig,
    })
  }

  // 重写父类的 fillLinearGradientStartPoint
  fillLinearGradientStartPoint(startPoint?: IPos) {
    let { width, height } = this.size()
    width /= 2
    height /= 2
    if (isNil(startPoint)) {
      startPoint = this.graphic.fillLinearGradientStartPoint()
      startPoint.x = startPoint.x + width
      startPoint.y = startPoint.y + height

      return startPoint
    }

    this.graphic.fillLinearGradientStartPoint({
      x: startPoint.x - width,
      y: startPoint.y - height,
    })
  }

  // 重写父类的 fillLinearGradientEndPoint
  fillLinearGradientEndPoint(endPoint?: IPos) {
    let { width, height } = this.size()
    width /= 2
    height /= 2
    if (isNil(endPoint)) {
      endPoint = this.graphic.fillLinearGradientStartPoint()
      endPoint.x = endPoint.x + width
      endPoint.y = endPoint.y + height

      return endPoint
    }

    this.graphic.fillLinearGradientEndPoint({
      x: endPoint.x - width,
      y: endPoint.y - height,
    })
  }
}
