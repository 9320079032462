// 扩展名。
export enum EExtension {
  // 压缩包。
  ZIP = 'zip',

  // 文件。
  XLSX = 'xlsx',
  XLS = 'xls',
  DOC = 'doc',
  DOCX = 'docx',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',

  // 图片。
  BMP = 'bmp',
  GIF = 'gif',
  PNG = 'png',
  JPG = 'jpg',
  SVG = 'svg',
  JPEG = 'jpeg',

  HTML = 'html',

  // 视频。
  MP4 = 'mp4',
  AVI = 'avi',
  MOV = 'mov',
  WMV = 'wmv',
  MKV = 'mkv',
  FLV = 'flv',
  MPEG = 'mpeg',
  MPG = 'mpg',
  WEBM = 'webm',

  // 音频。
  MP3 = 'mp3',

  // css
  CSS = 'css',

  // 字体。
  TTF = 'ttf',
  OTF = 'otf',
  WOFF = 'woff',
  WOFF2 = 'woff2',

  // json
  JSON = 'json',

  // 动画。
  PAG = 'pag',

  // 文本。
  TXT = 'txt',
}

// 扩展名 mime 映射
export const EXTENSION_MIME_MAP = {
  [EExtension.ZIP]: 'application/zip',
  [EExtension.XLSX]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [EExtension.XLS]: 'application/vnd.ms-excel',
  [EExtension.BMP]: 'image/bmp',
  [EExtension.GIF]: 'image/gif',
  [EExtension.PNG]: 'image/png',
  [EExtension.SVG]: 'image/svg',
  [EExtension.JPG]: 'image/jpg',
  [EExtension.JPEG]: 'image/jpeg',
  [EExtension.DOC]: 'application/msword',
  [EExtension.DOCX]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [EExtension.PPT]: 'application/vnd.ms-powerpoint',
  [EExtension.PPTX]:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  [EExtension.PDF]: 'application/pdf',
  [EExtension.HTML]: 'text/html',
  [EExtension.MP4]: 'video/mp4',
  [EExtension.AVI]: 'video/x-msvideo',
  [EExtension.MOV]: 'video/quicktime',
  [EExtension.WMV]: 'video/x-ms-wmv',
  [EExtension.MKV]: 'video/x-matroska',
  [EExtension.FLV]: 'video/x-flv',
  [EExtension.MPEG]: 'video/mpeg',
  [EExtension.MPG]: 'video/mpg',
  [EExtension.WEBM]: 'video/webm',
  [EExtension.MP3]: 'audio/mp3',
  [EExtension.CSS]: 'text/css',
  [EExtension.JSON]: 'application/json',
  [EExtension.TTF]: 'font/ttf',
  [EExtension.OTF]: 'font/otf',
  [EExtension.WOFF]: 'font/woff',
  [EExtension.WOFF2]: 'font/woff2',
  [EExtension.PAG]: 'application/x-pag',
  [EExtension.TXT]: 'text/plain',
} as const

// ppt 文件扩展名列表
export const PPT_EXTENSIONS = [EExtension.PPT, EExtension.PPTX]

// ppt 文件 mime 列表
export const PPT_MIME = PPT_EXTENSIONS.map((pptExt) => {
  return EXTENSION_MIME_MAP[pptExt] as string
})

// 图片文件扩展名列表。
export const IMAGE_EXTENSIONS = [
  EExtension.PNG,
  EExtension.JPG,
  EExtension.JPEG,
]

// 图片文件 mime 列表
export const IMAGE_MIME = IMAGE_EXTENSIONS.map((imageExt) => {
  return EXTENSION_MIME_MAP[imageExt] as string
})

// 视频文件扩展名列表。
export const VIDEO_EXTENSIONS = [EExtension.MP4, EExtension.WEBM]

// 视频文件 mime 列表
export const VIDEO_MIME = VIDEO_EXTENSIONS.map((videoExt) => {
  return EXTENSION_MIME_MAP[videoExt] as string
})

// 音频文件扩展名列表。
export const AUDIO_EXTENSIONS = [EExtension.MP3]

// 音频文件 mime 列表
export const AUDIO_MIME = AUDIO_EXTENSIONS.map((audioExt) => {
  return EXTENSION_MIME_MAP[audioExt] as string
})
