import {
  castArray,
  first,
  intersection,
  isNil,
  last,
  max,
  min,
  partialRight,
  sortBy,
  sumBy,
} from 'lodash-es'
import { v4 } from 'uuid'

import { isApproximatelyEqual } from '../../predicate'
import { GraphicEditor, KonvaLine } from '..'
import { EGraphicEvent } from '../config'
import { KonvaArrow } from '../konva/Arrow'
import { KonvaCircle } from '../konva/Circle'
import { KonvaEllipse } from '../konva/Ellipse'
import { KonvaGroup } from '../konva/Group'
import { KonvaImage } from '../konva/Image'
import { KonvaLayer } from '../konva/Layer'
import { KonvaRect } from '../konva/Rect'
import { KonvaRegularPolygon } from '../konva/RegularPolygon'
import { KonvaText } from '../konva/Text'
import { EAnchor } from '../konva/Transformer'
import { KonvaVideo } from '../konva/Video'
import { IPos, IRectBox, ISize } from '../type'
import {
  checkEqualWithinPointFive,
  rectToBox,
  rectToPoints,
  roundTo1,
} from '../util'

const isEqual = partialRight(isApproximatelyEqual, 0.000001)

// 图形配置。
export interface IGraphicConfig {
  x?: number
  y?: number
  id?: string
  groupId?: number
  lock?: boolean
  itemGroup?: number
  width?: number
  height?: number
}

// 图形类。
export abstract class Graphic<
  T extends
    | KonvaImage
    | KonvaVideo
    | KonvaRect
    | KonvaText
    | KonvaCircle
    | KonvaLine
    | KonvaArrow
    | KonvaEllipse
    | KonvaRegularPolygon = any,
> {
  graphicEditor?: GraphicEditor

  // 是否第一次绘制完成。
  abstract firstDrawIsComplete: boolean

  // 图形组。
  group: KonvaGroup

  // 分组 id
  groupId = 0

  // 是否锁定。
  lock = false

  // 项目组 id
  itemGroup = 0

  graphic: T

  // 图形框。
  box: KonvaRect

  // 唯一 id
  id: string

  // 容器原始点。
  containerOriginPos = {
    x: 0,
    y: 0,
  }

  constructor(
    graphicConfig: IGraphicConfig & {
      graphic: T
    },
  ) {
    const {
      graphic,
      width,
      height,
      lock = false,
      itemGroup = 0,
      groupId = 0,
      x = 0,
      y = 0,
      id = v4(),
    } = graphicConfig

    this.graphic = graphic
    this.groupId = groupId
    this.lock = lock
    this.itemGroup = itemGroup
    this.id = id

    // 创建图形组。
    this.group = new KonvaGroup({
      x,
      y,
    })

    this.group.add(this.graphic)
    this.graphic.position({ x: 0, y: 0 })

    this.box = new KonvaRect({
      name: 'box',
      width,
      height,
      x: 0,
      y: 0,

      // fill: 'red',
      // opacity: 0.5,
    })
    this.box.on('xChange', () => {
      this.fire(EGraphicEvent.X_CHANGE)
      this.fire(EGraphicEvent.POSITION_CHANGE)
    })
    this.box.on('yChange', () => {
      this.fire(EGraphicEvent.Y_CHANGE)
      this.fire(EGraphicEvent.POSITION_CHANGE)
    })
    this.box.on('widthChange', () => {
      this.fire(EGraphicEvent.WIDTH_CHANGE)
      this.fire(EGraphicEvent.DIMENSION_CHANGE)
    })
    this.box.on('heightChange', () => {
      this.fire(EGraphicEvent.HEIGHT_CHANGE)
      this.fire(EGraphicEvent.DIMENSION_CHANGE)
    })
    this.box.on('rotationChange', () => {
      this.fire(EGraphicEvent.DIMENSION_CHANGE)
    })

    this.group.add(this.box)
  }

  // 设置或获取定位。
  position(position?: IPos) {
    if (isNil(position)) {
      return {
        x: this.x(),
        y: this.y(),
      }
    }

    this.x(position.x)

    this.y(position.y)

    return position
  }

  // 设置或获取 x
  x(x?: number) {
    const curX = this.logicalRectBox.x
    if (isNil(x)) {
      return roundTo1(curX)
    }

    if (x === curX) {
      return x
    }

    this.box.x(x - this.containerOriginPos.x)

    return x
  }

  // X 轴移动。
  moveX(x: number) {
    const curX = this.x()
    const _x = this.x(x + curX)

    return _x
  }

  // 设置或获取 y
  y(y?: number) {
    const curY = this.logicalRectBox.y
    if (isNil(y)) {
      return roundTo1(curY)
    }

    if (y === curY) {
      return y
    }

    this.box.y(y - this.containerOriginPos.y)

    return y
  }

  // Y 轴移动。
  moveY(y: number) {
    const curY = this.y()
    const _y = this.y(y + curY)

    return _y
  }

  // 设置或获取尺寸。
  size(size?: ISize) {
    if (isNil(size)) {
      return {
        width: this.width(),
        height: this.height(),
      }
    }

    this.width(size.width)

    this.height(size.height)

    return size
  }

  // 设置或获取宽度。
  width(width?: number) {
    const curWidth = this.box.width() * this.scaleX()
    if (isNil(width)) {
      width = roundTo1(curWidth)

      return width
    }

    if (width === curWidth) {
      return width
    }

    this.box.width(width / this.scaleX())

    return width
  }

  // 设置或获取 height
  height(height?: number) {
    const curHeight = this.box.height() * this.scaleY()
    if (isNil(height)) {
      height = roundTo1(curHeight)

      return height
    }

    if (height === curHeight) {
      return height
    }

    this.box.height(height / this.scaleY())

    return height
  }

  // 设置或获取透明度。
  opacity(opacity?: number) {
    if (isNil(opacity)) {
      return this.graphic.opacity()
    }

    this.graphic.opacity(opacity)

    return opacity
  }

  // 设置或获取旋转角度。
  rotation(rotation?: number) {
    if (isNil(rotation)) {
      return this.box.rotation()
    }

    this.box.rotation(rotation)

    return rotation

    // const newPos = getNewPosAfterRotateAroundCenter({
    //   ...this.position(),
    //   ...this.size(),
    //   oldRotation: this.rotation(),
    //   newRotation: rotation,
    // })
    // this.x(newPos.x)
    // this.y(newPos.y)
  }

  // 调整宽度。
  adjustWidth(width: number) {
    const curWidth = this.width()
    const _width = this.width(width + curWidth)

    return _width
  }

  // 调整高度。
  adjustHeight(height: number) {
    const curHeight = this.height()
    const _height = this.height(height + curHeight)

    return _height
  }

  // 移动到左侧。
  toLeft(logicalRect: IRectBox) {
    this.x(logicalRect.x)
  }

  // 水平居中。
  centerHorizontally(logicalRect: IRectBox) {
    this.x((logicalRect.width - this.width()) / 2 + logicalRect.x)
  }

  // 移动到右侧。
  toRight(logicalRect: IRectBox) {
    this.x(logicalRect.x2 - this.width())
  }

  // 移动到顶部。
  toTop(logicalRect: IRectBox) {
    this.y(logicalRect.y)
  }

  // 垂直居中。
  centerVertically(logicalRect: IRectBox) {
    this.y((logicalRect.height - this.height()) / 2 + logicalRect.y)
  }

  // 移动到底部。
  toBottom(logicalRect: IRectBox) {
    this.y(logicalRect.y2 - this.height())
  }

  // 水平分布。
  horizontalDistribute() {
    // 更新位置。
    this.distribute('x')
  }

  // 垂直分布。
  verticalDistribute() {
    this.distribute('y')
  }

  // 均分分布。
  distribute(direction: keyof IPos) {
    const { inMultiSelect, selectedGraphics } = this
    if (!inMultiSelect) {
      return
    }

    if (this !== first(sortBy(selectedGraphics, (graphic) => graphic.x()))) {
      return
    }

    this.getDistributeData(direction).forEach(({ graphics, newSize }) => {
      graphics.forEach((graphic, i) => {
        graphic[direction](newSize[i])
      })
    })
  }

  // 获取均分分布数据。
  getDistributeData(direction: keyof IPos) {
    enum EDirectionSize {
      x = 'width',
      y = 'height',
    }

    // 获取多个图形宽度。
    const getGraphicsWidth = (graphics: Graphic<any>[]) => {
      return getRightOrBottomSize(graphics) - getLeftOrTopSize(graphics)
    }

    // 获取最左或最上的尺寸。
    const getLeftOrTopSize = (graphics: Graphic<any>[]) => {
      return min(
        graphics!.map((graphic) => {
          return graphic[direction]()
        }),
      )!
    }

    // 获取最右或最下的尺寸。
    const getRightOrBottomSize = (graphics: Graphic<any>[]) => {
      return max(
        graphics!.map((graphic) => {
          return graphic[direction]() + graphic[EDirectionSize[direction]]()
        }),
      )!
    }

    const { selectedGraphics } = this

    // 图形列表。
    let graphicsList: {
      graphics: Graphic<any>[]
      width: number
      size: number
      newSize: number[]
    }[] = []
    let i = 0
    while (i < selectedGraphics.length) {
      const selectedGraphic = selectedGraphics[i]
      if (selectedGraphic.groupId === 0) {
        graphicsList.push({
          graphics: [selectedGraphic],
          width: getGraphicsWidth([selectedGraphic]),
          size: getLeftOrTopSize([selectedGraphic]),
          newSize: [],
        })
        i++
      } else {
        const groupGraphics: Graphic<any>[] = []
        while (selectedGraphics[i]?.groupId === selectedGraphic.groupId) {
          groupGraphics.push(selectedGraphics[i])
          selectedGraphics.splice(i, 1)
        }

        graphicsList.push({
          graphics: sortBy(groupGraphics, (graphic) => graphic[direction]()),
          width: getGraphicsWidth(groupGraphics),
          size: getLeftOrTopSize(groupGraphics),
          newSize: [],
        })
      }
    }

    // 按宽度排序。
    graphicsList = sortBy(graphicsList, 'size')

    // 全部空间。
    const space =
      getLeftOrTopSize(last(graphicsList)!.graphics) -
      getRightOrBottomSize(first(graphicsList)!.graphics)

    // 占用空间。
    const usedSpace = sumBy(graphicsList.slice(1, -1), ({ width }) => width)!

    // 剩余空间。
    const remainedSpace = space - usedSpace

    // 间隙。
    let spaceBetween = 0

    if (remainedSpace > 0) {
      // 有空隙。
      spaceBetween = remainedSpace / (graphicsList.length - 1)
    } else {
      // 没有空隙时按照固定间隙处理。
      spaceBetween = 20
    }

    graphicsList.reduce((preTotalSize, graphic, i) => {
      if (i !== 0) {
        preTotalSize += spaceBetween
      }

      const firstGraphic = first(graphic.graphics)!
      graphic.newSize = graphic.graphics.map((graphic) => {
        const diff = graphic[direction]() - firstGraphic[direction]()

        return preTotalSize + diff
      })
      preTotalSize += getGraphicsWidth(graphic.graphics)

      return preTotalSize
    }, first(graphicsList)!.size)

    return graphicsList
  }

  // 是否已分布。
  isDistributed(direction: keyof IPos) {
    if (!this.inMultiSelect) {
      return true
    }

    const distributeData = this.getDistributeData(direction)

    return distributeData.every(({ graphics, newSize }) => {
      return graphics.every((graphic, i) => {
        return checkEqualWithinPointFive(graphic[direction](), newSize[i])
      })
    })
  }

  // 是否已水平均匀分布。
  get isHorizontallyDistributed() {
    return this.isDistributed('x')
  }

  // 是否已垂直均匀分布。
  get isVerticallyDistributed() {
    return this.isDistributed('y')
  }

  // 移动到别的图层。
  moveTo(layer: KonvaLayer) {
    return this.group.moveTo(layer)
  }

  // 设置层级为最底层。
  moveToBottom() {
    this.group.moveToBottom()

    this.fire(EGraphicEvent.MOVE_TO_BOTTOM)

    return this.zIndex()
  }

  // 设置层级为最顶层。
  moveToTop() {
    this.group.moveToTop()

    this.fire(EGraphicEvent.MOVE_TO_TOP)

    return this.zIndex()
  }

  // 层级+1
  moveUp() {
    if (this.isGrouped) {
      this.group.moveUp()
    } else {
      const index = this.graphicEditor!.zIndexList.indexOf(this.group.zIndex())
      const upIndex = last(
        castArray(this.graphicEditor!.zIndexList[index + 1]),
      )!
      this.group.zIndex(upIndex)
    }

    this.fire(EGraphicEvent.MOVE_UP)

    return this.zIndex()
  }

  // 层级-1
  moveDown() {
    if (this.isGrouped) {
      this.group.moveDown()
    } else {
      const index = this.graphicEditor!.zIndexList.indexOf(this.group.zIndex())
      const downIndex = first(
        castArray(this.graphicEditor!.zIndexList[index - 1]),
      )!
      this.group.zIndex(downIndex)
    }

    this.fire(EGraphicEvent.MOVE_DOWN)

    return this.zIndex()
  }

  // 设置或获取 index
  zIndex(index?: number) {
    if (isNil(index)) {
      return this.group.zIndex()
    }

    this.group.zIndex(index)

    this.fire(EGraphicEvent.INDEX)

    return index
  }

  // 删除。
  remove(silent = false) {
    if (this.isRemoved) {
      return
    }

    this.fire(EGraphicEvent.BEFORE_REMOVE)

    this.group.remove()

    // 从变换器中移除。
    if (this.transformer!.nodes().includes(this.box)) {
      this.transformer?.removeNode(this.box)
    }

    if (!silent) {
      this.fire(EGraphicEvent.REMOVED)
    }
  }

  // 添加到。
  addTo({
    container,
    editor,
  }: {
    container: KonvaLayer | KonvaGroup
    editor: GraphicEditor
  }) {
    if (this.graphicEditor) {
      // 之前已经添加过了，取之前的层级。
      const preIndex = this.group.zIndex()
      container.add(this.group)
      this.group.zIndex(preIndex)
    } else {
      this.graphicEditor = editor
      this.containerOriginPos = this.group.position()
      container.add(this.group)
    }

    this.fire(EGraphicEvent.ADD_TO)
  }

  // 监听事件。
  on(eventName: `${EGraphicEvent}`, cb: (...e: any[]) => void) {
    this.group.on(eventName, cb)

    return this.off.bind(this, eventName, cb)
  }

  // 取消监听事件。
  off(eventName: `${EGraphicEvent}`, cb?: (...e: any[]) => void) {
    this.group.off(eventName, cb)
  }

  // 触发事件。
  fire(eventName: `${EGraphicEvent}`, e?: Record<string, any>) {
    this.group.fire(eventName, e)
  }

  // 父级逻辑尺寸。
  get parentLogicalRect() {
    if (this.inMultiSelect) {
      return this.selectedGraphicsLogicalRectBox
    }

    return this.graphicEditor!.resolutionRectBox
  }

  // 图形实际矩形信息，作为其他尺寸的基准参考。
  get actualRectBox() {
    return rectToBox(this.box.getClientRect())
  }

  // 图形实际点坐标，作为其他尺寸的基准参考。
  get actualPoints() {
    return rectToPoints(this.actualRectBox)
  }

  // 用于命中检测的图形实际矩形框。
  get hitActualRectBox() {
    return this.actualRectBox
  }

  // 用于命中检测的图形实际点坐标。
  get hitActualPoints() {
    return rectToPoints(this.hitActualRectBox)
  }

  // 命中图形后展示的实际矩形信息。
  get hoverActualRectBox() {
    return this.actualRectBox
  }

  // 命中图形后展示的实际点坐标。
  get hoverActualPoints() {
    return rectToPoints(this.hoverActualRectBox)
  }

  // 计算图形时实际矩形信息，用来处理图形之间的吸附和参考线逻辑。
  get calcActualRectBox() {
    return this.actualRectBox
  }

  // 计算图形时实际点坐标，用来处理图形之间的吸附和参考线逻辑。
  get calcActualPoints() {
    return rectToPoints(this.calcActualRectBox)
  }

  // 图形轮廓实际矩形框，用来表达实际展示的最大轮廓。
  get outlineActualRectBox() {
    return rectToBox(this.graphic.getClientRect())
  }

  // 图形轮廓实际点坐标，用来表达实际展示的最大轮廓。
  get outlineActualPoints() {
    return rectToPoints(this.outlineActualRectBox)
  }

  // 内容相对于父级的逻辑矩形信息。
  get logicalRectBox() {
    return this.graphicEditor!.actualToLogicalRectBox(this.calcActualRectBox)
  }

  // 图形轮廓逻辑矩形框，用来表达逻辑展示的最大轮廓。
  get outlineLogicalRectBox() {
    return this.graphicEditor!.actualToLogicalRectBox(this.outlineActualRectBox)
  }

  // 最大索引值。
  get maxIndex() {
    return (this.group.getParent()?.children ?? []).length - 1
  }

  // 属性。
  get attrs() {
    const { x, y } = this.position()
    const { width, height } = this.size()

    const {
      x: outlineX,
      y: outlineY,
      width: outlineWidth,
      height: outlineHeight,
    } = this.graphicEditor!.actualToLogicalRectBox(this.outlineActualRectBox)

    return {
      x,
      y,
      width,
      height,
      outlineX,
      outlineY,
      outlineWidth,
      outlineHeight,
      opacity: this.opacity(),
    }
  }

  // x 方向缩放。
  scaleX(x?: number) {
    if (isNil(x)) {
      return this.box.scaleX()
    }

    this.box.scaleY(x)

    return x
  }

  // y 方向缩放。
  scaleY(y?: number) {
    if (isNil(y)) {
      return this.box.scaleY()
    }

    this.box.scaleY(y)

    return y
  }

  // 缩放。
  scale(scale?: IPos) {
    if (isNil(scale)) {
      return this.box.scale()!
    }

    this.box.scale(scale)

    return scale
  }

  // 是否位于最底层。
  get atTop() {
    if (this.isGrouped) {
      if (this.myGroupIsSelected) {
        return (
          last(sortBy(this.myGroupGraphics.map((g) => g.zIndex()))) ===
          this.maxIndex
        )
      }

      return (
        this.zIndex() ===
        last(sortBy(this.myGroupGraphics.map((g) => g.zIndex())))
      )
    }

    return this.zIndex() === this.maxIndex
  }

  // 是否位于最顶层。
  get atBottom() {
    if (this.isGrouped) {
      if (this.myGroupIsSelected) {
        return first(sortBy(this.myGroupGraphics.map((g) => g.zIndex()))) === 0
      }

      return (
        this.zIndex() ===
        first(sortBy(this.myGroupGraphics.map((g) => g.zIndex())))
      )
    }

    return this.zIndex() === 0
  }

  // 变换器。
  get transformer() {
    return this.graphicEditor?.tool.graphicTransformer
  }

  // 在分组中是否在最左边。
  get isLeftmostInGroup() {
    return isEqual(this.x(), this.myGroupLogicalRect.x)
  }

  // 是否在最左边。
  get isLeftmost() {
    if (this.isSingleSelectAndInGroup) {
      return this.isLeftmostInGroup
    }

    let leftValue = 0
    let rightValue = 0

    if (this.myGroupIsSelected) {
      leftValue = this.myGroupLogicalRect.x
      rightValue = this.parentLogicalRect.x
    } else {
      leftValue = this.x()
      rightValue = this.parentLogicalRect.x
    }

    return isEqual(leftValue, rightValue)
  }

  // 在分组中是否水平居中。
  get isHorizontallyCenteredInGroup() {
    return isEqual(this.logicalRectBox.x1, this.myGroupLogicalRect.x1)
  }

  // 是否水平居中。
  get isHorizontallyCentered() {
    if (this.isSingleSelectAndInGroup) {
      return this.isHorizontallyCenteredInGroup
    }

    let leftValue = 0
    let rightValue = 0

    if (this.myGroupIsSelected) {
      leftValue = this.myGroupLogicalRect.x1
      rightValue = this.parentLogicalRect.x1
    } else {
      leftValue = this.logicalRectBox.x1
      rightValue = this.parentLogicalRect.x1
    }

    return isEqual(leftValue, rightValue)
  }

  // 在分组中是否在最右边。
  get isRightmostInGroup() {
    return isEqual(this.logicalRectBox.x2, this.myGroupLogicalRect.x2)
  }

  // 是否在最右边。
  get isRightmost() {
    if (this.isSingleSelectAndInGroup) {
      return this.isRightmostInGroup
    }

    let leftValue = 0
    let rightValue = 0

    if (this.myGroupIsSelected) {
      leftValue = this.myGroupLogicalRect.x2
      rightValue = this.parentLogicalRect.x2
    } else {
      leftValue = this.logicalRectBox.x2
      rightValue = this.parentLogicalRect.x2
    }

    return isEqual(leftValue, rightValue)
  }

  // 在分组中是否在最顶部。
  get isHighestInGroup() {
    return isEqual(this.y(), this.myGroupLogicalRect.y)
  }

  // 是否在最顶部。
  get isHighest() {
    if (this.isSingleSelectAndInGroup) {
      return this.isHighestInGroup
    }

    let leftValue = 0
    let rightValue = 0

    if (this.myGroupIsSelected) {
      leftValue = this.myGroupLogicalRect.y
      rightValue = this.parentLogicalRect.y
    } else {
      leftValue = this.y()
      rightValue = this.parentLogicalRect.y
    }

    return isEqual(leftValue, rightValue)
  }

  // 在分组中是否垂直居中。
  get isVerticallyCenteredInGroup() {
    return isEqual(this.logicalRectBox.y1, this.myGroupLogicalRect.y1)
  }

  // 单独被选中并且在分组中。
  get isSingleSelectAndInGroup() {
    return (
      this.isGrouped &&
      !this.myGroupIsSelected &&
      this.selectedGraphics.length === 1 &&
      this.selectedGraphics[0] === this
    )
  }

  // 是否垂直居中。
  get isVerticallyCentered() {
    if (this.isSingleSelectAndInGroup) {
      return this.isVerticallyCenteredInGroup
    }

    let leftValue = 0
    let rightValue = 0

    if (this.myGroupIsSelected) {
      leftValue = this.myGroupLogicalRect.y1
      rightValue = this.parentLogicalRect.y1
    } else {
      leftValue = this.logicalRectBox.y1
      rightValue = this.parentLogicalRect.y1
    }

    return isEqual(leftValue, rightValue)
  }

  // 在分组中是否在最底部。
  get isLowestInGroup() {
    return isEqual(this.logicalRectBox.y2, this.myGroupLogicalRect.y2)
  }

  // 是否在最底部。
  get isLowest() {
    if (this.isSingleSelectAndInGroup) {
      return this.isLowestInGroup
    }

    let leftValue = 0
    let rightValue = 0

    if (this.myGroupIsSelected) {
      leftValue = this.myGroupLogicalRect.y2
      rightValue = this.parentLogicalRect.y2
    } else {
      leftValue = this.logicalRectBox.y2
      rightValue = this.parentLogicalRect.y2
    }

    return isEqual(leftValue, rightValue)
  }

  // 是否已选中。
  get isSelected() {
    return this.graphicEditor!.selectedGraphics.includes(this)
  }

  // 已选中的图形。
  get selectedGraphics() {
    return this.graphicEditor?.selectedGraphics ?? []
  }

  // 是否已多选。
  get inMultiSelect() {
    return this.graphicEditor!.isMultiSelect && this.isSelected
  }

  // 是否已单选。
  get inSingleSelect() {
    return this.graphicEditor!.isSingleSelect && this.isSelected
  }

  // 选择框的实际尺寸。
  get selectedGraphicsActualRectBox() {
    return this.graphicEditor!.calcGraphicsActualRectBox(this.selectedGraphics)
  }

  // 选择框的逻辑尺寸。
  get selectedGraphicsLogicalRectBox() {
    return this.graphicEditor!.calcGraphicsLogicalRectBox(this.selectedGraphics)
  }

  // 是否已删除。
  get isRemoved() {
    return this.graphicEditor && !this.group.parent
  }

  // 是否是分组图形。
  get isGrouped() {
    return this.myGroupGraphics.length > 1
  }

  // 我所在的分组中的所有图形。
  get myGroupGraphics() {
    return this.graphicEditor!.graphicsGroupBy[this.groupId] ?? []
  }

  // 我所在的分组图形矩形。
  get myGroupLogicalRect() {
    return this.graphicEditor!.calcGraphicsLogicalRectBox(
      this.myGroupGraphics,
      'calc',
    )
  }

  // 我所在的分组是否都已经选中。
  get myGroupIsSelected() {
    if (!this.isGrouped) {
      return false
    }

    return (
      intersection(this.selectedGraphics, this.myGroupGraphics).length ===
      this.myGroupGraphics.length
    )
  }

  // 是否应该禁用修改属性。
  abstract shouldDisableModifyAttr(attr: string): boolean

  // 图形属性。
  abstract get operateAttrs(): {
    // 启用的缩放锚点。
    enabledAnchors: EAnchor[]

    // 是否保持宽高比缩放。
    keepRatio: boolean

    // 启用边框。
    borderEnabled: boolean

    // 启用缩放。
    resizeEnabled: boolean

    // 启用 mask。
    maskEnabled: boolean

    // 启用旋转。
    rotateEnabled: boolean

    // 是否可拖拽。
    draggable?: boolean

    // 是否可截取。
    croppable: boolean

    // 是否可选中。
    selectable: boolean

    // 是否可 hover。
    hoverEnabled: boolean

    // 鼠标样式。
    pointerStyle?: string

    // 是否有圆角。
    hasRadius: boolean
  }
}
