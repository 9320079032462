import { ECacheTime } from '@lanyan/constant/src/common'
import { usePromise } from '@lanyan/util/src/helper'
import { first, isEqual, isNil } from 'lodash-es'
import SparkMD5 from 'spark-md5'

import { useStorage } from './useStorage'

const debug = (...args: any[]) => {
  return console?.log?.(...args)
}

// 包装一个函数，保证相同参数的多次调用，在 cacheTime 时间内只调用一次
export const useUnrepeatable = <T extends (...args: any[]) => Promise<any>>(
  fn: T,
  {
    cacheTime, // 缓存时间。
    cacheId, // 缓存 id
    loggable = false,
    compareArgs = true, // 是否比较参数。
  }: {
    cacheTime: `${ECacheTime}` | number
    loggable?: boolean
    cacheId?: string
    compareArgs?: boolean
  },
) => {
  // 指定函数的参数类型。
  type P = Parameters<T>

  // 指定函数的返回值类型。
  type R = Awaited<ReturnType<T>>

  // 请求列表。
  const requests: {
    args: P
    promise: Promise<R>
  }[] = []

  // 缓存数据。
  const [getCachedData, setCachedData] = useStorage({
    cacheTime,
  })

  const getCacheKey = (...args: P) => {
    //  生成缓存 key
    const key = [
      'storage',
      cacheId,
      SparkMD5.hash(fn.toString() + JSON.stringify(args)),
    ]
      .filter(Boolean)
      .join('-')

    return key
  }

  const getPrevPromise = (...args: P) => {
    // 相同参数的请求。
    const sameArgRequest = compareArgs
      ? requests.find((request) => {
          return isEqual(request.args, args)
        })
      : first(requests)

    if (sameArgRequest) {
      loggable && debug('相同参数的请求，返回同一个promise', args)

      return sameArgRequest.promise
    }
  }

  const addNewRequest = (...args: P) => {
    const { promise, resolve, reject } = usePromise<R>()

    // 当前请求。
    const request = {
      args,
      promise,
    }

    // 保存当前请求。
    requests.push(request)

    return {
      promise,
      request,
      resolve,
      reject,
    }
  }

  const callFn = ({
    args,
    useCache = true,
  }: {
    args: P
    useCache: boolean
  }) => {
    const prevPromise = getPrevPromise(...args)
    if (prevPromise) {
      return prevPromise
    }

    const { resolve, reject, request, promise } = addNewRequest(...args)

    const key = getCacheKey(...args)
    if (useCache && cacheTime) {
      getCachedData<R>(key).then((data) => {
        if (isNil(data)) {
          loggable && debug('没有缓存数据', args)

          // 发送异步请求。
          fn(...args)
            .then((data: R) => {
              // 缓存数据。
              loggable && debug('返回缓存数据', args)

              if (cacheTime) {
                setCachedData(key, data)
              }

              // 返回数据。
              resolve(data)
            })
            .catch((err: any) => {
              // 返回错误。
              reject(err)
            })
            .finally(() => {
              // 删除请求。
              requests.splice(requests.indexOf(request), 1)
            })
        } else {
          loggable && debug('返回缓存数据', args)

          // 返回数据。
          resolve(data)
        }
      })
    } else {
      // 发送异步请求。
      fn(...args)
        .then((data: R) => {
          // 缓存数据。
          loggable && debug('返回缓存数据', args)

          if (cacheTime) {
            setCachedData(key, data)
          }

          // 返回数据。
          resolve(data)
        })
        .catch((err: any) => {
          // 返回错误。
          reject(err)
        })
        .finally(() => {
          // 删除请求。
          requests.splice(requests.indexOf(request), 1)
        })
    }

    return promise
  }

  const unrepeatableFnWithoutCache = async function (...args: P): Promise<R> {
    return callFn({
      useCache: false,
      args,
    })
  }

  const unrepeatableFn = async function (...args: P): Promise<R> {
    return callFn({
      useCache: true,
      args,
    })
  }

  return [unrepeatableFn, unrepeatableFnWithoutCache] as const
}
