import {
  EKeyboardModifier,
  KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP,
} from '@lanyan/constant'
import { Box } from 'konva/lib/shapes/Transformer'
import { difference, isEmpty, isNumber, partialRight, uniq } from 'lodash-es'

import { toFixed } from '../helper'
import { isApproximatelyEqual } from '../predicate'
import {
  EKeyboardKey,
  EKeyboardOperation,
  KEYBOARD_OPERATION_KEY_MAP,
  KEYBOARD_OPERATION_MODIFIER_LIST_MAP,
  KEYBOARD_OPERATIONS,
} from './config'
import { IPoint, IPos, IQuadrilateral, ISize } from './type'

// 数字精确到 6 位。
export const roundTo = partialRight(toFixed, 6)

// 数字精确到 0 位。
export const roundToInt = partialRight(toFixed, 0)

// 数字精确到 2 位。
export const roundTo2 = partialRight(toFixed, 2)

// 数字精确到 1 位。
export const roundTo1 = partialRight(toFixed, 1)

// 检查是否在 0。5 范围内相等，用在参考线展示逻辑。
export const checkEqualWithinPointFive = partialRight(isApproximatelyEqual, 0.5)

// 计算矩形的边界框。
export const calculateMaxBoundingBox = (rectangle: Box) => {
  const { x, y, width, height, rotation } = rectangle
  const angle = (rotation * Math.PI) / 180
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)

  const topLeftX = x
  const topLeftY = y
  const topRightX = x + width * cos
  const topRightY = y + width * sin
  const bottomLeftX = x - height * sin
  const bottomLeftY = y + height * cos
  const bottomRightX = bottomLeftX + width * cos
  const bottomRightY = bottomLeftY + width * sin

  const minX = Math.min(topLeftX, topRightX, bottomLeftX, bottomRightX)
  const minY = Math.min(topLeftY, topRightY, bottomLeftY, bottomRightY)
  const maxX = Math.max(topLeftX, topRightX, bottomLeftX, bottomRightX)
  const maxY = Math.max(topLeftY, topRightY, bottomLeftY, bottomRightY)
  const maxBoundingBoxWidth = maxX - minX
  const maxBoundingBoxHeight = maxY - minY

  return {
    x: minX,
    y: minY,
    width: maxBoundingBoxWidth,
    height: maxBoundingBoxHeight,
  }
}

// 检查是否按下了某个判断操作。
export const checkKeydownOp = (
  e: KeyboardEvent,
  keyboardOperation: `${EKeyboardOperation}`,
) => {
  const modifierList = KEYBOARD_OPERATION_MODIFIER_LIST_MAP[
    keyboardOperation
  ].map((keyboardModifiers) => {
    return keyboardModifiers.map((keyboardModifier) => {
      return KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP[keyboardModifier]
    })
  })

  if (isEmpty(modifierList)) {
    modifierList.push([])
  }

  const keys = KEYBOARD_OPERATION_KEY_MAP[keyboardOperation]

  const key = e.code.startsWith('Key')
    ? e.code.replace('Key', '').toLowerCase()
    : e.code
  const keyIsPress = keys.includes(key as EKeyboardKey)
  const modifierIsPress = modifierList.some((needPressedModifiers) => {
    const notNeedPressedModifiers = difference(
      Object.values(EKeyboardModifier).map(
        (modifier) => KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP[modifier],
      ),
      needPressedModifiers ?? [],
    )

    return (
      needPressedModifiers.every((modifier) => e[modifier]) &&
      notNeedPressedModifiers.every((modifier) => !e[modifier])
    )
  })

  return keyIsPress && modifierIsPress
}

// 获取某个键盘操作。
export const getKeydownOp = (e: KeyboardEvent) => {
  return KEYBOARD_OPERATIONS.find((keyboardOperation) => {
    return checkKeydownOp(e, keyboardOperation)
  })
}

// 计算两个矩形的相交面积。
export const calculateIntersectionArea = (
  rect1: ISize & IPos,
  rect2: ISize & IPos,
) => {
  // 提取矩形 1 的坐标和尺寸。
  const x1 = rect1.x
  const y1 = rect1.y
  const width1 = rect1.width
  const height1 = rect1.height

  // 提取矩形 2 的坐标和尺寸。
  const x2 = rect2.x
  const y2 = rect2.y
  const width2 = rect2.width
  const height2 = rect2.height

  // 计算相交矩形的左上角坐标。
  const x = Math.max(x1, x2)
  const y = Math.max(y1, y2)

  // 计算相交矩形的右下角坐标。
  const right_x = Math.min(x1 + width1, x2 + width2)
  const right_y = Math.min(y1 + height1, y2 + height2)

  // 检查是否存在相交。
  if (right_x > x && right_y > y) {
    // 计算相交矩形的宽度和高度。
    const width = right_x - x
    const height = right_y - y

    return {
      width: roundTo(width),
      height: roundTo(height),
    }
  }

  return {
    width: 0,
    height: 0,
  } // 无相交面积。
}

// 获取围绕中心旋转后的新位置。
export const getNewPosAfterRotateAroundCenter = (info: {
  width: number
  height: number
  x: number
  y: number
  oldRotation: number
  newRotation: number
}) => {
  const topLeft = { x: -info.width / 2, y: -info.height / 2 }
  const current = rotatePoint(topLeft.x, topLeft.y, 0, 0, info.oldRotation)
  const rotated = rotatePoint(topLeft.x, topLeft.y, 0, 0, info.newRotation)
  const dx = rotated[0] - current[0]
  const dy = rotated[1] - current[1]

  return {
    x: info.x + dx,
    y: info.y + dy,
  }
}

// 格式化圆角。
export const formatCornerRadiusToArray = (cornerRadius: number | number[]) => {
  if (isNumber(cornerRadius)) {
    return new Array<number>(4).fill(cornerRadius)
  }

  return [...cornerRadius]
}

// 角度转弧度。
const degreeToRadian = (degree: number) => {
  return degree * (Math.PI / 180)
}

// 矩形转换为矩形框。
export const rectToBox = (rect: ISize & IPos, precision = 6) => {
  const { x, y, width, height } = rect

  return {
    x: toFixed(x, precision),
    x1: toFixed(x + width / 2, precision),
    x2: toFixed(x + width, precision),
    y: toFixed(y, precision),
    y1: toFixed(y + height / 2, precision),
    y2: toFixed(y + height, precision),
    width: toFixed(width, precision),
    height: toFixed(height, precision),
  }
}

// 根据矩形获取线性渐变参数。
export const getLinearGradientParamsByRect = ({
  x,
  y,
  width,
  height,
  degree,
  colorStops,
}: {
  x: number
  y: number
  width: number
  height: number
  degree: number
  colorStops: [number, string][]
}) => {
  const halfWidth = width / 2
  const halfHeight = height / 2

  const is0_45Quadrant = degree >= 0 && degree < 45
  const is45_90Quadrant = degree >= 45 && degree < 90
  const is90_135Quadrant = degree >= 90 && degree < 135
  const is135_180Quadrant = degree >= 135 && degree < 180
  const is180_225Quadrant = degree >= 180 && degree < 225
  const is225_270Quadrant = degree >= 225 && degree < 270
  const is270_315Quadrant = degree >= 270 && degree < 315
  const is315_360Quadrant = degree >= 315 && degree < 360

  let startX = 0
  let startY = 0
  let endX = 0
  let endY = 0
  const calcPoint = (side1: number, side2: number, degree: number) => {
    const radian1 = degreeToRadian(degree)
    const radian2 = degreeToRadian(90 - degree)
    const tan = Math.tan(radian1)
    const cos = Math.cos(radian2)
    const sin = Math.sin(radian2)
    let x = side1 * tan
    let y = 0
    if (x) {
      const x1 = (side2 - x) * cos * cos
      x += x1
      y = x1 * sin
    }

    return [x, y]
  }

  switch (true) {
    case is0_45Quadrant: {
      const [x1, y1] = calcPoint(halfHeight, halfWidth, degree)

      startX = x + (halfWidth - x1)
      startY = y + height + y1
      endX = x + halfWidth + x1
      endY = y - y1

      break
    }

    case is45_90Quadrant: {
      const [x1, y1] = calcPoint(halfWidth, halfHeight, 90 - degree)

      startX = x - y1
      startY = y + halfHeight + x1
      endX = x + width + y1
      endY = y + halfHeight - x1

      break
    }

    case is90_135Quadrant: {
      const [x1, y1] = calcPoint(halfWidth, halfHeight, degree - 90)
      startX = x - y1
      startY = y + halfHeight - x1
      endX = x + width + y1
      endY = y + halfHeight + x1

      break
    }

    case is135_180Quadrant: {
      const [x1, y1] = calcPoint(halfHeight, halfWidth, 180 - degree)

      startX = x + halfWidth - x1
      startY = y - y1
      endX = x + halfWidth + x1
      endY = y + height + y1

      break
    }

    case is180_225Quadrant: {
      const [x1, y1] = calcPoint(halfHeight, halfWidth, degree - 180)

      startX = x + halfWidth + x1
      startY = y - y1
      endX = x + (halfWidth - x1)
      endY = y + height + y1

      break
    }

    case is225_270Quadrant: {
      const [x1, y1] = calcPoint(halfWidth, halfHeight, 270 - degree)

      startX = x + width + y1
      startY = y + halfHeight - x1
      endX = x - y1
      endY = y + halfHeight + x1

      break
    }

    case is270_315Quadrant: {
      const [x1, y1] = calcPoint(halfWidth, halfHeight, degree - 270)

      startX = x + width + y1
      startY = y + halfHeight + x1
      endX = x - y1
      endY = y + halfHeight - x1

      break
    }

    case is315_360Quadrant: {
      const [x1, y1] = calcPoint(halfHeight, halfWidth, 360 - degree)

      startX = x + halfWidth + x1
      startY = y + height + y1
      endX = x + halfWidth - x1
      endY = y - y1

      break
    }

    default: {
      break
    }
  }

  return { startX, startY, endX, endY, colorStops }
}

// 计算两点组成的矩形的大小。
export const calcSizeByTwoPoints = (point1: IPoint, point2: IPoint) => {
  // 确定左上角坐标。
  const leftX = Math.min(point1[0], point2[0])
  const topY = Math.min(point1[1], point2[1])

  // 确定右下角坐标。
  const rightX = Math.max(point1[0], point2[0])
  const bottomY = Math.max(point1[1], point2[1])

  // 计算宽度和高度。
  const width = rightX - leftX
  const height = bottomY - topY

  // 返回矩形的坐标。
  return {
    width,
    height,
  }
}

// 计算两个点的角度。
export const calcDegreeByTwoPoints = (point1: IPoint, point2: IPoint) => {
  const _x1 = Math.min(point1[0], point2[0])
  const _y1 = Math.min(point1[1], point2[1])
  const _x2 = Math.max(point1[0], point2[0])
  const _y2 = Math.max(point1[1], point2[1])

  // 计算向量的分量。
  const deltaX = _x2 - _x1
  const deltaY = _y2 - _y1

  // 计算角度（弧度）
  const thetaRadians = Math.atan2(deltaY, deltaX)

  // 将弧度转换为角度。
  const thetaDegrees = thetaRadians * (180 / Math.PI)

  return thetaDegrees
}

// 计算两个点的距离。
export const calcLengthByTwoPoints = (point1: IPoint, point2: IPoint) => {
  const [x1, y1] = point1
  const [x2, y2] = point2

  // 使用勾股定理计算距离。
  const distance = roundTo1(Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2))

  return distance
}

// 垂直移动线段。
export const verticalTranslateLine = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  distance: number,
) => {
  // 计算方向向量。
  const dx = x2 - x1
  const dy = y2 - y1

  // 计算法线方向的单位向量（向上的方向）
  const length = Math.sqrt(dx * dx + dy * dy)

  const unitNormalX = -dy / length
  const unitNormalY = dx / length

  // 按照法线方向移动给定距离。
  const newP1: IPoint = [
    x1 + unitNormalX * distance,
    y1 + unitNormalY * distance,
  ]

  const newP2: IPoint = [
    x2 + unitNormalX * distance,
    y2 + unitNormalY * distance,
  ]

  return [newP1, newP2]
}

// 计算叉积，用于确定三个点的顺序。
function cross(o: IPoint, a: IPoint, b: IPoint): number {
  return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0])
}

// 计算给定点集的凸包。
function convexHull(points: IPoint[]): IPoint[] {
  // 按 x 坐标排序，如果 x 相同则按 y 坐标排序
  points.sort((a, b) => a[0] - b[0] || a[1] - b[1])

  const lower: IPoint[] = []
  for (const point of points) {
    while (
      lower.length >= 2 &&
      cross(lower[lower.length - 2], lower[lower.length - 1], point) <= 0
    ) {
      lower.pop()
    }

    lower.push(point)
  }

  const upper: IPoint[] = []
  for (let i = points.length - 1; i >= 0; i--) {
    const point = points[i]
    while (
      upper.length >= 2 &&
      cross(upper[upper.length - 2], upper[upper.length - 1], point) <= 0
    ) {
      upper.pop()
    }

    upper.push(point)
  }

  // 去掉最后一个点，因为它与第一个点重复。
  upper.pop()
  lower.pop()

  return lower.concat(upper)
}

// 计算包含给定四边形的最小四边形（凸包）
export const calcMinQuadPoints = (quadrilaterals: IQuadrilateral[]) => {
  const points: IPoint[] = []

  for (const quad of quadrilaterals) {
    points.push(...quad)
  }

  return convexHull(points)
}

// 计算能够包含给定四边形的最大四边形。
export const calcMaxQuadPoints = (quadrilaterals: IQuadrilateral[]) => {
  let minX = Number.POSITIVE_INFINITY
  let maxX = Number.NEGATIVE_INFINITY
  let minY = Number.POSITIVE_INFINITY
  let maxY = Number.NEGATIVE_INFINITY

  for (const quad of quadrilaterals) {
    for (const [x, y] of quad) {
      if (x < minX) minX = x

      if (x > maxX) maxX = x

      if (y < minY) minY = y

      if (y > maxY) maxY = y
    }
  }

  // 返回包含所有四边形的最大四边形（矩形）
  return [
    [minX, minY], // 左下角。
    [maxX, minY], // 右下角。
    [maxX, maxY], // 右上角。
    [minX, maxY], // 左上角。
  ]
}

function orientation(a: IPoint, b: IPoint, c: IPoint): number {
  const val = (b[1] - a[1]) * (c[0] - b[0]) - (b[0] - a[0]) * (c[1] - b[1])
  if (val === 0) return 0 // 共线。

  return val > 0 ? 1 : 2 // 顺时针或逆时针。
}

function onSegment(a: IPoint, b: IPoint, c: IPoint): boolean {
  return (
    c[0] <= Math.max(a[0], b[0]) &&
    c[0] >= Math.min(a[0], b[0]) &&
    c[1] <= Math.max(a[1], b[1]) &&
    c[1] >= Math.min(a[1], b[1])
  )
}

// 判断两条线段是否相交。
function doLinesIntersect(
  p1: IPoint,
  p2: IPoint,
  q1: IPoint,
  q2: IPoint,
): boolean {
  const o1 = orientation(p1, p2, q1)
  const o2 = orientation(p1, p2, q2)
  const o3 = orientation(q1, q2, p1)
  const o4 = orientation(q1, q2, p2)

  if (o1 !== o2 && o3 !== o4) return true // 线段相交。

  // 检查是否在同一条线上的特殊情况。
  if (o1 === 0 && onSegment(p1, p2, q1)) return true

  if (o2 === 0 && onSegment(p1, p2, q2)) return true

  if (o3 === 0 && onSegment(q1, q2, p1)) return true

  if (o4 === 0 && onSegment(q1, q2, p2)) return true

  return false
}

// 判断一个点是否在多边形内。
function isPointInPolygon(polygon: IQuadrilateral, point: IPoint): boolean {
  let count = 0
  const inf: IPoint = [10000, point[1]] // 一个在多边形外的水平线段。

  for (let i = 0; i < 4; i++) {
    const next = (i + 1) % 4
    if (doLinesIntersect(polygon[i], polygon[next], point, inf)) {
      if (orientation(polygon[i], point, polygon[next]) === 0) {
        return onSegment(polygon[i], polygon[next], point)
      }

      count++
    }
  }

  return count % 2 === 1 // 如果交点数为奇数，则点在多边形内。
}

// 判断两个四边形是否相交。
export const doQuadrilateralsIntersect = (
  q1: IQuadrilateral,
  q2: IQuadrilateral,
) => {
  // 检查四边形的边是否相交。
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 4; j++) {
      if (doLinesIntersect(q1[i], q1[(i + 1) % 4], q2[j], q2[(j + 1) % 4])) {
        return true
      }
    }
  }

  // 检查一个四边形是否包含在另一个四边形内。
  if (isPointInPolygon(q1, q2[0]) || isPointInPolygon(q2, q1[0])) {
    return true
  }

  return false
}

// 计算旋转后的点坐标。
export const rotatePoint = (
  px: number,
  py: number,
  ox: number,
  oy: number,
  degree: number,
) => {
  const radians = degree * (Math.PI / 180) // 角度转换为弧度。
  const cos = Math.cos(radians)
  const sin = Math.sin(radians)

  const nx = cos * (px - ox) - sin * (py - oy) + ox
  const ny = sin * (px - ox) + cos * (py - oy) + oy

  return [roundTo(nx), roundTo(ny)] as IPoint
}

// 矩形转 points。
export const rectToPoints = ({
  x,
  y,
  width,
  height,
  rotation = 0,
  ox = x,
  oy = y,
}: {
  x: number
  y: number
  width: number
  height: number
  rotation?: number
  ox?: number
  oy?: number
}) => {
  // 未旋转时的四个顶点。
  const topLeft = [x, y]
  const topRight = [x + width, y]
  const bottomLeft = [x, y + height]
  const bottomRight = [x + width, y + height]

  // 旋转四个顶点。
  const rotatedTopLeft = rotatePoint(topLeft[0], topLeft[1], ox, oy, rotation)
  const rotatedTopRight = rotatePoint(
    topRight[0],
    topRight[1],
    ox,
    oy,
    rotation,
  )
  const rotatedBottomLeft = rotatePoint(
    bottomLeft[0],
    bottomLeft[1],
    ox,
    oy,
    rotation,
  )
  const rotatedBottomRight = rotatePoint(
    bottomRight[0],
    bottomRight[1],
    ox,
    oy,
    rotation,
  )

  return [
    rotatedTopLeft,
    rotatedTopRight,
    rotatedBottomRight,
    rotatedBottomLeft,
  ] as IQuadrilateral
}

// 根据 points 计算矩形,points 顺序顺时针。
export const pointsToRect = (points: [IPoint, IPoint, IPoint, IPoint]) => {
  // 计算旋转角度（基于左上角到右上角的方向）
  const angle = Math.atan2(
    points[1][1] - points[0][1],
    points[1][0] - points[0][0],
  )
  const angleDegrees = angle * (180 / Math.PI)

  // 找到左上角顶点（通过找到最小的 x 和 y 值）
  const topLeft = points[0]

  // 计算边界框的宽度和高度（基于左上角顶点到其他顶点的距离）
  const edgeLengths = [
    calcLengthByTwoPoints(topLeft, points[1]), // 上边。
    calcLengthByTwoPoints(points[1], points[2]), // 右边。
    calcLengthByTwoPoints(points[2], points[3]), // 下边。
    calcLengthByTwoPoints(points[3], topLeft), // 左边。
  ]

  const width = roundTo((edgeLengths[1] + edgeLengths[3]) / 2)
  const height = roundTo((edgeLengths[0] + edgeLengths[2]) / 2)

  return {
    x: points[0][0],
    y: points[0][1],
    width,
    height,
    rotation: angleDegrees,
  }
}

// 计算线段 points。
export const calcLinePoints = (p1: IPoint, p2: IPoint, width: number) => {
  // 计算两个点确定的直线的方向向量。
  const dx = p2[0] - p1[0]
  const dy = p2[1] - p1[1]

  // 计算法线方向的单位向量。
  const length = Math.sqrt(dx * dx + dy * dy)
  const unitNormalX = -dy / length
  const unitNormalY = dx / length

  // 计算线条边缘的偏移量。
  const offsetX = unitNormalX * (width / 2)
  const offsetY = unitNormalY * (width / 2)

  // 计算四个点的坐标。
  const point1: IPoint = [p1[0] + offsetX, p1[1] + offsetY]
  const point2: IPoint = [p1[0] - offsetX, p1[1] - offsetY]
  const point3: IPoint = [p2[0] + offsetX, p2[1] + offsetY]
  const point4: IPoint = [p2[0] - offsetX, p2[1] - offsetY]

  return [point2, point4, point3, point1]
}

export const findShortEdgesMidpoints = (points: IPoint[]) => {
  // 计算每条边的长度。
  const edgeLengths: number[] = [
    calcLengthByTwoPoints(points[0], points[1]),
    calcLengthByTwoPoints(points[1], points[2]),
    calcLengthByTwoPoints(points[2], points[3]),
    calcLengthByTwoPoints(points[3], points[0]),
  ]

  // 正方形。
  if (uniq(edgeLengths).length === 1) {
    // 计算两个最短边的中点。
    const midpoint1: IPoint = [
      (points[0][0] + points[1][0]) / 2,
      (points[0][1] + points[1][1]) / 2,
    ]
    const midpoint2: IPoint = [
      (points[2][0] + points[3][0]) / 2,
      (points[2][1] + points[3][1]) / 2,
    ]

    return [midpoint1, midpoint2]
  }

  // 找到最短的两条边的索引。
  const shortestEdgesIndices = findShortestEdgesIndices(edgeLengths)

  // 获取最短边的端点坐标。
  const shortestEdge1 = [
    points[shortestEdgesIndices[0]],
    points[(shortestEdgesIndices[0] + 1) % 4],
  ]
  const shortestEdge2 = [
    points[shortestEdgesIndices[1]],
    points[(shortestEdgesIndices[1] + 1) % 4],
  ]

  // 计算两个最短边的中点。
  const midpoint1: IPoint = calcMidpoint(shortestEdge1[0], shortestEdge1[1])
  const midpoint2: IPoint = calcMidpoint(shortestEdge2[0], shortestEdge2[1])

  return [midpoint1, midpoint2]
}

// 找到数组中最小的两个值的索引。
function findShortestEdgesIndices(lengths: number[]): number[] {
  const shortestIndices: number[] = [-1, -1]
  let shortestLength1 = Infinity
  let shortestLength2 = Infinity

  for (let i = 0; i < lengths.length; i++) {
    if (lengths[i] < shortestLength1) {
      shortestLength2 = shortestLength1
      shortestIndices[1] = shortestIndices[0]
      shortestLength1 = lengths[i]
      shortestIndices[0] = i
    } else if (lengths[i] < shortestLength2) {
      shortestLength2 = lengths[i]
      shortestIndices[1] = i
    }
  }

  return shortestIndices
}

// 计算两点的中点。
export const calcMidpoint = (point1: IPoint, point2: IPoint) => {
  const [x1, y1] = point1
  const [x2, y2] = point2

  // 计算中点的坐标。
  const midpointX = (x1 + x2) / 2
  const midpointY = (y1 + y2) / 2

  return [midpointX, midpointY] as IPoint
}

// 排序。
export const sortPoints = (points: number[][]) => {
  return points.sort((a, b) => {
    if (a[0] === b[0]) {
      return a[1] - b[1]
    }

    return a[0] - b[0]
  })
}

// 计算新的点相对于原点只能是 0，45，90 度。
export const calcNewPoint = (op: IPoint, np: IPoint) => {
  const dx = np[0] - op[0]
  const dy = np[1] - op[1]
  let degree = Math.atan2(dy, dx) * (180 / Math.PI)
  degree = Math.round(degree / 45) * 45
  const distance = Math.sqrt(dx * dx + dy * dy)
  const radians = degree * (Math.PI / 180)
  const newX = op[0] + distance * Math.cos(radians)
  const newY = op[1] + distance * Math.sin(radians)

  return [newX, newY]
}
