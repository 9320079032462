// 模版类型。
export enum ETemplateType {
  USER = 1,
  OPERATOR = 2,
}

// 模版类型文案。
export const TEMPLATE_TYPE_TEXT_MAP = {
  [ETemplateType.USER]: '用户模版',
  [ETemplateType.OPERATOR]: '运营模版',
}
