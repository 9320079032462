import {
  EKeyboardModifier,
  EMouse,
  KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP,
  MOUSE_BUTTON_MAP,
  MOUSE_BUTTONS_MAP,
} from '@lanyan/constant/src/dom'
import { Optional, RemoveSecondType } from '@lanyan/type'
import to from 'await-to-js'
import { uniq } from 'lodash-es'

import { fileToUrl } from './file'
import { usePromise } from './helper'
import { isImage, isVideo } from './predicate'

// 修饰符列表。
const MODIFIES = Object.values(EKeyboardModifier)

type KeyboardEventMap = Pick<WindowEventMap, 'keydown' | 'keyup'>
type MouseEventMap = Pick<
  WindowEventMap,
  | 'mouseout'
  | 'mousedown'
  | 'mousemove'
  | 'mouseup'
  | 'mouseenter'
  | 'mouseleave'
  | 'mouseover'
>

export const onKeyDown = (
  ...params: RemoveSecondType<Parameters<typeof onKeyboard>>
) => {
  return onKeyboard(params[0], 'keydown', params[1], params[2])
}
export const onKeyUp = (
  ...params: RemoveSecondType<Parameters<typeof onKeyboard>>
) => {
  return onKeyboard(params[0], 'keyup', params[1], params[2])
}

// 监听指定键盘事件。
export function onKeyboard<
  EL extends HTMLElement | Window,
  EV extends keyof KeyboardEventMap,
>(el: EL, event: EV, listener: (ev: any) => any, keys: string[] = []) {
  return on(el, event, (e: KeyboardEvent) => {
    keys = uniq(keys)
    const res = keys.every((key) => {
      if (MODIFIES.includes(key as EKeyboardModifier)) {
        return e[
          KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP[key as EKeyboardModifier]
        ]
      }

      return e.key === key
    })

    if (res) {
      listener?.(e)
    }
  })
}

export const onMouseUp = (
  ...params: RemoveSecondType<Parameters<typeof onMouse>>
) => {
  return onMouse(params[0], 'mouseup', params[1], params[2])
}
export const onMouseDown = (
  ...params: RemoveSecondType<Parameters<typeof onMouse>>
) => {
  return onMouse(params[0], 'mousedown', params[1], params[2])
}
export const onMouseMove = (
  ...params: RemoveSecondType<Parameters<typeof onMouse>>
) => {
  return onMouse(params[0], 'mousemove', params[1], params[2])
}
export const onMouseLeave = (
  ...params: RemoveSecondType<Parameters<typeof onMouse>>
) => {
  return onMouse(params[0], 'mouseleave', params[1], params[2])
}
export const onMouseEnter = (
  ...params: RemoveSecondType<Parameters<typeof onMouse>>
) => {
  return onMouse(params[0], 'mouseenter', params[1], params[2])
}

// 监听指定鼠标事件。
export function onMouse<
  EL extends HTMLElement | Window,
  EV extends keyof MouseEventMap,
>(
  el: EL,
  event: EV,
  listener: (ev: any) => any,
  keys: `${EMouse | EKeyboardModifier}`[] = [],
) {
  return on(el, event, (e: MouseEvent) => {
    keys = uniq(keys)
    const res = keys.every((key) => {
      if (MODIFIES.includes(key as EKeyboardModifier)) {
        return e[
          KEYBOARD_MODIFIER_MOUSE_EVENT_KEY_MAP[key as EKeyboardModifier]
        ]
      }

      if (['mouseup', 'mousedown'].includes(event)) {
        return e.button === MOUSE_BUTTON_MAP[key as Exclude<EMouse, 'none'>]
      }

      return e.buttons === MOUSE_BUTTONS_MAP[key as EMouse]
    })
    if (res) {
      listener?.(e)
    }
  })
}

// 添加事件监听器，返回移除事件监听器函数，如果不传事件监听器，则返回 promise
export function on<
  EL extends HTMLElement | Window,
  EV extends EL extends Window
    ? keyof WindowEventMap
    : keyof HTMLElementEventMap,
>(el: EL, event: EV): Promise<any>
export function on<
  EL extends HTMLElement | Window,
  EV extends EL extends Window
    ? keyof WindowEventMap
    : keyof HTMLElementEventMap,
>(
  el: EL,
  event: EV,
  listener: (ev: any) => any,
  options?: boolean | AddEventListenerOptions,
): () => void
export function on<
  EL extends HTMLElement | Window,
  EV extends EL extends Window
    ? keyof WindowEventMap
    : keyof HTMLElementEventMap,
>(
  el: EL,
  event: EV,
  listener?: (ev: any) => any,
  options?: boolean | AddEventListenerOptions,
) {
  if (listener) {
    el.addEventListener(event, listener, options)

    return () => el.removeEventListener(event, listener, options)
  }

  const { promise, resolve } = usePromise<any>()

  el.addEventListener(
    event,
    function _listener(ev) {
      el.removeEventListener(event, _listener, options)

      resolve(ev)
    },
    options,
  )

  return promise
}

// 为元素添加一次性事件监听器，事件触发后自动移除监听器。
export const once = <
  EL extends HTMLElement | Window,
  EV extends EL extends Window
    ? keyof WindowEventMap
    : keyof HTMLElementEventMap,
>(
  el: EL,
  event: EV,
  listener: (ev: any) => any,
  options?: boolean | AddEventListenerOptions,
) => {
  el.addEventListener(
    event,
    function _listener(...params) {
      listener(...params)

      el.removeEventListener(event, _listener, options)
    },
    options,
  )
}

// 在元素外部单击时触发回调函数。
export const onClickOut = (els: HTMLElement[], cb: (e: MouseEvent) => void) => {
  const onClickOut = (e: MouseEvent) => {
    if (els.every((el) => !el.contains(e.target as Node))) {
      cb(e)
    }
  }

  document.addEventListener('click', onClickOut)

  return () => {
    document.removeEventListener('click', onClickOut)
  }
}

// 在元素外部按下鼠标时触发回调函数。
export const onMouseDownOut = (
  el: HTMLElement,
  cb: (e: MouseEvent) => void,
) => {
  const onMouseDownOut = (e: MouseEvent) => {
    if (!el.contains(e.target as Node)) {
      cb(e)
    }
  }

  document.addEventListener('mousedown', onMouseDownOut)

  return () => {
    document.removeEventListener('mousedown', onMouseDownOut)
  }
}

// 创建图片元素。
export const createImage = async (elementAttrs: Partial<HTMLImageElement>) => {
  const { resolve, promise } = usePromise<Optional<HTMLImageElement>>()
  const $image = new window.Image()

  once($image, 'load', () => resolve($image))

  once($image, 'error', () => resolve(undefined))

  Object.assign($image, {
    crossOrigin: 'Anonymous',
    ...elementAttrs,
  })

  return promise
}

// 创建音频元素。
export const createAudio = async (elementAttrs: Partial<HTMLAudioElement>) => {
  const { resolve, promise } = usePromise<Optional<HTMLAudioElement>>()
  const $audio = document.createElement('audio')

  once($audio, 'loadedmetadata', () => resolve($audio))

  once($audio, 'error', () => resolve(undefined))

  Object.assign($audio, {
    crossOrigin: 'Anonymous',
    ...elementAttrs,
  })

  return promise
}

// 创建视频元素。
export const createVideo = async (elementAttrs: Partial<HTMLVideoElement>) => {
  const { resolve, promise } = usePromise<Optional<HTMLVideoElement>>()
  const $video = document.createElement('video')

  once($video, 'loadedmetadata', () => resolve($video))

  once($video, 'error', () => resolve())

  Object.assign($video, {
    crossOrigin: 'Anonymous',
    ...elementAttrs,
  })

  return promise
}

// 根据文件获取媒体尺寸。
export const getMediaSizeByFile = async (file: File) => {
  if (isImage(file)) {
    const $img = await createImage({ src: fileToUrl(file) })

    return {
      width: $img?.width ?? 0,
      height: $img?.height ?? 0,
    }
  } else if (isVideo(file)) {
    const $video = await createVideo({ src: fileToUrl(file) })

    return {
      width: $video?.videoWidth ?? 0,
      height: $video?.videoHeight ?? 0,
    }
  }
}

// 测量文本的宽度。
export const measureTextWidth = ({
  text,
  fontSize,
  fontStyle,
  fontWeight,
  letterSpacing,
  lineHeight,
  fontFamily,
  textDecoration,
}: {
  text: string
  fontSize: string
  fontStyle: string
  fontWeight: string
  fontFamily: string
  letterSpacing: string
  lineHeight: string
  textDecoration: string
}) => {
  // 创建一个临时的 span 标签。
  let $span = document.getElementById('span-for-measure-text-size')

  if (!$span) {
    $span = document.createElement('span')

    $span.style.position = 'absolute'

    $span.style.left = '-9999px'

    $span.style.top = '-9999px'

    $span.style.zIndex = '-9999'
  }

  // 设置 span 标签的样式。
  $span.style.fontSize = fontSize

  $span.style.fontFamily = fontFamily

  $span.style.fontStyle = fontStyle

  $span.style.fontWeight = fontWeight

  $span.style.letterSpacing = letterSpacing

  $span.style.lineHeight = lineHeight

  $span.style.textDecoration = textDecoration

  // 设置 span 标签的文本。
  $span.innerText = text

  // 将 span 标签添加到 body 中。
  document.body.appendChild($span)

  const width = $span.clientWidth

  // 将 span 标签从 body 中移除。
  document.body.removeChild($span)

  return width
}

// 测量文本在指定宽度下的高度。
export const measureTextHeight = ({
  text,
  width,
  style = {},
}: {
  text: string
  width: number
  style?: {
    fontSize?: string
    fontStyle?: string
    fontWeight?: string
    fontFamily?: string
    letterSpacing?: string
    lineHeight?: string | number
    textDecoration?: string
    wordBreak?: string
    whiteSpace?: string
  }
}) => {
  // 初始化一个 div 元素，用于计算文本高度。
  let $div = document.getElementById('div-for-measure-text-multiple-height')

  if (!$div) {
    $div = document.createElement('div')

    $div.style.position = 'absolute'

    $div.style.left = '-9999px'

    $div.style.top = '-9999px'

    $div.style.zIndex = '-9999'
  }

  // 设置默认的行高。
  if (isNaN(style.lineHeight as number)) {
    style.lineHeight = 1.5
  }

  // 设置 div 的样式。
  Object.assign($div.style, style)

  $div.style.width = `${width}px`

  // 设置 div 的内容。
  $div.innerText = text

  // 将 div 加入到 DOM 中。
  document.body.appendChild($div)

  // 获取 div 的高度。
  const { height } = $div.getBoundingClientRect()

  // 将 div 从 DOM 中移除。
  document.body.removeChild($div)

  return height
}

// 播放视频。
export const playVideo = async ($video: HTMLVideoElement) => {
  const [err] = await to($video.play())
  if (err) {
    console.error(err)

    return false
  }

  return true
}
