import { Image } from 'konva/lib/shapes/Image'
import { Rect } from 'konva/lib/shapes/Rect'
import { pick } from 'lodash-es'

import { IKonvaGroupConfig, KonvaGroup } from './Group'

type Animator = {
  stop: () => void
  start: () => void
  onDrawFrame: (ctx: any, frame: any) => void
  animateInCanvas: (canvas: HTMLCanvasElement) => void
}

const PADDING = 50

export type ILoadingConfig = IKonvaGroupConfig & {
  loadingSize?: number
  position?:
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | 'rightBottom'
    | 'topCenter'
    | 'bottomCenter'
    | 'leftCenter'
    | 'rightCenter'
    | 'center'
}

export class Loading extends KonvaGroup {
  #animator?: Animator

  #url?: string

  #position: ILoadingConfig['position']

  #loadingSize: number

  #rect: Rect

  #loadingImage: Image

  constructor(loadingConfig: ILoadingConfig) {
    const {
      width,
      height,
      loadingSize = 50,
      position = 'center',
      x,
      y,
      url,
    } = pick(loadingConfig, [
      'url',
      'width',
      'height',
      'loadingSize',
      'position',
      'x',
      'y',
    ])

    super({ width, height, x, y })

    this.#position = position

    this.#loadingSize = loadingSize

    this.#url = url

    this.#rect = new Rect({
      width,
      height,
      fill: '#ffffff',
      opacity: 0.5,
    })

    this.add(this.#rect)

    this.#loadingImage = new Image({
      ...this.getLoadingPosition({ width, height }),
      width: loadingSize,
      height: loadingSize,
      cornerRadius: 9999,
      stroke: 'blue',
      image: document.createElement('canvas'),
    })

    // @ts-ignore
    gifler(this.#url).get((animator: Animator) => {
      this.#animator = animator

      animator.onDrawFrame = (
        ctx: CanvasRenderingContext2D,
        frame: { width: number; height: number; buffer: HTMLCanvasElement },
      ) => {
        ctx.canvas.width = frame.width

        ctx.canvas.height = frame.height

        ctx.drawImage(frame.buffer, 0, 0)

        this.getLayer()?.draw()
      }

      animator.animateInCanvas(this.#loadingImage.image() as HTMLCanvasElement)
    })

    this.add(this.#loadingImage)
  }

  getLoadingPosition({ width = 0, height = 0 }) {
    let x = 0
    let y = 0

    switch (this.#position) {
      // 居中。
      case 'center': {
        x = width! / 2 - this.#loadingSize / 2

        y = height! / 2 - this.#loadingSize / 2

        break
      }

      // 右上角。
      case 'rightTop': {
        x = width! - this.#loadingSize - PADDING

        y = PADDING

        break
      }

      // 右下角。
      case 'rightBottom': {
        x = width! - this.#loadingSize - PADDING

        y = height! - this.#loadingSize - PADDING

        break
      }

      default: {
        break
      }
    }

    return { x, y }
  }

  startLoading() {
    this.#animator?.start()
  }

  stopLoading() {
    this.#animator?.stop()
  }

  remove() {
    this.stopLoading()

    return super.remove()
  }
}
