export class Token {
  private _tokenName: string

  constructor(options: { name: string }) {
    this._tokenName = options.name
  }

  set(token: string) {
    localStorage.setItem(this._tokenName, token)
  }

  get() {
    return localStorage.getItem(this._tokenName)
  }

  clear() {
    this.set('')
  }
}
