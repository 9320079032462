import { IImageConfig, Image } from './Image'

export type IActorConfig = IImageConfig

export class Actor extends Image {
  constructor(actorConfig: IActorConfig) {
    super({
      ...actorConfig,
    })
  }

  get operateAttrs() {
    return {
      ...super.operateAttrs,
      hasRadius: false,
      croppable: false,
    }
  }
}
