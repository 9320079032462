import { ETemplateType } from './template'

export enum EVideoTaskStatus {
  FAILED = -1,
  PENDING,
  PROCESSING,
  SUCCESS,
}

// 音频类型。
export enum EAudioType {
  SCRIPT = 1, // 来自脚本。
  USER_AUDIO = 2, // 来自用户上传的音频文件。
}

// 素材业务类型。
export enum EMaterialBusinessType {
  BACKGROUND = 1,
  ACTOR = 2,
  IMAGE = 3,
  VIDEO = 4,
  TEXT = 5,
  SHAPE = 6,
}

// 素材业务类型文字映射。
export const MATERIAL_TYPE_TEXT_MAP = {
  [EMaterialBusinessType.BACKGROUND]: '背景',
  [EMaterialBusinessType.ACTOR]: '人物',
  [EMaterialBusinessType.IMAGE]: '图片',
  [EMaterialBusinessType.VIDEO]: '视频',
  [EMaterialBusinessType.TEXT]: '文字',
  [EMaterialBusinessType.SHAPE]: '图形',
}

// 素材文件类型，针对不同的素材业务类型，可能有不同的文件形式，比如北京可以是普通图片和纯色图片和视频等等。
export enum EMaterialFileType {
  DEFAULT = 0,
  IMAGE = 1,
  VIDEO = 2,
  COLOR_IMAGE = 3,
}

// 图形类型。
export enum EShapeType {
  RECTANGLE = 1,
  CIRCLE = 2,
  REGULAR_POLYGON = 3,
  ARROW = 4,
  LINE = 5,
}

// 图形类型和文字映射。
export const SHAPE_TYPE_TEXT_MAP = {
  [EShapeType.RECTANGLE]: '矩形',
  [EShapeType.CIRCLE]: '圆形',
  [EShapeType.REGULAR_POLYGON]: '多边形',
  [EShapeType.ARROW]: '箭头',
  [EShapeType.LINE]: '直线',
}

// 动画。
export enum EAnimation {
  NONE = 'none',
  CIRCLE_CROP = 'circlecrop',
  FADE_IN = 'fade',
  SLIDE_TO_LEFT = 'slideleft',
  SLIDE_TO_RIGHT = 'slideright',
  SLIDE_TO_UP = 'slideup',
  SLIDE_TO_DOWN = 'slidedown',
}

// 动画速率。
export enum EAnimationVelocity {
  LINAR = 'linar',
}

// 最多场景数量。
export const MAX_SCENE_COUNT = 200

// 最多素材数量。
export const MAX_MATERIAL_COUNT = 100

// 片段类型。
export enum ESceneType {
  DEFAULT = 0,
  HOME = 1,
  END = 2,
  SECTION_OUTLINE = 3,
  SUBSECTION_OUTLINE = 4,
  BODY = 5,
}

// 场景类型列表。
export const TEMPLATE_TYPE_SCENE_TYPES = {
  [ETemplateType.USER]: [
    ESceneType.HOME,
    ESceneType.SECTION_OUTLINE,
    ESceneType.SUBSECTION_OUTLINE,
    ESceneType.BODY,
    ESceneType.END,
  ],
  [ETemplateType.OPERATOR]: [ESceneType.HOME, ESceneType.BODY],
}

// 场景类型文字映射。
export const SCENE_TYPE_TEXT_MAP = {
  [ESceneType.DEFAULT]: '',
  [ESceneType.HOME]: '首页',
  [ESceneType.SECTION_OUTLINE]: '小节大纲',
  [ESceneType.SUBSECTION_OUTLINE]: '子节大纲',
  [ESceneType.BODY]: '正文',
  [ESceneType.END]: '尾页',
}

// 文本类型。
export enum ETextType {
  DEFAULT = 0,
  TITLE = 1,
  SUBTITLE = 2,
  CONTENT = 3,
  OUTLINE = 4,
}

// 文字类型和颜色映射。
export const TEXT_TYPE_COLOR_MAP = {
  [ETextType.DEFAULT]: '#999',
  [ETextType.TITLE]: '#7DA1EC',
  [ETextType.SUBTITLE]: '#7DA1EC',
  [ETextType.CONTENT]: '#6ECC84',
  [ETextType.OUTLINE]: '#6ECC84',
}

// 在不同模版类型下不同场景的文字类型列表映射。
export const TEMPLATE_TYPE_SCENE_TYPE_TEXT_TYPE_LIST_MAP = {
  [ETemplateType.USER]: {
    [ESceneType.DEFAULT]: [],
    [ESceneType.HOME]: [ETextType.TITLE, ETextType.SUBTITLE, ETextType.DEFAULT],
    [ESceneType.SECTION_OUTLINE]: [
      ETextType.TITLE,
      ETextType.OUTLINE,
      ETextType.DEFAULT,
    ],
    [ESceneType.SUBSECTION_OUTLINE]: [
      ETextType.TITLE,
      ETextType.OUTLINE,
      ETextType.DEFAULT,
    ],
    [ESceneType.BODY]: [
      ETextType.TITLE,
      ETextType.SUBTITLE,
      ETextType.CONTENT,
      ETextType.DEFAULT,
    ],
    [ESceneType.END]: [ETextType.TITLE, ETextType.SUBTITLE, ETextType.DEFAULT],
  },
  [ETemplateType.OPERATOR]: {
    [ESceneType.DEFAULT]: [],
    [ESceneType.HOME]: [ETextType.TITLE, ETextType.SUBTITLE, ETextType.DEFAULT],
    [ESceneType.SECTION_OUTLINE]: [
      ETextType.TITLE,
      ETextType.OUTLINE,
      ETextType.DEFAULT,
    ],
    [ESceneType.SUBSECTION_OUTLINE]: [
      ETextType.TITLE,
      ETextType.OUTLINE,
      ETextType.DEFAULT,
    ],
    [ESceneType.BODY]: [
      ETextType.TITLE,
      ETextType.SUBTITLE,
      ETextType.CONTENT,
      ETextType.DEFAULT,
    ],
    [ESceneType.END]: [ETextType.TITLE, ETextType.SUBTITLE, ETextType.DEFAULT],
  },
}

// 不同场景下的不同文字类型文本映射。
export const SCENE_TYPE_TEXT_TYPE_TEXT_MAP = {
  [ESceneType.DEFAULT]: {
    [ETextType.DEFAULT]: '',
    [ETextType.TITLE]: '',
    [ETextType.SUBTITLE]: '',
    [ETextType.OUTLINE]: '',
    [ETextType.CONTENT]: '',
  },
  [ESceneType.HOME]: {
    [ETextType.DEFAULT]: '静态文本',
    [ETextType.TITLE]: '课程名称',
    [ETextType.SUBTITLE]: '小节名称',
    [ETextType.OUTLINE]: '',
    [ETextType.CONTENT]: '',
  },
  [ESceneType.SECTION_OUTLINE]: {
    [ETextType.DEFAULT]: '静态文本',
    [ETextType.TITLE]: '小节名称',
    [ETextType.SUBTITLE]: '',
    [ETextType.OUTLINE]: '小节大纲',
    [ETextType.CONTENT]: '',
  },
  [ESceneType.SUBSECTION_OUTLINE]: {
    [ETextType.DEFAULT]: '静态文本',
    [ETextType.TITLE]: '子节名称',
    [ETextType.SUBTITLE]: '',
    [ETextType.OUTLINE]: '子节大纲',
    [ETextType.CONTENT]: '',
  },
  [ESceneType.BODY]: {
    [ETextType.DEFAULT]: '静态文本',
    [ETextType.TITLE]: '子节名称',
    [ETextType.SUBTITLE]: '段落名称',
    [ETextType.OUTLINE]: '',
    [ETextType.CONTENT]: '正文',
  },
  [ESceneType.END]: {
    [ETextType.DEFAULT]: '静态文本',
    [ETextType.TITLE]: '课程名称',
    [ETextType.SUBTITLE]: '小节名称',
    [ETextType.OUTLINE]: '',
    [ETextType.CONTENT]: '',
  },
}

// 首页版式。
export enum EHomeLayoutType {
  L1 = 1,
}

// 尾页版式。
export enum EEndLayoutType {
  L1 = 1,
}

// 大纲版式。
export enum ESectionOutlineLayoutType {
  L1 = 1,
}

// 子节大纲版式。
export enum ESubSectionOutlineLayoutType {
  L1 = 1,
}

// 默认版式。
export enum EDefaultLayoutType {
  L1 = 1,
}

// 正文版式。
export enum EBodyLayoutType {
  L1 = 1,
  L2,
  L3,
  L4,
  L5,
  L6,
  L7,
  L8,
  L9,
}

// 默认版式文本数量枚举。
export const DEFAULT_LAYOUT_TEXT_COUNT_MAP = {
  [EDefaultLayoutType.L1]: [0],
}

// 首页版式文本数量枚举。
export const HOME_LAYOUT_TEXT_COUNT_MAP = {
  [EHomeLayoutType.L1]: [0],
}

// 尾页版式文本数量枚举。
export const END_LAYOUT_TEXT_COUNT_MAP = {
  [EEndLayoutType.L1]: [0],
}

// 大纲版式文本数量枚举。
export const SECTION_OUTLINE_LAYOUT_TEXT_COUNT_MAP = {
  [ESectionOutlineLayoutType.L1]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
}

// 子节大纲版式文本数量枚举。
export const SUB_SECTION_OUTLINE_LAYOUT_TEXT_COUNT_MAP = {
  [ESubSectionOutlineLayoutType.L1]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
}

//
// 正文版式文本数量枚举。
export const CONTENT_LAYOUT_TEXT_COUNT_MAP = {
  [EBodyLayoutType.L1]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L2]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L3]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L4]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L5]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L6]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L7]: [4],
  [EBodyLayoutType.L8]: [1, 2, 3, 4, 5, 6],
  [EBodyLayoutType.L9]: [1, 2, 3, 4, 5, 6],
}

// 默认版式文本映射。
export const DEFAULT_LAYOUT_TYPE_TEXT_MAP = {
  [EDefaultLayoutType.L1]: '默认',
}

// 首页版式文本映射。
export const HOME_LAYOUT_TYPE_TEXT_MAP = {
  [EHomeLayoutType.L1]: '默认',
}

// 尾页版式文本映射。
export const END_LAYOUT_TYPE_TEXT_MAP = {
  [EEndLayoutType.L1]: '默认',
}

// 大纲版式文本映射。
export const SECTION_OUTLINE_LAYOUT_TYPE_TEXT_MAP = {
  [ESectionOutlineLayoutType.L1]: '默认',
}

// 子节大纲版式文本映射。
export const SUB_SECTION_OUTLINE_LAYOUT_TYPE_TEXT_MAP = {
  [ESubSectionOutlineLayoutType.L1]: '默认',
}

// 正文版式文本映射。
export const BODY_LAYOUT_TYPE_TEXT_MAP = {
  [EBodyLayoutType.L1]: '列表A',
  [EBodyLayoutType.L2]: '总分',
  [EBodyLayoutType.L3]: '',
  [EBodyLayoutType.L4]: '大图',
  [EBodyLayoutType.L5]: '列表B',
  [EBodyLayoutType.L6]: '有图',
  [EBodyLayoutType.L7]: '例题',
  [EBodyLayoutType.L8]: '',
  [EBodyLayoutType.L9]: '',
}

// 默认版式可替换素材数量映射。
export const DEFAULT_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [EDefaultLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 1,
  },
}

// 首页版式可替换素材数量映射。
export const HOME_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [EHomeLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 1,
  },
}

// 大纲版式可替换素材数量映射。
export const SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [ESectionOutlineLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
}

// 子节大纲版式可替换素材数量映射。
export const SUB_SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [ESubSectionOutlineLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
}

// 正文版式可替换素材数量映射。
export const BODY_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [EBodyLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L2]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L3]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L4]: {
    [EMaterialBusinessType.IMAGE]: 1,
  },
  [EBodyLayoutType.L5]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L6]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L7]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L8]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
  [EBodyLayoutType.L9]: {
    [EMaterialBusinessType.IMAGE]: 0,
  },
}

// 尾页版式可替换素材数量映射。
export const END_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [EEndLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 1,
  },
}

// 大纲版式可替换图片数量映射。
export const SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP =
  {
    [ESectionOutlineLayoutType.L1]: {
      [EMaterialBusinessType.IMAGE]: 0,
      [EMaterialBusinessType.TEXT]: 1,
    },
  }

// 子节大纲版式可替换图片数量映射。
export const SUB_SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP =
  {
    [ESubSectionOutlineLayoutType.L1]: {
      [EMaterialBusinessType.IMAGE]: 0,
      [EMaterialBusinessType.TEXT]: 1,
    },
  }

// 正文版式在项目组中可替换图片数量映射。
export const BODY_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP = {
  [EBodyLayoutType.L1]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L2]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L3]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L4]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L5]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L6]: {
    [EMaterialBusinessType.IMAGE]: 1,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L7]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L8]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
  [EBodyLayoutType.L9]: {
    [EMaterialBusinessType.IMAGE]: 0,
    [EMaterialBusinessType.TEXT]: 1,
  },
}

// 首页版式类型列表。
export const HOME_LAYOUT_TYPES = [EHomeLayoutType.L1]

// 默认版式类型列表。
export const DEFAULT_LAYOUT_TYPES = [EDefaultLayoutType.L1]

// 尾页版式类型列表。
export const END_LAYOUT_TYPES = [EEndLayoutType.L1]

// 大纲版式类型列表。
export const SECTION_OUTLINE_LAYOUT_TYPES = [ESectionOutlineLayoutType.L1]

// 子节大纲版式类型列表。
export const SUB_SECTION_OUTLINE_LAYOUT_TYPES = [
  ESubSectionOutlineLayoutType.L1,
]

// 正文版式类型列表。
export const BODY_LAYOUT_TYPES = [
  EBodyLayoutType.L1,
  EBodyLayoutType.L2,

  // EBodyLayoutType.L3,
  EBodyLayoutType.L4,
  EBodyLayoutType.L5,
  EBodyLayoutType.L6,
  EBodyLayoutType.L7,

  // EBodyLayoutType.L8,

  // EBodyLayoutType.L9,
]

// 场景类型版式类型列表映射。
export const SCENE_TYPE_LAYOUT_TYPES_MAP = {
  [ESceneType.DEFAULT]: DEFAULT_LAYOUT_TYPES,
  [ESceneType.HOME]: HOME_LAYOUT_TYPES,
  [ESceneType.END]: END_LAYOUT_TYPES,
  [ESceneType.SECTION_OUTLINE]: SECTION_OUTLINE_LAYOUT_TYPES,
  [ESceneType.SUBSECTION_OUTLINE]: SUB_SECTION_OUTLINE_LAYOUT_TYPES,
  [ESceneType.BODY]: BODY_LAYOUT_TYPES,
}

// 场景类型版式类型文本映射。
export const SCENE_TYPE_LAYOUT_TYPE_TEXT_MAP = {
  [ESceneType.DEFAULT]: DEFAULT_LAYOUT_TYPE_TEXT_MAP,
  [ESceneType.HOME]: HOME_LAYOUT_TYPE_TEXT_MAP,
  [ESceneType.END]: END_LAYOUT_TYPE_TEXT_MAP,
  [ESceneType.SECTION_OUTLINE]: SECTION_OUTLINE_LAYOUT_TYPE_TEXT_MAP,
  [ESceneType.SUBSECTION_OUTLINE]: SUB_SECTION_OUTLINE_LAYOUT_TYPE_TEXT_MAP,
  [ESceneType.BODY]: BODY_LAYOUT_TYPE_TEXT_MAP,
} as Record<ESceneType, Record<number, string>>

// 场景类型版式类型文本数量映射。
export const SCENE_TYPE_LAYOUT_TEXT_COUNT_MAP = {
  [ESceneType.DEFAULT]: DEFAULT_LAYOUT_TEXT_COUNT_MAP,
  [ESceneType.HOME]: HOME_LAYOUT_TEXT_COUNT_MAP,
  [ESceneType.END]: END_LAYOUT_TEXT_COUNT_MAP,
  [ESceneType.SECTION_OUTLINE]: SECTION_OUTLINE_LAYOUT_TEXT_COUNT_MAP,
  [ESceneType.SUBSECTION_OUTLINE]: SUB_SECTION_OUTLINE_LAYOUT_TEXT_COUNT_MAP,
  [ESceneType.BODY]: CONTENT_LAYOUT_TEXT_COUNT_MAP,
} as Record<ESceneType, Record<number, number[]>>

// 场景类型版式类型默认版式映射。
export const SCENE_TYPE_LAYOUT_DEFAULT_MAP = {
  [ESceneType.DEFAULT]: EDefaultLayoutType.L1,
  [ESceneType.HOME]: EHomeLayoutType.L1,
  [ESceneType.END]: EEndLayoutType.L1,
  [ESceneType.SECTION_OUTLINE]: ESectionOutlineLayoutType.L1,
  [ESceneType.SUBSECTION_OUTLINE]: ESubSectionOutlineLayoutType.L1,
  [ESceneType.BODY]: EBodyLayoutType.L1,
}

// 可替换的素材类型列表。
export const REPLACEABLE_MATERIAL_TYPES = [EMaterialBusinessType.IMAGE] as const

// 场景类型版式类型可替换素材数量映射。
export const SCENE_TYPE_LAYOUT_REPLACEABLE_MATERIAL_COUNT_MAP = {
  [ESceneType.DEFAULT]: DEFAULT_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP,
  [ESceneType.HOME]: HOME_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP,
  [ESceneType.END]: END_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP,
  [ESceneType.SECTION_OUTLINE]:
    SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP,
  [ESceneType.SUBSECTION_OUTLINE]:
    SUB_SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP,
  [ESceneType.BODY]: BODY_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_MAP,
} as Record<
  ESceneType,
  Record<number, Record<(typeof REPLACEABLE_MATERIAL_TYPES)[number], number>>
>

// 项目组中的可替换的素材类型列表。
export const REPLACEABLE_MATERIAL_IN_ITEM_GROUP_TYPES = [
  EMaterialBusinessType.IMAGE,
  EMaterialBusinessType.TEXT,
] as const

// 场景类型版式类型在项目组中可替换素材数量映射。
export const SCENE_TYPE_LAYOUT_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP = {
  [ESceneType.SECTION_OUTLINE]:
    SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP,
  [ESceneType.SUBSECTION_OUTLINE]:
    SUB_SECTION_OUTLINE_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP,
  [ESceneType.BODY]:
    BODY_LAYOUT_TYPE_REPLACEABLE_MATERIAL_COUNT_IN_ITEM_GROUP_MAP,
} as Record<
  number,
  Record<
    number,
    Record<(typeof REPLACEABLE_MATERIAL_IN_ITEM_GROUP_TYPES)[number], number>
  >
>

// 声音来源。
export enum EVoiceSourceType {
  MICROSOFT = 1,
  ALI,
}

// 字幕视频合成状态。
export enum ESubtitleVideoStatus {
  INIT = 'Init',
  PROCESSING = 'Processing',
  SUCCESS = 'Success',
  FAIL = 'Fail',
}
