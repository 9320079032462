import { mixinBackground } from './Background'
import { IVideoConfig, Video } from './Video'

export type IVideoBackgroundConfig = IVideoConfig

export class VideoBackground extends Video {
  constructor(videoBackgroundConfig: IVideoBackgroundConfig) {
    super({
      ...videoBackgroundConfig,
    })

    mixinBackground(this)
  }
}
