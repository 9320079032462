import { ECacheTime } from '@lanyan/constant/src/common'
import { Nullable } from '@lanyan/type'
import { parseJSON } from '@lanyan/util/src/helper'
import dayjs from 'dayjs'
import localforage from 'localforage'
import { isNumber, isString } from 'lodash-es'

let localStorage: Nullable<{
  getItem: <T>(key: string) => Promise<Nullable<T>> | Nullable<T>
  setItem: (key: string, value: any) => void
  removeItem: (key: string) => void
}>

// 过期时间。
const EXPIRE_TIME = 'expire-time'

const cacheDataMapForMemory: Record<string, any> = {}

// 获取过期时间的 key
const getExpireTimeKey = (key: string) => [key, EXPIRE_TIME].join('-')

declare const wx: any
declare const uni: any

// 是否有 window 对象。
const hasWindow = typeof window !== 'undefined'

// 是否有 self 对象。
const hasSelf = typeof self !== 'undefined'

// 是否有微信小程序对象。
const hasWx = typeof wx !== 'undefined'

// 是否有 uni 对象。
const hasUni = typeof uni !== 'undefined'

// 数据缓存，支持三个模式，内存、sessionStorage、indexDB，不同类型支持存储的数据类型都不一致。
// 内存：所有数据都支持。
// sessionStorage：只支持字符串或能够转化成 JSON 字符串的数据，且不支持 worker
// indexDB：支持结构化克隆数据【MDN Reference】（https：//developer。mozilla。org/en-US/docs/Web/API/structuredClone）
export const useStorage = ({
  cacheTime,
}: {
  cacheTime: `${ECacheTime}` | number // 缓存时间。
}) => {
  if (cacheTime === ECacheTime.SESSION && !hasWindow) {
    throw new Error('当前环境不支持 SessionStorage')
  }

  let useStorage = false

  if (isNumber(cacheTime)) {
    if ((hasWindow || hasSelf) && indexedDB && !localStorage) {
      const localforageInstance = localforage.createInstance({
        name: 'useStorage',
      })
      localStorage = {
        getItem: (key: string) => localforageInstance.getItem<any>(key),
        setItem: (key: string, value: any) =>
          localforageInstance.setItem(key, value),
        removeItem: (key: string) => localforageInstance.removeItem(key),
      }
    } else if ((hasWindow || hasSelf) && localStorage && !localStorage) {
      const storage = self.localStorage || window.localStorage
      localStorage = {
        getItem: <T = string>(key: string) =>
          storage.getItem(key) as Nullable<T>,
        setItem: (key: string, value: any) => storage.setItem(key, value),
        removeItem: (key: string) => storage.removeItem(key),
      }
      useStorage = true
    } else if (hasUni) {
      localStorage = {
        getItem: (key: string) => uni.getStorageSync(key),
        setItem: (key: string, value: any) => uni.setStorageSync(key, value),
        removeItem: (key: string) => uni.removeStorageSync(key),
      }
    } else if (hasWx) {
      localStorage = {
        getItem: (key: string) => wx.getStorageSync(key),
        setItem: (key: string, value: any) => wx.setStorageSync(key, value),
        removeItem: (key: string) => wx.removeStorageSync(key),
      }
      useStorage = true
    }

    if (!localStorage) {
      throw new Error('没有可用的存储API')
    }
  }

  // 存储数据。
  const set = <T>(key: string, value: T) => {
    if (useStorage && !isString(value)) {
      throw new Error('当前存储不支持非字符串类型')
    }

    if (cacheTime === ECacheTime.MEMORY) {
      cacheDataMapForMemory[key] = value
    } else if (cacheTime === ECacheTime.SESSION) {
      sessionStorage.setItem(key, JSON.stringify(value))
    } else {
      const expireKey = getExpireTimeKey(key)
      const expireTime = +new Date() + +cacheTime
      localStorage!.setItem(key, value)
      localStorage!.setItem(expireKey, expireTime)
    }
  }

  const checkState = <T>(data: Nullable<T>, expireTime: Nullable<string>) => {
    return data && expireTime && dayjs(expireTime).isAfter(dayjs(), 'ms')
  }

  // 获取数据。
  const get = async <T>(key: string) => {
    if (cacheTime === ECacheTime.MEMORY) {
      return (cacheDataMapForMemory[key] as T) ?? null
    }

    if (cacheTime === ECacheTime.SESSION) {
      const data = sessionStorage.getItem(key)

      return data ? parseJSON<T>(data) ?? (data as T) : null
    }

    const expireKey = getExpireTimeKey(key)
    const data = await localStorage!.getItem(key)
    const expireTime = await localStorage!.getItem<string>(expireKey)
    if (checkState(data, expireTime)) {
      return data as T
    }

    // 过期。
    localStorage!.removeItem(key)
    localStorage!.removeItem(expireKey)

    return null
  }

  const remove = (key: string) => {
    if (cacheTime === ECacheTime.MEMORY) {
      cacheDataMapForMemory[key] = null
    } else if (cacheTime === ECacheTime.SESSION) {
      sessionStorage.removeItem(key)
    } else {
      localStorage?.removeItem(key)
    }
  }

  return [get, set, remove] as const
}
