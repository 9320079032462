import { IResponseData } from '@lanyan/type'
import { Request as BaseRequest } from '@lanyan/util'
import { message } from 'ant-design-vue'
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { isRegExp } from 'lodash-es'

import router from '@/router'
import { ISchool } from '@/types/school'
import { token } from '@/utils/token'

import { schoolInfoStorage } from './localforage'

const COMMON_ERROR_MSG = '出错了，请稍后再试'

const WITH_SCHOOL_ID_WHITELIST = [/web-info/]

const handleToken = async (
  config: InternalAxiosRequestConfig,
  token: string,
) => {
  config.headers.Authorization = `${token}`
}

const handleSchoolId = async (
  config: InternalAxiosRequestConfig,
  school_id: number,
) => {
  config.headers['School-Id'] = `${school_id}`
}

const shouldSkipSchoolIdAuth = ({
  url,
  needAuth,
}: InternalAxiosRequestConfig) => {
  if (needAuth) {
    return false
  }

  return WITH_SCHOOL_ID_WHITELIST.some((whitelistItem) => {
    if (isRegExp(whitelistItem)) {
      return whitelistItem.test(url!)
    }

    return url!.includes(whitelistItem)
  })
}

const showError = (error?: string) => {
  message.error({
    content: error,
    duration: 5,
  })
}

class Request extends BaseRequest {
  constructor(...params: ConstructorParameters<typeof BaseRequest>) {
    super(...params)
    this.addRequestInterceptor(
      this.authRequestInterceptor,
      this.commonRequestInterceptorExceptionHandler,
    )

    this.addResponseInterceptor(
      this.commonResponseInterceptor,
      this.commonResponseInterceptorExceptionHandler,
    )
  }

  // 通用请求异常处理器
  commonRequestInterceptorExceptionHandler(error: AxiosError) {
    console.error(error)

    return Promise.reject(error)
  }

  // 通用请求处理器
  async authRequestInterceptor(config: InternalAxiosRequestConfig) {
    const { version } = config
    if (version) {
      config.url = `/v${version}${config.url}`
    }

    if (shouldSkipSchoolIdAuth(config)) {
      return config
    }

    const schoolInfo = (await schoolInfoStorage.getItem('schoolInfo')) as string

    if (schoolInfo) {
      const school_id = (JSON.parse(schoolInfo) as unknown as ISchool.Detail).id

      if (school_id) {
        handleSchoolId(config, school_id)
      } else {
        router.push(`/login`)

        return Promise.reject(config)
      }
    } else {
      router.push(`/login`)

      return Promise.reject(config)
    }

    if (!(config.needAuth ?? true)) {
      return config
    }

    const tokenStr = token.get()

    if (tokenStr) {
      await handleToken(config, tokenStr)

      return config
    }

    router.push(`/login`)

    return Promise.reject(config)
  }

  // 通用响应处理器
  async commonResponseInterceptor(response: AxiosResponse) {
    const { config, data } = response
    if (config.responseType === 'blob') {
      return data
    }

    if (data.code === 0) {
      return data
    }

    const errorMsg = data.message ?? COMMON_ERROR_MSG
    if (config.loggable) {
      showError(errorMsg)
    }

    return Promise.reject(response)
  }

  // 通用响应异常处理器
  async commonResponseInterceptorExceptionHandler(
    error: AxiosError<IResponseData<any>>,
  ) {
    console.error(error)
    const { status, data } = error.response ?? {}
    const { onCancel, loggable = false } = error.config ?? {}

    // token失效
    if (status === 401 && data?.code === 40102) {
      token.clear()

      router.push(`/login`)
    } else if (error instanceof axios.Cancel) {
      console.log('取消请求')
      onCancel?.(error)
    } else {
      if (loggable) {
        showError(error.message)
      }
    }

    return Promise.reject(error)
  }
}

export default Request
