import { EEnv } from './common'

// 数字人状态
export enum EActorStatus {
  UP = 1,
  DOWN = 2,
}

// 数字人来源
export enum EActorSourceChannel {
  SELF_OWN = 1,
  YUE_QIAN = 2,
  ALI = 3,
}

// 数字人类型
export enum EActorPortraitType {
  AVATAR = 1,
  HALF_BODY = 2,
  BIG_HALF_BODY = 3,
  FULL_BODY = 4,
}

// 数字默认宽度
export const ACTOR_DEFAULT_WIDTH = 1080

// 数字默认高度
export const ACTOR_DEFAULT_HEIGHT = 1080

// 数字人默认类型
export const ACTOR_DEFAULT_TYPE = EActorPortraitType.AVATAR

// 测试环境默认数字人
export const TESTING_ACTOR = {
  id: 86,
  img_url: 'https://testing-cdn.lanyan.tech/actor/models-3/馨馨营销站姿.png',
  width: 1080,
  height: 1920,
  portrait_type: EActorPortraitType.BIG_HALF_BODY,
}

// 生产环境默认数字人
export const PRODUCTION_ACTOR = {
  id: 50,
  img_url: 'https://cdn.lanyan.tech/actor/models-v3/馨馨营销站姿.png',
  width: 1080,
  height: 1920,
  portrait_type: EActorPortraitType.BIG_HALF_BODY,
}

// 环境和默认数字人映射
export const ENV_DEFAULT_ACTOR = {
  [EEnv.PRODUCTION]: PRODUCTION_ACTOR,
  [EEnv.DEVELOPMENT]: TESTING_ACTOR,
  [EEnv.TEST]: TESTING_ACTOR,
}
