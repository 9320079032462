import { isEqual, isNil } from 'lodash-es'

import { EGraphicEvent } from '../config'
import { IKonvaRectConfig, KonvaRect } from '../konva/Rect'
import { formatCornerRadiusToArray } from '../util'
import { GraphicShape, IGraphicShapeConfig } from './Shape'

export type IGraphicRectConfig = IGraphicShapeConfig & IKonvaRectConfig

export class GraphicRect extends GraphicShape<KonvaRect> {
  constructor(graphicRectConfig: IGraphicRectConfig) {
    super({
      ...graphicRectConfig,
      graphic: new KonvaRect({
        ...graphicRectConfig,
      }),
    })

    // 处理拖拽。
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      this.graphic.setAttrs(this.box.attrs)
    })

    this.on(EGraphicEvent.RESIZE, () => {
      const { width, height } = this.calcActualRectBox
      this.graphic.setAttrs({
        x: this.box.x(),
        y: this.box.y(),
        width: this.graphicEditor!.actualSizeToLogicalSize(width),
        height: this.graphicEditor!.actualSizeToLogicalSize(height),
        scaleX: 1,
        scaleY: 1,
        cornerRadius: this.cornerRadius(),
      })
    })

    // 处理缩放结束。
    this.on(EGraphicEvent.RESIZE_END, () => {
      const { width, height } = this.calcActualRectBox
      this.box.setAttrs({
        width: this.graphicEditor!.actualSizeToLogicalSize(width),
        height: this.graphicEditor!.actualSizeToLogicalSize(height),
        scaleX: 1,
        scaleY: 1,
      })
    })
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      return super.x()
    }

    x = super.x(x)

    this.graphic.x(this.box.x())

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      return super.y()
    }

    y = super.y(y)

    this.graphic.y(this.box.y())

    return y
  }

  // 重写父类的 height 方法。
  width(width?: number) {
    if (isNil(width)) {
      return super.width()
    }

    super.width(width)
    this.graphic.width(this.box.width())

    return width
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    if (isNil(height)) {
      return super.height()
    }

    super.height(height)
    this.graphic.height(this.box.height())

    return height
  }

  // 设置或获取圆角。
  cornerRadius(cornerRadius?: number[]) {
    if (isNil(cornerRadius)) {
      return formatCornerRadiusToArray(this.graphic.cornerRadius())
    }

    if (!isEqual(cornerRadius, this.graphic.cornerRadius())) {
      this.graphic.cornerRadius([...cornerRadius])
    }

    return cornerRadius
  }

  // 矩形属性。
  get attrs() {
    return {
      ...super.attrs,
      cornerRadius: this.cornerRadius(),
    }
  }

  get operateAttrs() {
    return {
      ...super.operateAttrs,
      hasRadius: true,
    }
  }
}
