import { isNil } from 'lodash-es'

import { EGraphicEvent, EGraphicType } from '../config'
import { KonvaImage } from '../konva/Image'
import { Loading } from '../konva/Loading'
import { ANGLE_RESIZE_ANCHOR_LIST } from '../konva/Transformer'
import { KonvaVideo } from '../konva/Video'
import { Graphic, IGraphicConfig } from './Graphic'

type IKonvaMedia = KonvaImage | KonvaVideo
export type IGraphicMediaConfig = IGraphicConfig & {
  onLoadMedia?: (image: ImageBitmap) => void
  onDrawComplete?: () => void
  onGetUrl?: (url: string) => void
}
export abstract class GraphicMedia<T extends IKonvaMedia> extends Graphic<T> {
  // 图形类型。
  abstract graphicType: EGraphicType

  #onLoadMedia: IGraphicMediaConfig['onLoadMedia']

  #onGetUrl: IGraphicMediaConfig['onGetUrl']

  #onDrawComplete: IGraphicMediaConfig['onDrawComplete']

  #loading?: Loading

  firstDrawIsComplete = false

  constructor(graphicMediaConfig: IGraphicMediaConfig & { graphic: T }) {
    super({
      ...graphicMediaConfig,
    })

    this.#onLoadMedia = graphicMediaConfig.onLoadMedia
    this.#onGetUrl = graphicMediaConfig.onGetUrl
    this.#onDrawComplete = graphicMediaConfig.onDrawComplete

    this.graphic.off(EGraphicEvent.FIRST_DRAW_COMPLETE)
    this.graphic.on(EGraphicEvent.FIRST_DRAW_COMPLETE, () => {
      this.firstDrawIsComplete = true
      this.fire(EGraphicEvent.FIRST_DRAW_COMPLETE)
    })

    this.graphic.off(EGraphicEvent.DRAW_COMPLETE)
    this.graphic.on(EGraphicEvent.DRAW_COMPLETE, () => {
      this.#onDrawComplete?.()
      this.fire(EGraphicEvent.DRAW_COMPLETE)
    })

    this.graphic.off(EGraphicEvent.START_LOAD_MEDIA)
    this.graphic.on(EGraphicEvent.START_LOAD_MEDIA, () => {
      this.fire(EGraphicEvent.START_LOAD_MEDIA)
    })

    this.graphic.off(EGraphicEvent.URL_CHANGE)
    this.graphic.on(EGraphicEvent.URL_CHANGE, () => {
      this.fire(EGraphicEvent.URL_CHANGE)
    })

    this.graphic.off(EGraphicEvent.LOAD_MEDIA_SUCCESS)
    this.graphic.on(EGraphicEvent.LOAD_MEDIA_SUCCESS, () => {
      this.#onLoadMedia?.(this.graphic.imageBitmap!)
      this.fire(EGraphicEvent.LOAD_MEDIA_SUCCESS)

      this.graphic.off(EGraphicEvent.GETTING_URL)
      this.graphic.on(EGraphicEvent.GETTING_URL, () => {
        // 创建并添加 loading
        this.#loading = new Loading({
          ...this.box.size(),
          ...this.box.position(),
          url: `${
            import.meta.env.VITE_OSS_PROD_DOMAIN
          }/assets/pc/image/loading-230512.gif`,
        })
        this.group.add(this.#loading)
        const removeLoading = () => {
          // 移除 loading
          this.#loading?.remove()
          this.transformer?.removeNode(this.#loading!)
          this.#loading = undefined
        }

        this.graphic.off(EGraphicEvent.GET_URL_FAIL)
        this.graphic.on(EGraphicEvent.GET_URL_FAIL, () => {
          removeLoading()
          this.fire(EGraphicEvent.GET_URL_FAIL)
        })

        this.graphic.off(EGraphicEvent.GET_URL_SUCCESS)
        this.graphic.on(EGraphicEvent.GET_URL_SUCCESS, () => {
          removeLoading()
          this.#onGetUrl?.(this.url()!)
          this.fire(EGraphicEvent.GET_URL_SUCCESS)
        })
      })
    })
  }

  // 设置或获取 url
  url(
    config?: Pick<
      IGraphicMediaConfig,
      'onLoadMedia' | 'onGetUrl' | 'onDrawComplete'
    > &
      Parameters<T['load']>[0],
  ) {
    if (isNil(config)) {
      return this.graphic.url
    }

    this.#onLoadMedia = config.onLoadMedia
    this.#onGetUrl = config.onGetUrl
    this.#onDrawComplete = config.onDrawComplete

    this.graphic.load(config)
  }

  // 媒体属性。
  get attrs() {
    return {
      ...super.attrs,
      url: this.url(),
    }
  }

  // 是否应该禁用修改属性。
  shouldDisableModifyAttr(attr: string) {
    if (['x', 'y', 'width', 'height'].includes(attr)) {
      return !this.url()
    }

    return false
  }

  // 操作属性。
  get operateAttrs(): Graphic['operateAttrs'] {
    const hasUrl = !!this.url()

    return {
      enabledAnchors: ANGLE_RESIZE_ANCHOR_LIST,
      keepRatio: true,
      borderEnabled: true,
      resizeEnabled: hasUrl,
      rotateEnabled: false,
      maskEnabled: hasUrl,
      draggable: hasUrl,
      croppable: false,
      selectable: hasUrl,
      hoverEnabled: hasUrl,
      hasRadius: false,
    }
  }
}
