import {
  GraphicVideo,
  IGraphicVideoConfig,
} from '../../GraphicEditor/graphics/Video'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type IVideoConfig = IGraphicVideoConfig & IMaterialConfig
export interface Video extends Material {}
export class Video extends GraphicVideo {
  constructor(videoConfig: IVideoConfig) {
    super({
      ...videoConfig,
    })
    mixinMaterial(this, videoConfig)
  }

  get operateAttrs() {
    return {
      ...super.operateAttrs,
      croppable: false,
    }
  }
}
