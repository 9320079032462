import { ECacheTime } from '@lanyan/constant'
import { useUnrepeatable } from '@lanyan/hook/src/useUnrepeatable'
import { Image, ImageConfig } from 'konva/lib/shapes/Image'
import { omit } from 'lodash-es'

import { fileToUrl } from '../../file'
import { EGraphicEvent } from '../config'

export type IKonvaImageConfig = ImageConfig & {
  url?: string
  file?: File
  getMediaUrl?: (file: File) => Promise<string>
}

// 通过图片 url 创建 ImageBitmap，不会重复创建
const [noRepeatCreateImageBitmapByUrl] = useUnrepeatable(
  async (src: string) => {
    const imgBlob = await fetch(src!).then((r) => r.blob())
    const img = await createImageBitmap(imgBlob)

    return img
  },
  {
    cacheId: 'konvaImageBitmap',
    cacheTime: ECacheTime.MEMORY,
  },
)

export class KonvaImage extends Image {
  imageBitmap?: ImageBitmap

  url?: string

  constructor(imageConfig: IKonvaImageConfig) {
    super({ width: 0, height: 0, ...omit(imageConfig, 'image') })

    this.load({ ...imageConfig, isFirstDraw: true })
  }

  // 图片宽高比。
  get ratio() {
    if (this.imageBitmap) {
      return this.imageBitmap.width / this.imageBitmap.height
    }
  }

  // 加载图片。
  async load({
    url,
    file,
    getMediaUrl,
    isFirstDraw,
  }: Pick<IKonvaImageConfig, 'url' | 'file' | 'getMediaUrl'> & {
    isFirstDraw?: boolean
  }) {
    const sourceUrl = url || (file && fileToUrl(file))
    if (!sourceUrl) {
      return
    }

    const oldUrl = this.url
    this.url = url
    this.fire(EGraphicEvent.START_LOAD_MEDIA)
    this.imageBitmap = await noRepeatCreateImageBitmapByUrl(sourceUrl)
    this.image(this.imageBitmap)
    this.fire(EGraphicEvent.LOAD_MEDIA_SUCCESS)
    requestAnimationFrame(() => {
      if (isFirstDraw) {
        this.fire(EGraphicEvent.FIRST_DRAW_COMPLETE)
      }

      this.fire(EGraphicEvent.DRAW_COMPLETE)
    })

    if (this.url) {
      if (oldUrl && oldUrl !== url) {
        this.fire(EGraphicEvent.URL_CHANGE)
      }
    } else {
      if (file && getMediaUrl) {
        this.fire(EGraphicEvent.GETTING_URL)
        this.url = await getMediaUrl(file)
        if (this.url) {
          this.fire(EGraphicEvent.GET_URL_SUCCESS)
          this.fire(EGraphicEvent.URL_CHANGE)
        } else {
          this.fire(EGraphicEvent.GET_URL_FAIL)
        }
      }
    }
  }
}
