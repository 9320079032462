import { createRouter, createWebHistory } from 'vue-router'

import course from './modules/course'
import user from './modules/user'

const routes = [
  {
    path: '/',
    component: () => import('@/views/Layout/index.vue'),
    redirect: '/course',
    children: [course, user],
  },
  { path: '/login', component: () => import('@/views/Login/index.vue') },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
