import { isNil } from 'lodash-es'

import {
  IKonvaRegularPolygonConfig,
  KonvaRegularPolygon,
} from '../konva/RegularPolygon'
import {
  GraphicSymmetryShape,
  IGraphicSymmetryShapeConfig,
} from './SymmetryShape'

export type IGraphicRegularPolygonConfig = IGraphicSymmetryShapeConfig &
  IKonvaRegularPolygonConfig

export class GraphicRegularPolygon extends GraphicSymmetryShape<KonvaRegularPolygon> {
  constructor(graphicRegularPolygonConfig: IGraphicRegularPolygonConfig) {
    super({
      ...graphicRegularPolygonConfig,
      graphic: new KonvaRegularPolygon({
        ...graphicRegularPolygonConfig,
      }),
    })
  }

  // 设置或获取边数。
  sides(sides?: number) {
    if (isNil(sides)) {
      return this.graphic.sides()
    }

    this.graphic.sides(sides)

    return sides
  }

  // 正多边形属性。
  get attrs() {
    return {
      ...super.attrs,
      sides: this.sides(),
    }
  }
}
