import { mixinBackground } from './Background'
import { IImageConfig, Image } from './Image'

export type IImageBackgroundConfig = IImageConfig

export class ImageBackground extends Image {
  constructor(imageBackgroundConfig: IImageBackgroundConfig) {
    super({
      ...imageBackgroundConfig,
    })
    mixinBackground(this)
  }
}
