import { ShapeConfig } from 'konva/lib/Shape'

import { IKonvaEllipseConfig, KonvaEllipse } from '../konva/Ellipse'
import {
  GraphicDissymmetryShape,
  IGraphicDissymmetryShapeConfig,
} from './DissymmetryShape'

export type IGraphicEllipseConfig = IGraphicDissymmetryShapeConfig & ShapeConfig

export class GraphicEllipse extends GraphicDissymmetryShape<KonvaEllipse> {
  constructor(EllipseConfig: IGraphicEllipseConfig) {
    super({
      ...EllipseConfig,
      graphic: new KonvaEllipse({
        ...EllipseConfig,
      } as IKonvaEllipseConfig),
    })
  }
}
