import {
  GraphicImage,
  IGraphicImageConfig,
} from '../../GraphicEditor/graphics/Image'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type IImageConfig = IGraphicImageConfig & IMaterialConfig
export interface Image extends Material {}
export class Image extends GraphicImage {
  constructor(imageConfig: IImageConfig) {
    super({
      ...imageConfig,
    })
    mixinMaterial(this, imageConfig)
  }
}
