import { RouteRecordRaw } from 'vue-router'

export default {
  path: 'user',
  redirect: '/user/list',
  component: () => import('@/views/User/index.vue'),
  meta: {
    title: '用户管理',
  },
  children: [
    {
      path: '/user/list',
      component: () => import('@/views/User/List/index.vue'),
    },
    {
      path: '/user/detail/:id',
      name: 'userDetail',
      component: () => import('@/views/User/Detail/index.vue'),
      meta: {
        title: '用户详情',
      },
    },
  ],
} as RouteRecordRaw
