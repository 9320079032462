// 判断是否在移动设备上
export const isMobile = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent)

// 判断是否在 iOS 设备上
export const isiOS = () => /iPhone|iPad|iPod/i.test(navigator?.userAgent)

// 判断是否在 Android 设备上
export const isAndroid = () => /Android/i.test(navigator?.userAgent)

// 判断是否在特定浏览器上（以 Chrome 为例）
export const isChrome = () => /Chrome/i.test(navigator?.userAgent)

// 判断是否在 Safari 浏览器上
export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator?.userAgent)

// 判断是否在 Firefox 浏览器上
export const isFirefox = () => /Firefox/i.test(navigator?.userAgent)

// 判断是否在 Edge 浏览器上
export const isEdge = () => /Edg/i.test(navigator?.userAgent)

// 判断是否在 IE 浏览器上（注意：越来越少的现代浏览器支持 IE 判断）
export const isIE = () => /Trident|MSIE/i.test(navigator?.userAgent)

// 判断是否在 Windows 系统上
export const isWindows = () => /Windows/i.test(navigator?.userAgent)

// 判断是否在 mac 系统上
export const isMac = () => /Macintosh|Mac OS X/i.test(navigator?.userAgent)

// 判断是否支持OffscreenCanvas
export const isSupportOffscreenCanvas = () => !!OffscreenCanvas

// 判断是否支持worker
export const isSupportWorker = () => !!Worker

// 判断是否支持位图
export const isSupportImageBitmap = () => !!ImageBitmap

// 判断是否在 web worker 中
export const isWebWorker = () =>
  typeof window === 'undefined' && typeof self !== 'undefined'
