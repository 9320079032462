export interface Material {
  materialId?: number
  replaceable?: number
  templateMaterialId?: string
}

export type IMaterialConfig = Material

export const mixinMaterial = <T extends Material>(
  target: T,
  materialConfig: IMaterialConfig,
) => {
  target.materialId = materialConfig.materialId
  target.replaceable = materialConfig.replaceable
  target.templateMaterialId = materialConfig.templateMaterialId
}
