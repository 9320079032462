import { EBool } from './common'

export const VIDEO_WATERMARK_TEXT_MAP = {
  [EBool.TRUE]: '标准',
  [EBool.FALSE]: '无水印',
}

// 支付方式
export enum EPaymentMethod {
  BALANCE = 1,
  ALIPAY,
  WECHAT,
}

// 支付方式文案
export const PAYMENT_METHOD_TEXT_MAP = {
  [EPaymentMethod.ALIPAY]: '支付宝',
  [EPaymentMethod.WECHAT]: '微信',
  [EPaymentMethod.BALANCE]: '余额',
}

// 收费标准
export const VIDEO_CHARGES_TEXT_MAP = {
  [EBool.TRUE]: 10,
  [EBool.FALSE]: 20,
}

// 支付结果
export enum EPaymentStatus {
  UNPAID = 1,
  SUCCESS,
  FAILED,
}

// 支付结果文案
export const PAYMENT_STATUS_TEXT_MAP = {
  [EPaymentStatus.UNPAID]: '未支付',
  [EPaymentStatus.SUCCESS]: '支付成功',
  [EPaymentStatus.FAILED]: '支付失败',
}
