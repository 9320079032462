import { RouteRecordRaw } from 'vue-router'

export default {
  path: 'course',
  redirect: '/course/list',
  component: () => import('@/views/Course/index.vue'),
  meta: {
    title: '课程管理',
  },
  children: [
    {
      path: '/course/list',
      component: () => import('@/views/Course/List/index.vue'),
    },
    {
      path: '/course/detail/:id',
      name: 'courseDetail',
      component: () => import('@/views/Course/Detail/index.vue'),
      meta: {
        title: '课程详情',
      },
    },
  ],
} as RouteRecordRaw
