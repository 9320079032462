import { ETextType } from '@lanyan/constant'

import {
  GraphicText,
  IGraphicTextConfig,
} from '../../GraphicEditor/graphics/Text'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type ITextConfig = IGraphicTextConfig &
  IMaterialConfig & {
    textType?: ETextType
  }

export interface Text extends Material {}

export class Text extends GraphicText {
  textType: ETextType

  constructor(textConfig: ITextConfig) {
    super({
      ...textConfig,
    })
    this.textType = textConfig.textType ?? ETextType.DEFAULT
    mixinMaterial(this, textConfig)
  }
}
