// 操作模式。
export enum EOpMode {
  VIEW = 'view',
  READONLY = 'readonly',
  EDIT = 'edit',
  ADD = 'add',
}

// 开发环境。
export enum EEnv {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  TEST = 'test',
}

// 性别。
export enum EGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

// 性别文字映射。
export const GENDER_TEXT_MAP = {
  [EGender.FEMALE]: '女',
  [EGender.MALE]: '男',
}

// 动画时间。
export const ANIMATION_DURATION = 300

// 布尔。
export enum EBool {
  FALSE = 0,
  TRUE = 1,
}

// 存储大小单位。
export enum EStorageSizeUnit {
  b = 1,
  B = 8,
  KB = 1024,
  MB = 1024 * 1024,
}

// 文件上传大小限制。
export enum EFileUploadSizeLimit {
  IMAGE = 20 * EStorageSizeUnit.MB,
  VIDEO = 500 * EStorageSizeUnit.MB,
  PPT = 200 * EStorageSizeUnit.MB,
}

// 文件上传大小限制文字映射。
export const FILE_UPLOAD_SIZE_LIMIT_TEXT_MAP = {
  [EFileUploadSizeLimit.IMAGE]: `${
    EFileUploadSizeLimit.IMAGE / EStorageSizeUnit.MB
  }MB`,
  [EFileUploadSizeLimit.VIDEO]: `${
    EFileUploadSizeLimit.VIDEO / EStorageSizeUnit.MB
  }MB`,
  [EFileUploadSizeLimit.PPT]: `${
    EFileUploadSizeLimit.PPT / EStorageSizeUnit.MB
  }MB`,
}

// 文件上传大小限制提示映射。
export const FILE_UPLOAD_SIZE_LIMIT_TIP_MAP = {
  [EFileUploadSizeLimit.IMAGE]: `图片大小不能超过${
    FILE_UPLOAD_SIZE_LIMIT_TEXT_MAP[EFileUploadSizeLimit.IMAGE]
  }`,
  [EFileUploadSizeLimit.VIDEO]: `视频大小不能超过${
    FILE_UPLOAD_SIZE_LIMIT_TEXT_MAP[EFileUploadSizeLimit.VIDEO]
  }`,
  [EFileUploadSizeLimit.PPT]: `PPT大小不能超过${
    FILE_UPLOAD_SIZE_LIMIT_TEXT_MAP[EFileUploadSizeLimit.PPT]
  }`,
}

// 未命名默认值。
export const UNTITLED = '未命名'

// 分页数据。
export const DEFAULT_PAGER_SIZE = 10

// 默认分页数列表。
export const DEFAULT_PAGER_SIZE_OPTIONS = ['10', '20', '50', '100']

// 分页数据的排序。
export enum ESortType {
  // 升序。
  ASC = 'asc',

  // 降序。
  DESC = 'desc',
}

// 默认全部文字。
export const ALL_TEXT = '全部'

// 跟后端的约定，如果需要查询所有数据的类型是字符串，传空字符串。
export const STR_ALL = new Array(2)
  .fill(undefined)
  .map(() => Math.random().toString(36).substring(2))
  .join('.')

// 跟后端的约定，如果需要查询所有数据的类型是数字，传空字符串。
export const NUM_ALL = Math.floor(Math.random() * 1000000 * 1000000)

// 轮询的结果。
export const enum EPollStatus {
  TIMEOUT = 'timeout',
  ERROR = 'error',
  SUCCESS = 'success',
}

// 缓存时间。
export const enum ECacheTime {
  SESSION = 'session',
  MEMORY = 'memory',
  NONE = 0,
  SEC = 1000,
  MINUTE = 1000 * 60,
  HOUR = 1000 * 60 * 60,
  DAY = 1000 * 60 * 60 * 24,
  WEEK = 1000 * 60 * 60 * 24 * 7,
  MONTH = 1000 * 60 * 60 * 24 * 30,
  QUARTER = 1000 * 60 * 60 * 24 * 30 * 4,
  YEAR = 1000 * 60 * 60 * 24 * 30 * 4 * 3,
  PERMANENT = 1000 * 60 * 60 * 24 * 30 * 4 * 3 * 1000,
}

// 上传时的场景枚举。
export enum EUploadScene {
  VIDEO_PPT = 'video/ppt',
  VIDEO_MATERIAL = 'video/material',
  TEMPLATE_COVER = 'template/cover',
  VIDEO_TRANSFER = 'video/transfer',
  TEMPLATE_MATERIAL = 'template/material',
  VIDEO_ASS = 'video',
  SCHOOL_LOGO = 'school',
  COURSE_IMAGE = 'course',
  STUDENT_AVATAR = 'school',
  USER_PUBLIC_VIDEO = 'course',
}
