import localforage from 'localforage'

export const commonStorage = localforage.createInstance({
  name: 'commonStorage',
})

export const fileStorage = localforage.createInstance({
  name: 'fileStorage',
})

export const voiceStorage = localforage.createInstance({
  name: 'voiceStorage',
})

// 问答聊天记录
export const messageStorage = localforage.createInstance({
  name: 'messageStorage',
})
