import { AxiosRequestConfig } from 'axios'

import { ISchool } from '@/types/school'

import { baseRequest } from '.'

// 获取学校信息
export const getSchoolBaseInfo = (
  params: { cname: string },
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.get<ISchool.Detail>(`/system/web-info/${params.cname}`, {
    ...config,
    needAuth: false,
  })
}

type IUpdateUserInfoData = {
  id: number
  name: string
  phone: string
  password?: string
  confirm_password?: string
  school_id: number
}

// 修改用户信息（系统用户-管理员）
export const updateUserInfo = (
  data: IUpdateUserInfoData,
  config: Partial<AxiosRequestConfig> = {},
) => {
  return baseRequest.put<void>(`/system/user/${data.id}`, {
    transformRequest: (data: IUpdateUserInfoData) => {
      if (!data.password || !data.confirm_password) {
        delete data.password
        delete data.confirm_password
      }

      return JSON.stringify(data)
    },
    data,
    ...config,
  })
}
