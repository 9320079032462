import { isNil } from 'lodash-es'

import { EGraphicEvent, EGraphicType } from '../config'
import { IKonvaVideoConfig, KonvaVideo } from '../konva/Video'
import { roundTo1 } from '../util'
import { GraphicMedia, IGraphicMediaConfig } from './Media'

export type IGraphicVideoConfig = IGraphicMediaConfig & IKonvaVideoConfig

export class GraphicVideo extends GraphicMedia<KonvaVideo> {
  graphicType = EGraphicType.VIDEO

  constructor(graphicVideoConfig: IGraphicVideoConfig) {
    super({
      ...graphicVideoConfig,
      graphic: new KonvaVideo(graphicVideoConfig),
    })

    // 处理缩放。
    this.on(EGraphicEvent.RESIZE, () => {
      this.graphic.setAttrs(this.box.attrs)
    })

    // 处理拖拽。
    this.on(EGraphicEvent.DRAG_MOVE, () => {
      this.graphic.setAttrs(this.box.attrs)
    })

    this.graphic.on(EGraphicEvent.PLAYING, () => {
      this.fire(EGraphicEvent.PLAYING)
    })

    this.graphic.on(EGraphicEvent.WAITING, () => {
      this.fire(EGraphicEvent.WAITING)
    })

    this.graphic.on(EGraphicEvent.PLAY, () => {
      this.fire(EGraphicEvent.PLAY)
    })

    this.graphic.on(EGraphicEvent.PAUSE, () => {
      this.fire(EGraphicEvent.PAUSE)
    })

    this.graphic.on(EGraphicEvent.CAN_PLAY, () => {
      this.fire(EGraphicEvent.CAN_PLAY)
    })

    this.graphic.on(EGraphicEvent.SEEKING, () => {
      this.fire(EGraphicEvent.SEEKING)
    })

    this.graphic.on(EGraphicEvent.PROGRESS, () => {
      this.fire(EGraphicEvent.PROGRESS)
    })

    this.graphic.on(EGraphicEvent.ENDED, () => {
      this.fire(EGraphicEvent.ENDED)
    })
  }

  // 播放。
  play(...params: Parameters<KonvaVideo['play']>) {
    return this.graphic.play(...params)
  }

  // 暂停。
  pause(...params: Parameters<KonvaVideo['pause']>) {
    return this.graphic.pause(...params)
  }

  // 停止。
  stop(...params: Parameters<KonvaVideo['stop']>) {
    return this.graphic.stop(...params)
  }

  // 定位。
  seek(...params: Parameters<KonvaVideo['seek']>) {
    return this.graphic.seek(...params)
  }

  // 视频时长。
  get duration() {
    return this.graphic?.duration ?? 0
  }

  // 是否已经暂停。
  get paused() {
    return this.graphic?.paused ?? true
  }

  // 是否正在播放。
  get playing() {
    return !this.paused
  }

  // 当前播放时间。
  get currentTime() {
    return this.graphic?.currentTime ?? 0
  }

  // 重写父类的 x 方法。
  x(x?: number) {
    if (isNil(x)) {
      return super.x()
    }

    x = super.x(x)

    this.graphic.x(this.box.x())

    return x
  }

  // 重写父类的 y 方法。
  y(y?: number) {
    if (isNil(y)) {
      return super.y()
    }

    y = super.y(y)

    this.graphic.y(this.box.y())

    return y
  }

  // 重写父类的 height 方法。
  width(width?: number) {
    if (isNil(width)) {
      return super.width()
    }

    const { ratio } = this.graphic
    if (isNil(ratio)) {
      super.width(width)
    } else {
      super.width(width)
      this.graphic.width(this.box.width())
      const newH = roundTo1(width / ratio)
      super.height(newH)
      this.graphic.height(this.box.height())
    }

    return width
  }

  // 重写父类的 height 方法。
  height(height?: number) {
    if (isNil(height)) {
      return super.height()
    }

    const { ratio } = this.graphic
    if (isNil(ratio)) {
      super.height(height)
    } else {
      super.height(height)
      this.graphic.height(this.box.height())
      const newW = roundTo1(height * ratio)
      super.width(newW)
      this.graphic.width(this.box.width())
    }

    return height
  }
}
