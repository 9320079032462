import {
  ECacheTime,
  EMaterialBusinessType,
  EMaterialFileType,
  EShapeType,
  TEXT_TYPE_COLOR_MAP,
} from '@lanyan/constant'
import { useUnrepeatable } from '@lanyan/hook/src/useUnrepeatable'
import { IVideo, Optional } from '@lanyan/type'
import { proxy, transfer } from 'comlink'
import {
  differenceBy,
  groupBy,
  intersectionBy,
  isEqual,
  last,
  omit,
  partialRight,
} from 'lodash-es'
import { v4 } from 'uuid'

import { createVideo, on, playVideo } from '../dom'
import {
  EAlign,
  EFillPriority,
  EGraphicEvent,
  EVerticalAlign,
} from '../GraphicEditor/config'
import { TEXT_ATTRS } from '../GraphicEditor/graphics/Text'
import { IGraphicVideoConfig } from '../GraphicEditor/graphics/Video'
import { flatTextList, TextListItem } from '../GraphicEditor/konva/util'
import {
  removeUndefinedProp,
  renameObjKey,
  toFixed,
  usePromise,
} from '../helper'
import {
  checkTexFont,
  EFontLoadMode,
  loadTexFont,
} from '../MaterialEditor/font'
import { onRequestAnimationFrame } from '../webAPI'
import { EApplication } from './config'
import { MaterialEditor } from './MaterialEditor'
import { IMaterial } from './type'
import {
  isActorMaterial,
  isArrowMaterial,
  isEllipseMaterial,
  isImageBackgroundMaterial,
  isImageMaterial,
  isLineMaterial,
  isLineShapeMaterial,
  isMediaBackgroundMaterial,
  isPureBackgroundMaterial,
  isRectMaterial,
  isRegularPolygonMaterial,
  isTextMaterial,
  isVideoBackgroundMaterial,
  isVideoMaterial,
} from './util'

// 属性反序列化映射，指将前端数据结构转化成后端数据结构。
const MATERIAL_ATTRS_DESERIALIZE_MAP = {
  // 截取属性。
  CROP: {
    cropX: 'crop_x',
    cropY: 'crop_y',
    cropWidth: 'crop_width',
    cropHeight: 'crop_height',
    imageWidth: 'image_width',
    imageHeight: 'image_height',
    imageX: 'image_x',
    imageY: 'image_y',
  },

  RADIUS: {
    cornerRadius: 'corner_radius',
  },
  OUTLINE: {
    strokeWidth: 'stroke_width',
    strokePriority: 'stroke_priority',
    strokeLinearGradientColorStops: 'stroke_linear_gradient_color_stops',
    strokeLinearGradientDegree: 'stroke_linear_gradient_degree',
  },
  LAYER: {
    blurRadius: 'blur_radius',
  },
  FILL: {
    fillPriority: 'fill_priority',
    linearGradientColorStops: 'linear_gradient_color_stops',
    linearGradientDegree: 'linear_gradient_degree',
  },
  ARROW: {
    pointerAtBeginning: 'pointer_at_beginning',
    pointerAtEnding: 'pointer_at_ending',
  },
  COMMON: {
    outlineX: 'outline_x',
    outlineY: 'outline_y',
    outlineWidth: 'outline_width',
    outlineHeight: 'outline_height',
  },
} as const

// 属性序列化映射，指后端数据结构转化成前端数据结构。
const MATERIAL_ATTRS_SERIALIZE_MAP = {
  // 截取属性。
  CROP: {
    crop_x: 'cropX',
    crop_y: 'cropY',
    crop_width: 'cropWidth',
    crop_height: 'cropHeight',
    image_width: 'imageWidth',
    image_height: 'imageHeight',
    image_x: 'imageX',
    image_y: 'imageY',
  },
  RADIUS: {
    corner_radius: 'cornerRadius',
  },
  OUTLINE: {
    stroke_width: 'strokeWidth',
    stroke_priority: 'strokePriority',
    stroke_linear_gradient_color_stops: 'strokeLinearGradientColorStops',
    stroke_linear_gradient_degree: 'strokeLinearGradientDegree',
  },
  LAYER: {
    blur_radius: 'blurRadius',
  },
  FILL: {
    fill_priority: 'fillPriority',
    linear_gradient_color_stops: 'linearGradientColorStops',
    linear_gradient_degree: 'linearGradientDegree',
  },
  ARROW: {
    pointer_at_beginning: 'pointerAtBeginning',
    pointer_at_ending: 'pointerAtEnding',
  },
} as const

// 序列化通用属性。
export const serializeCommonAttrs = <
  T extends {
    linearGradientColorStops?: IVideo.MaterialAttrs['linear_gradient_color_stops']
    strokeLinearGradientColorStops?: IVideo.MaterialAttrs['stroke_linear_gradient_color_stops']
    fillPriority?: IVideo.MaterialAttrs['fill_priority']
    strokePriority?: IVideo.MaterialAttrs['stroke_priority']
  },
>(
  commonAttrs: T,
) => {
  const serializedCommonAttrs: Omit<
    T,
    | 'linearGradientColorStops'
    | 'fillPriority'
    | 'strokeLinearGradientColorStops'
    | 'strokePriority'
  > & {
    linearGradientColorStops?: [number, string][]
    fillPriority?: `${EFillPriority}`
    strokeLinearGradientColorStops?: [number, string][]
    strokePriority?: `${EFillPriority}`
  } = omit(commonAttrs, [
    'linearGradientColorStops',
    'fillPriority',
    'strokePriority',
    'strokeLinearGradientColorStops',
  ])

  if (commonAttrs.linearGradientColorStops) {
    serializedCommonAttrs.linearGradientColorStops =
      commonAttrs.linearGradientColorStops.map((item) => {
        const num = +item[0]

        return [num, item[1]]
      })
  }

  if (commonAttrs.strokeLinearGradientColorStops) {
    serializedCommonAttrs.strokeLinearGradientColorStops =
      commonAttrs.strokeLinearGradientColorStops.map((item) => {
        const num = +item[0]

        return [num, item[1]]
      })
  }

  if (commonAttrs.fillPriority) {
    serializedCommonAttrs.fillPriority =
      commonAttrs.fillPriority as `${EFillPriority}`
  }

  if (commonAttrs.strokePriority) {
    serializedCommonAttrs.strokePriority =
      commonAttrs.strokePriority as `${EFillPriority}`
  }

  return serializedCommonAttrs
}

// 序列化文字属性。
export const serializeTextAttrs = (textAttrs: {
  font_size?: IVideo.MaterialAttrs['font_size']
  font_family?: IVideo.MaterialAttrs['font_family']
  text_decoration?: IVideo.MaterialAttrs['text_decoration']
  letter_spacing?: IVideo.MaterialAttrs['letter_spacing']
  line_height?: IVideo.MaterialAttrs['line_height']
  fill?: IVideo.MaterialAttrs['fill']
  font_style?: IVideo.MaterialAttrs['font_style']
  font_weight?: IVideo.MaterialAttrs['font_weight']
  linear_gradient_color_stops?: IVideo.MaterialAttrs['linear_gradient_color_stops']
  linear_gradient_degree?: IVideo.MaterialAttrs['linear_gradient_degree']
  fill_priority?: IVideo.MaterialAttrs['fill_priority']
}) => {
  const serializedTextAttrs: {
    fontSize?: IVideo.MaterialAttrs['font_size']
    fontFamily?: IVideo.MaterialAttrs['font_family']
    textDecoration?: IVideo.MaterialAttrs['text_decoration']
    letterSpacing?: IVideo.MaterialAttrs['letter_spacing']
    lineHeight?: IVideo.MaterialAttrs['line_height']
    fill?: IVideo.MaterialAttrs['fill']
    fontStyle?: IVideo.MaterialAttrs['font_style']
    linearGradientColorStops?: IVideo.MaterialAttrs['linear_gradient_color_stops']
    linearGradientDegree?: IVideo.MaterialAttrs['linear_gradient_degree']
    fillPriority?: IVideo.MaterialAttrs['fill_priority']
  } = {
    ...renameObjKey(textAttrs, {
      font_size: 'fontSize',
      font_family: 'fontFamily',
      text_decoration: 'textDecoration',
      letter_spacing: 'letterSpacing',
      line_height: 'lineHeight',
      linear_gradient_color_stops: 'linearGradientColorStops',
      linear_gradient_degree: 'linearGradientDegree',
      fill_priority: 'fillPriority',
    }),
  }

  if (textAttrs.font_style || textAttrs.font_weight) {
    serializedTextAttrs.fontStyle = [
      textAttrs.font_weight || 'normal',
      textAttrs.font_style || 'normal',
    ].join(' ')
  }

  serializedTextAttrs.letterSpacing ??= 0
  serializedTextAttrs.textDecoration ??= 'none'
  serializedTextAttrs.linearGradientColorStops ??= []
  serializedTextAttrs.linearGradientDegree ??= 0

  // 之前字间距存储的是具体的 px 值，但后面改成比例了，如果字间距大于 1 则判定为 px 值，转成比例
  if (serializedTextAttrs.letterSpacing! > 1) {
    serializedTextAttrs.letterSpacing = toFixed(
      serializedTextAttrs.letterSpacing! / serializedTextAttrs.fontSize!,
      2,
    )
  }

  return serializedTextAttrs
}

// 序列化素材，将后端数据结构转化成前端数据结构。
export const serializeMaterial = (material: IVideo.Material) => {
  const serializedMaterial: Record<string, any> = {
    id: material.id,
    groupId: material.group_id,
    itemGroup: material.item_group,
    replaceable: material.replaceable,
    templateMaterialId: material.template_material_id,
    lock: !!material.lock,
    materialId: material.material_id,
  }

  switch (material.type) {
    // 添加数字人。
    case EMaterialBusinessType.ACTOR: {
      return {
        ...serializedMaterial,
        url: material.url!,
        ...renameObjKey(material.attrs, {}),
      }
    }

    // 添加图片素材。
    case EMaterialBusinessType.IMAGE: {
      return {
        ...serializedMaterial,
        url: material.url!,
        ...renameObjKey(material.attrs, {
          ...MATERIAL_ATTRS_SERIALIZE_MAP.RADIUS,
          ...MATERIAL_ATTRS_SERIALIZE_MAP.CROP,
          ...MATERIAL_ATTRS_SERIALIZE_MAP.LAYER,
        }),
      }
    }

    // 添加视频。
    case EMaterialBusinessType.VIDEO: {
      return {
        ...serializedMaterial,
        url: material.url!,
        ...material.attrs,
      }
    }

    case EMaterialBusinessType.BACKGROUND: {
      // 添加纯色背景。
      if (material.file_type === EMaterialFileType.COLOR_IMAGE) {
        return {
          ...serializedMaterial,
          ...material.attrs,
        }
      }

      return {
        ...serializedMaterial,
        url: material.url!,
        ...material.attrs,
      }
    }

    case EMaterialBusinessType.TEXT: {
      const { attrs } = material

      const text = {
        ...serializedMaterial,
        x: attrs.x,
        y: attrs.y,
        width: attrs.width,
        height: attrs.height,
        align: attrs.text_align,
        verticalAlign: attrs.vertical_align,
        opacity: attrs.opacity,
        textList: flatTextList(
          (
            material.text_list ??
            ([{ text: material.text }] as NonNullable<
              IVideo.Material['text_list']
            >)
          ).map((text) => {
            return {
              text: text.text,
              ...serializeCommonAttrs(
                serializeTextAttrs({
                  fill: text.fill ?? attrs.fill,
                  font_style: text.font_style ?? attrs.font_style,
                  font_weight: text.font_weight ?? attrs.font_weight,
                  font_family: text.font_family ?? attrs.font_family,
                  text_decoration:
                    text.text_decoration ?? attrs.text_decoration,
                  font_size: text.font_size ?? attrs.font_size,
                  line_height: text.line_height ?? attrs.line_height,
                  letter_spacing: text.letter_spacing ?? attrs.letter_spacing,
                  linear_gradient_color_stops:
                    text.linear_gradient_color_stops ??
                    attrs.linear_gradient_color_stops,
                  linear_gradient_degree:
                    text.linear_gradient_degree ?? attrs.linear_gradient_degree,
                  fill_priority: text.fill_priority ?? attrs.fill_priority,
                }),
              ),
            } as TextListItem
          }),
        ),
        textType: material.text_type,
      }

      return text
    }

    // 添加图形。
    case EMaterialBusinessType.SHAPE: {
      // 添加矩形。
      switch (material.shape_type) {
        case EShapeType.RECTANGLE: {
          return {
            ...serializedMaterial,
            ...serializeCommonAttrs(
              renameObjKey(material.attrs, {
                ...MATERIAL_ATTRS_SERIALIZE_MAP.OUTLINE,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.RADIUS,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.FILL,
              }),
            ),
          }
        }

        case EShapeType.REGULAR_POLYGON: {
          return {
            ...serializedMaterial,
            ...serializeCommonAttrs(
              renameObjKey(material.attrs, {
                ...MATERIAL_ATTRS_SERIALIZE_MAP.OUTLINE,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.FILL,
              }),
            ),
          }
        }

        case EShapeType.CIRCLE: {
          return {
            ...serializedMaterial,
            ...serializeCommonAttrs(
              renameObjKey(material.attrs, {
                ...MATERIAL_ATTRS_SERIALIZE_MAP.OUTLINE,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.FILL,
              }),
            ),
          }
        }

        case EShapeType.LINE: {
          return {
            ...serializedMaterial,
            ...serializeCommonAttrs(
              renameObjKey(material.attrs, {
                ...MATERIAL_ATTRS_SERIALIZE_MAP.OUTLINE,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.FILL,
              }),
            ),
          }
        }

        case EShapeType.ARROW: {
          return {
            ...serializedMaterial,
            ...serializeCommonAttrs(
              renameObjKey(material.attrs, {
                ...MATERIAL_ATTRS_SERIALIZE_MAP.OUTLINE,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.ARROW,
                ...MATERIAL_ATTRS_SERIALIZE_MAP.FILL,
              }),
            ),
          }
        }

        default: {
          break
        }
      }
    }

    default: {
      break
    }
  }
}

// 反序列化通用属性。
const deserializeCommonAttrs = <
  T extends {
    linear_gradient_color_stops?: [number, string][]
    fill_priority?: `${EFillPriority}`
    stroke_linear_gradient_color_stops?: [number, string][]
    stroke_priority?: `${EFillPriority}`
  },
>(
  commonAttrs: T,
) => {
  const deserializedCommonAttrs: Omit<
    T,
    | 'linear_gradient_color_stops'
    | 'fill_priority'
    | 'stroke_linear_gradient_color_stops'
    | 'stroke_priority'
  > & {
    linear_gradient_color_stops?: IVideo.MaterialAttrs['linear_gradient_color_stops']
    fill_priority?: IVideo.MaterialAttrs['fill_priority']
    stroke_linear_gradient_color_stops?: IVideo.MaterialAttrs['stroke_linear_gradient_color_stops']
    stroke_priority?: IVideo.MaterialAttrs['stroke_priority']
  } = omit(commonAttrs, [
    'linear_gradient_color_stops',
    'fill_priority',
    'stroke_priority',
    'stroke_linear_gradient_color_stops',
  ])
  if (commonAttrs.linear_gradient_color_stops) {
    deserializedCommonAttrs.linear_gradient_color_stops =
      commonAttrs.linear_gradient_color_stops.map((item) => {
        const str = `${item[0]}`

        return [str, item[1]]
      })
  }

  if (commonAttrs.stroke_linear_gradient_color_stops) {
    deserializedCommonAttrs.stroke_linear_gradient_color_stops =
      commonAttrs.stroke_linear_gradient_color_stops.map((item) => {
        const str = `${item[0]}`

        return [str, item[1]]
      })
  }

  if (commonAttrs.fill_priority) {
    deserializedCommonAttrs.fill_priority =
      commonAttrs.fill_priority as IVideo.MaterialAttrs['fill_priority']
  }

  if (commonAttrs.stroke_priority) {
    deserializedCommonAttrs.stroke_priority =
      commonAttrs.stroke_priority as IVideo.MaterialAttrs['stroke_priority']
  }

  return deserializedCommonAttrs
}

// 反序列化文字属性。
const deserializeTextAttrs = (textAttrs: {
  fontSize?: number
  fontFamily?: string
  textDecoration?: string
  letterSpacing?: number
  lineHeight?: number
  fill?: string
  fontStyle?: string
  linearGradientColorStops?: [number, string][]
  linearGradientDegree?: number
  fillPriority?: `${EFillPriority}`
}) => {
  const deserializedTextAttrs: {
    fill?: string
    font_family?: string
    line_height?: number
    font_size?: number
    letter_spacing?: number
    text_decoration?: IVideo.MaterialAttrs['text_decoration']
    font_weight?: string
    font_style?: string
    linear_gradient_color_stops?: [number, string][]
    linear_gradient_degree?: number
    fill_priority?: `${EFillPriority}`
  } = {
    fill: textAttrs.fill,
    font_family: textAttrs.fontFamily,
    line_height: textAttrs.lineHeight,
    font_size: textAttrs.fontSize,
    letter_spacing: textAttrs.letterSpacing,
    linear_gradient_color_stops: textAttrs.linearGradientColorStops,
    linear_gradient_degree: textAttrs.linearGradientDegree,
    fill_priority: textAttrs.fillPriority,
    text_decoration:
      textAttrs.textDecoration as IVideo.MaterialAttrs['text_decoration'],
  }

  if (textAttrs.fontStyle) {
    deserializedTextAttrs.font_weight = textAttrs.fontStyle.includes('bold')
      ? 'bold'
      : 'normal'
    deserializedTextAttrs.font_style = textAttrs.fontStyle.includes('italic')
      ? 'italic'
      : 'normal'
  } else {
    deserializedTextAttrs.font_weight = undefined
    deserializedTextAttrs.font_style = undefined
  }

  return deserializedTextAttrs
}

// 反序列化素材，将前端数据结构转化成后端数据结构。
export const deserializeMaterial = (material: IMaterial) => {
  const deserializedMaterial = {
    id: material.id,
    item_group: material.itemGroup,
    replaceable: material.replaceable,
    template_material_id: material.templateMaterialId,
    lock: +material.lock,
    group_id: material.groupId,
  }

  switch (true) {
    case isActorMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
      })

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.ACTOR,
        file_type: EMaterialFileType.IMAGE,
        material_id: material.materialId,
        url: attrs.url,
        attrs,
      } as IVideo.Material

      break
    }

    case isPureBackgroundMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
      })

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.BACKGROUND,
        file_type: EMaterialFileType.COLOR_IMAGE,
        attrs,
      } as IVideo.Material
    }

    case isImageBackgroundMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
      })

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.BACKGROUND,
        file_type: EMaterialFileType.IMAGE,
        url: attrs.url,
        attrs,
      } as IVideo.Material
    }

    case isVideoBackgroundMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
      })

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.BACKGROUND,
        file_type: EMaterialFileType.VIDEO,
        url: attrs.url,
        attrs,
      } as IVideo.Material
    }

    case isImageMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.RADIUS,
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.CROP,
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.LAYER,
      })

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.IMAGE,
        file_type: EMaterialFileType.IMAGE,
        url: attrs.url,
        attrs,
      } as IVideo.Material
    }

    case isVideoMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
      })

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.VIDEO,
        file_type: EMaterialFileType.VIDEO,
        url: attrs.url,
        attrs,
      } as IVideo.Material
    }

    case isRectMaterial(material): {
      const attrs = deserializeCommonAttrs(
        renameObjKey(material.attrs, {
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.RADIUS,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.OUTLINE,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.FILL,
        }),
      )

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.SHAPE,
        shape_type: EShapeType.RECTANGLE,
        file_type: EMaterialFileType.DEFAULT,
        attrs,
      } as IVideo.Material
    }

    case isRegularPolygonMaterial(material): {
      const attrs = deserializeCommonAttrs(
        renameObjKey(material.attrs, {
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.OUTLINE,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.FILL,
        }),
      )

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.SHAPE,
        shape_type: EShapeType.REGULAR_POLYGON,
        file_type: EMaterialFileType.DEFAULT,
        attrs,
      } as IVideo.Material
    }

    case isEllipseMaterial(material): {
      const attrs = deserializeCommonAttrs(
        renameObjKey(material.attrs, {
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.OUTLINE,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.FILL,
        }),
      )

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.SHAPE,
        shape_type: EShapeType.CIRCLE,
        file_type: EMaterialFileType.DEFAULT,
        attrs,
      } as IVideo.Material
    }

    case isLineMaterial(material): {
      const attrs = deserializeCommonAttrs(
        renameObjKey(material.attrs, {
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.OUTLINE,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.FILL,
        }),
      )

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.SHAPE,
        shape_type: EShapeType.LINE,
        file_type: EMaterialFileType.DEFAULT,
        attrs,
      } as IVideo.Material
    }

    case isArrowMaterial(material): {
      const attrs = deserializeCommonAttrs(
        renameObjKey(material.attrs, {
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.OUTLINE,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.ARROW,
          ...MATERIAL_ATTRS_DESERIALIZE_MAP.FILL,
        }),
      )

      return {
        ...deserializedMaterial,
        type: EMaterialBusinessType.SHAPE,
        shape_type: EShapeType.ARROW,
        file_type: EMaterialFileType.DEFAULT,
        attrs,
      } as IVideo.Material
    }

    case isTextMaterial(material): {
      const attrs = renameObjKey(material.attrs, {
        ...MATERIAL_ATTRS_DESERIALIZE_MAP.COMMON,
      })

      const text = {
        ...deserializedMaterial,
        type: EMaterialBusinessType.TEXT,
        file_type: EMaterialFileType.DEFAULT,
        text: attrs.text,
        text_type: material.textType,
        attrs: {
          x: attrs.x,
          y: attrs.y,
          width: attrs.width,
          height: attrs.height,
          text_align: attrs.align,
          vertical_align: attrs.verticalAlign,
          opacity: attrs.opacity,
          outline_x: attrs.outline_x,
          outline_y: attrs.outline_y,
          outline_width: attrs.outline_width,
          outline_height: attrs.outline_height,
        },
      } as IVideo.Material

      const globalTextStyle: {
        fontSize?: number
        fontFamily?: string
        textDecoration?: string
        letterSpacing?: number
        lineHeight?: number
        fill?: string
        fontStyle?: string
        linearGradientColorStops?: [number, string][]
        linearGradientDegree?: number
        fillPriority?: `${EFillPriority}`
      } = {}

      // 为了减少 json 大小，将使用到最多的样式提取到外层。
      TEXT_ATTRS.forEach((attr) => {
        const groupedAttrValue = groupBy(attrs.textList, attr)

        // 找出属性值最多的属性。
        const maxUseAttrTextList = Object.values(groupedAttrValue).sort(
          (a, b) => b.length - a.length,
        )[0]
        if (maxUseAttrTextList) {
          const attrValue = maxUseAttrTextList[0][attr]
          globalTextStyle[attr] = attrValue as any

          maxUseAttrTextList.forEach((t) => {
            delete t[attr]
          })
        }
      })
      Object.assign(
        text.attrs,
        deserializeCommonAttrs(deserializeTextAttrs(globalTextStyle)),
      )

      // 格式化文字列表。
      const _textList = attrs.textList.map((t) => {
        return {
          text: t.text,
          ...deserializeCommonAttrs(
            removeUndefinedProp(deserializeTextAttrs(t)),
          ),
        }
      })

      // 合并相邻的相同样式的文字。
      const textList: IVideo.Material['text_list'] = []
      for (let i = 0; i < _textList.length; i++) {
        const text = _textList[i]
        const lastText = last(textList)
        if (lastText && isEqual(omit(lastText, 'text'), omit(text, 'text'))) {
          lastText.text += text.text
        } else {
          textList.push(text)
        }
      }

      text.text_list = textList

      return text
    }

    default: {
      break
    }
  }
}

// 绘制场景。
export const drawScene = (
  editor: MaterialEditor,
  scene: IVideo.Scene,
  {
    fontLoadMode,
    application,
    ossDomain,
  }: {
    ossDomain: string
    application?: `${EApplication}`
    fontLoadMode?: `${EFontLoadMode}`
  },
) => {
  scene.video.materials.forEach((material) => {
    addMaterial(editor, material, {
      ossDomain,
      application,
      fontLoadMode,
    })
  })
}

// 添加素材。
export const addMaterial = (
  materialEditor: MaterialEditor,
  material: IVideo.Material,
  {
    application,
    ossDomain,
    fontLoadMode,
  }: {
    application?: `${EApplication}`
    ossDomain: string
    fontLoadMode?: `${EFontLoadMode}`
  },
) => {
  switch (material.type) {
    case EMaterialBusinessType.ACTOR: {
      // 添加数字人。
      return materialEditor.addActor({
        ...(serializeMaterial(material) as any),
      })
    }

    // 添加媒体。
    case EMaterialBusinessType.IMAGE:

    case EMaterialBusinessType.VIDEO: {
      return materialEditor.addMedia({
        ...serializeMaterial(material),
      })

      break
    }

    // 背景。
    case EMaterialBusinessType.BACKGROUND: {
      // 添加纯色背景。
      switch (material.file_type) {
        case EMaterialFileType.COLOR_IMAGE: {
          return materialEditor.addPureBackground(
            serializeMaterial(material) as any,
          )

          break
        }

        // 添加媒体背景。
        case EMaterialFileType.IMAGE:

        case EMaterialFileType.VIDEO: {
          return materialEditor.addMediaBackground(
            serializeMaterial(material) as any,
          )

          break
        }

        default: {
          break
        }
      }

      break
    }

    // 添加文字。
    case EMaterialBusinessType.TEXT: {
      return materialEditor.addText({
        ...serializeMaterial(material),
        showBorder: application === EApplication.COURSE,
        borderColor: TEXT_TYPE_COLOR_MAP[material.text_type!],
        checkFont: checkTexFont,
        loadFont: partialRight(loadTexFont, { ossDomain, fontLoadMode }),
      } as any)

      break
    }

    // 添加矩形。
    case EMaterialBusinessType.SHAPE: {
      switch (material.shape_type) {
        case EShapeType.RECTANGLE: {
          return materialEditor.addRect({
            ...serializeMaterial(material),
          })

          break
        }

        case EShapeType.REGULAR_POLYGON: {
          return materialEditor.addRegularPolygon({
            ...serializeMaterial(material),
          } as any)

          break
        }

        case EShapeType.CIRCLE: {
          return materialEditor.addEllipse({
            ...serializeMaterial(material),
          })

          break
        }

        case EShapeType.LINE: {
          return materialEditor.addLine({
            ...serializeMaterial(material),
          })

          break
        }

        case EShapeType.ARROW: {
          return materialEditor.addArrow({
            ...(serializeMaterial(material) as any),
          })

          break
        }

        default: {
          break
        }
      }

      break
    }

    default: {
      break
    }
  }
}

// 视频地址和视频元素的映射。
const VIDEO_URL_ELEMENT_MAP: Record<string, HTMLVideoElement> = {}

// 创建视频元素，不会重复创建。
const [noRepeatCreateVideo] = useUnrepeatable(createVideo, {
  cacheId: 'createVideo',
  cacheTime: ECacheTime.MEMORY,
})

// 包装 ImageBitmap
const wrapImageBitmap = <T extends Optional<ImageBitmap>>(
  imageBitmap: T,
  inWorker: boolean,
) => {
  if (inWorker && imageBitmap) {
    return transfer({ imageBitmap }, [imageBitmap])
  }

  return { imageBitmap }
}

// 获取视频帧位图。
const getImageBitmap = async ($video: HTMLVideoElement) => {
  let imageBitmap: Optional<ImageBitmap>
  try {
    imageBitmap = await createImageBitmap($video)
  } catch (err) {
    // console.log(err)
  }

  return imageBitmap
}

// 视频第一帧是否已经加载，不会重复检查。
const [noRepeatCheckFirstFrameIsLoaded] = useUnrepeatable(
  (url: string) => {
    const $video = VIDEO_URL_ELEMENT_MAP[url]
    const { promise, resolve } = usePromise<boolean>()
    const [startCaptureFirstFrame, stopCaptureFirstFrame] =
      onRequestAnimationFrame(async () => {
        const imageBitmap = await getImageBitmap($video)
        if (imageBitmap) {
          resolve(true)
          stopCaptureFirstFrame()
        }
      })

    playVideo($video).then((played) => {
      if (played) {
        $video.pause()
        startCaptureFirstFrame()
      } else {
        resolve(false)
      }
    })

    return promise
  },
  {
    cacheId: 'firstFrameIsLoaded',
    cacheTime: ECacheTime.MEMORY,
  },
)

// 当前操作视频元素的操作者 id
let curVideoOperatorId = ''

// 创建视频处理器。
export const createVideoHandler = async (
  inWorker = false,
  ...params: Parameters<IGraphicVideoConfig['createVideoHandler']>
) => {
  const id = v4()
  curVideoOperatorId = id
  const [url, { cbs, needCaptureFirstFrame }] = params

  // 创建视频，并设置基础属性。
  const $video =
    VIDEO_URL_ELEMENT_MAP[url] ?? (await noRepeatCreateVideo({ src: url }))
  VIDEO_URL_ELEMENT_MAP[url] = $video
  if (!$video) {
    return
  }

  $video.width = $video.videoWidth
  $video.height = $video.videoHeight
  $video.muted = true

  // 捕获视频帧。
  const [startCaptureVideoFrame, stopCaptureVideoFrame] =
    onRequestAnimationFrame(async () => {
      const imageBitmap = await getImageBitmap($video)
      if (imageBitmap) {
        cbs.onImageBitmap(wrapImageBitmap(imageBitmap, inWorker))
      }
    })

  // 播放。
  const play = () => {
    curVideoOperatorId = id

    return playVideo($video)
  }

  // 暂停。
  const pause = () => {
    curVideoOperatorId = id
    $video.pause()
  }

  // 定位。
  const seek = (time: number) => {
    curVideoOperatorId = id
    $video.currentTime = time
  }

  // 停止。
  const stop = () => {
    seek(0)
    pause()
  }

  // 当前播放时间。
  const getCurrentTime = () => {
    return $video.currentTime ?? 0
  }

  // 视频时长。
  const getDuration = () => {
    return $video.duration ?? 0
  }

  // 当前视频是否已经暂停。
  const getPaused = () => {
    return $video.paused ?? true
  }

  // 销毁。
  const destroy = () => {
    // 清除事件监听之前暂停视频并回到初始状态。
    pause()
    seek(0)
    offWaiting()
    offPlay()
    offPlaying()
    offPause()
    offEnded()
    offSeeked()
    offCanPlay()
    offSeeking()
    offProgress()
  }

  // 默认定位到 0。
  seek(0)

  // 处理捕获第一帧。
  let isCapturingFirstFrame = false
  if (needCaptureFirstFrame) {
    isCapturingFirstFrame = true
    noRepeatCheckFirstFrameIsLoaded(url).then(async (isLoaded) => {
      if (isLoaded) {
        const imageBitmap = (await getImageBitmap($video))!
        cbs.onLoadFirstFrame(wrapImageBitmap(imageBitmap, inWorker))
      }

      isCapturingFirstFrame = false
    })
  }

  // 事件监听。
  const offWaiting = on($video, EGraphicEvent.WAITING, () => {
    if (id !== curVideoOperatorId) return

    stopCaptureVideoFrame()
    cbs.onWaiting()
  })
  const offPlay = on($video, EGraphicEvent.PLAY, () => {
    if (id !== curVideoOperatorId) return

    cbs.onPlay()
  })
  const offPlaying = on($video, EGraphicEvent.PLAYING, () => {
    if (id !== curVideoOperatorId) return

    if (!isCapturingFirstFrame) {
      startCaptureVideoFrame()
    }

    cbs.onPlaying()
  })
  const offPause = on($video, EGraphicEvent.PAUSE, () => {
    if (id !== curVideoOperatorId) return

    stopCaptureVideoFrame()
    cbs.onPause()
  })
  const offEnded = on($video, EGraphicEvent.ENDED, () => {
    if (id !== curVideoOperatorId) return

    stopCaptureVideoFrame()
    cbs.onEnded()
  })
  const offSeeked = on($video, EGraphicEvent.SEEKED, () => {
    if (id !== curVideoOperatorId) return

    cbs.onSeeked()
  })
  const offCanPlay = on($video, EGraphicEvent.CAN_PLAY, () => {
    if (id !== curVideoOperatorId) return

    cbs.onCanPlay()
  })
  const offSeeking = on($video, EGraphicEvent.SEEKING, () => {
    if (id !== curVideoOperatorId) return

    cbs.onSeeking()
  })
  const offProgress = on($video, EGraphicEvent.PROGRESS, () => {
    if (id !== curVideoOperatorId) return

    cbs.onProgress()
  })

  const returnData = {
    play,
    stop,
    seek,
    getCurrentTime,
    getDuration,
    getPaused,
    pause,
    destroy,
    getImageBitmap: async () => {
      const imageBitmap = await getImageBitmap($video)

      return wrapImageBitmap(imageBitmap, inWorker)
    },
  }

  if (inWorker) {
    return proxy(returnData)
  }

  return returnData
}

// 更新素材。
export const updateMaterial = (
  rawMaterial: IVideo.Material,
  material: IMaterial,
) => {
  const serializedMaterial = serializeMaterial(rawMaterial)! as unknown as {
    id: string
    url?: string
    groupId?: number
    materialId?: number
    width?: number
    height?: number
    x?: number
    fill?: string
    y?: number
    cornerRadius?: number[]
    fontSize?: number
    fontFamily?: string
    textDecoration?: string
    letterSpacing?: number
    lineHeight?: number
    align?: `${EAlign}`
    verticalAlign?: `${EVerticalAlign}`
    fontStyle?: string
    textType?: number
    opacity?: number
    text?: string
    textList?: TextListItem[]
    cropX?: number
    cropY?: number
    cropWidth?: number
    cropHeight?: number
    imageX?: number
    imageY?: number
    imageWidth?: number
    imageHeight?: number
    stroke?: string
    strokeWidth?: number
    dash?: [number, number]
    blurRadius?: number
    sides?: number
    fillPriority?: `${EFillPriority}`
    linearGradientColorStops?: [number, string][]
    linearGradientDegree?: number
    strokePriority?: `${EFillPriority}`
    strokeLinearGradientColorStops?: [number, string][]
    strokeLinearGradientDegree?: number
    points?: number[]
    pointerAtBeginning?: boolean
    pointerAtEnding?: boolean
  }

  // 修改公共属性。
  material.opacity(serializedMaterial.opacity!)
  material.size({
    width: serializedMaterial.width!,
    height: serializedMaterial.height!,
  })
  material.position({
    x: serializedMaterial.x!,
    y: serializedMaterial.y!,
  })

  switch (true) {
    case isActorMaterial(material): {
      material.url({ url: serializedMaterial.url })

      break
    }

    case isMediaBackgroundMaterial(material): {
      material.url({ url: serializedMaterial.url })

      break
    }

    case isPureBackgroundMaterial(material): {
      material.fill(serializedMaterial.fill)

      break
    }

    case isImageMaterial(material): {
      // 替换图片和截取图片冲突，因为替换图片也会发生截取。
      material.cornerRadius(serializedMaterial.cornerRadius!)
      material.blurRadius(serializedMaterial.blurRadius!)
      if (material.url() !== serializedMaterial.url) {
        material.url({ url: serializedMaterial.url })
      } else {
        if (serializedMaterial.cropWidth && serializedMaterial.cropHeight) {
          material.crop({
            imageX: serializedMaterial.imageX!,
            imageY: serializedMaterial.imageY!,
            imageWidth: serializedMaterial.imageWidth!,
            imageHeight: serializedMaterial.imageHeight!,
            cropX: serializedMaterial.cropX!,
            cropY: serializedMaterial.cropY!,
            cropWidth: serializedMaterial.cropWidth!,
            cropHeight: serializedMaterial.cropHeight!,
          })
        }
      }

      break
    }

    case isVideoMaterial(material): {
      material.url({ url: serializedMaterial.url })

      break
    }

    case isRectMaterial(material): {
      material.cornerRadius(serializedMaterial.cornerRadius!)
      material.fill(serializedMaterial.fill!)
      material.stroke(serializedMaterial.stroke!)
      material.strokeWidth(serializedMaterial.strokeWidth!)
      material.dash(serializedMaterial.dash!)
      material.fillPriority(serializedMaterial.fillPriority!)
      material.linearGradientColorStops(
        serializedMaterial.linearGradientColorStops!,
      )
      material.linearGradientDegree(serializedMaterial.linearGradientDegree!)
      material.strokePriority(serializedMaterial.strokePriority!)
      material.strokeLinearGradientColorStops(
        serializedMaterial.strokeLinearGradientColorStops!,
      )
      material.strokeLinearGradientDegree(
        serializedMaterial.strokeLinearGradientDegree!,
      )

      break
    }

    case isRegularPolygonMaterial(material): {
      material.sides(serializedMaterial.sides!)
      material.fill(serializedMaterial.fill!)
      material.stroke(serializedMaterial.stroke!)
      material.strokeWidth(serializedMaterial.strokeWidth!)
      material.dash(serializedMaterial.dash!)
      material.fillPriority(serializedMaterial.fillPriority!)
      material.linearGradientColorStops(
        serializedMaterial.linearGradientColorStops!,
      )
      material.linearGradientDegree(serializedMaterial.linearGradientDegree!)
      material.strokePriority(serializedMaterial.strokePriority!)
      material.strokeLinearGradientColorStops(
        serializedMaterial.strokeLinearGradientColorStops!,
      )
      material.strokeLinearGradientDegree(
        serializedMaterial.strokeLinearGradientDegree!,
      )

      break
    }

    case isEllipseMaterial(material): {
      material.fill(serializedMaterial.fill!)
      material.stroke(serializedMaterial.stroke!)
      material.strokeWidth(serializedMaterial.strokeWidth!)
      material.dash(serializedMaterial.dash!)
      material.fillPriority(serializedMaterial.fillPriority!)
      material.linearGradientColorStops(
        serializedMaterial.linearGradientColorStops!,
      )
      material.linearGradientDegree(serializedMaterial.linearGradientDegree!)
      material.strokePriority(serializedMaterial.strokePriority!)
      material.strokeLinearGradientColorStops(
        serializedMaterial.strokeLinearGradientColorStops!,
      )
      material.strokeLinearGradientDegree(
        serializedMaterial.strokeLinearGradientDegree!,
      )

      break
    }

    case isLineShapeMaterial(material): {
      material.stroke(serializedMaterial.stroke!)
      material.strokeWidth(serializedMaterial.strokeWidth!)
      material.dash(serializedMaterial.dash!)
      material.strokePriority(serializedMaterial.strokePriority!)
      material.strokeLinearGradientColorStops(
        serializedMaterial.strokeLinearGradientColorStops!,
      )
      material.strokeLinearGradientDegree(
        serializedMaterial.strokeLinearGradientDegree!,
      )
      material.points(serializedMaterial.points!)

      if (isArrowMaterial(material)) {
        material.pointerAtBeginning(serializedMaterial.pointerAtBeginning)
        material.pointerAtEnding(serializedMaterial.pointerAtEnding)
      }

      break
    }

    case isTextMaterial(material): {
      material.textType = serializedMaterial.textType!
      material.setTextList(serializedMaterial.textList!)
      material.align(serializedMaterial.align)
      material.verticalAlign(serializedMaterial.verticalAlign)

      break
    }

    default: {
      break
    }
  }
}

// 对比两个素材列表，找出新增，删除，更新的素材列表。
export const getDiffBetweenMaterials = (
  newMaterials: IVideo.Material[],
  oldMaterials: IVideo.Material[],
) => {
  const addMaterials = differenceBy(newMaterials, oldMaterials, 'id')
  const deleteMaterials = differenceBy(oldMaterials, newMaterials, 'id')
  const updateMaterials = intersectionBy(
    newMaterials,
    oldMaterials,
    'id',
  ).filter(({ id }) => {
    const newMaterial = newMaterials.find((m) => m.id === id)!
    const oldMaterial = oldMaterials.find((m) => m.id === id)!

    return !isEqual(newMaterial, oldMaterial)
  })

  return {
    deleteMaterials,
    addMaterials,
    updateMaterials,
  }
}

// 更新场景。
export const updateScene = (
  materialEditor: MaterialEditor,
  newScene: IVideo.Scene,
  {
    ossDomain,
    fontLoadMode,
  }: {
    ossDomain: string
    fontLoadMode?: `${EFontLoadMode}`
  },
) => {
  // 旧素材列表。
  const oldMaterials = materialEditor.graphics.map((graphic) => {
    return deserializeMaterial(graphic as IMaterial)!
  })

  const { updateMaterials, addMaterials, deleteMaterials } =
    getDiffBetweenMaterials(newScene.video.materials, oldMaterials)

  // 删除素材。
  deleteMaterials.forEach((material) => {
    materialEditor.find(material.id)!.remove()
  })

  // 添加素材。
  addMaterials.forEach((material) => {
    addMaterial(materialEditor, material, { ossDomain, fontLoadMode })
  })

  // 更新素材。
  updateMaterials.forEach((material) => {
    updateMaterial(material, materialEditor.find(material.id)! as IMaterial)
  })

  // 更新素材层级。
  const bgMaterials = newScene.video.materials.filter(
    (material) => material.type === EMaterialBusinessType.BACKGROUND,
  )
  newScene.video.materials.map((material, i) => {
    if (!bgMaterials.includes(material)) {
      materialEditor.find(material.id)?.zIndex(i - bgMaterials.length)
    }
  })
}
