import { IKonvaLineConfig, KonvaLine } from '../konva/Line'
import { GraphicLineShape, IGraphicLineShapeConfig } from './LineShape'

export type IGraphicLineConfig = IGraphicLineShapeConfig & IKonvaLineConfig

export class GraphicLine extends GraphicLineShape<KonvaLine> {
  constructor(graphicLineConfig: IGraphicLineConfig) {
    super({
      ...graphicLineConfig,
      graphic: new KonvaLine({
        ...graphicLineConfig,
      }),
    })
  }
}
