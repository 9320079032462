import '@/styles/index.less'

import { createApp } from 'vue'

import router from '@/router'
import store from '@/store'

import App from './App.vue'
import { useCommonStore } from './store/common'
import { useUserStore } from './store/user'
import { token } from './utils/token'

const app = createApp(App)

app.use(router).use(store).mount('#app')

const userStore = useUserStore()
const commonStore = useCommonStore()

const WHITELIST = ['/login']

router.beforeEach(async (to, from, next) => {
  if (WHITELIST.includes(to.path) || to.name === 'NotFound') {
    next()
  } else if (token.get()) {
    if (to.path === '/login') {
      const { redirect } = to.query
      next((redirect as string) ?? '/')
    } else {
      await userStore.getUserInfo()
      await commonStore.getSchoolInfo()
      if (userStore.userInfo && commonStore.schoolInfo) {
        next()
      } else {
        next({ path: '/login', query: { redirect: to.path } })
      }
    }
  } else {
    next({ path: '/login', query: { redirect: to.path } })
  }
})
