import {
  GraphicArrow,
  IGraphicArrowConfig,
} from '../../GraphicEditor/graphics/Arrow'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type IArrowConfig = IGraphicArrowConfig & IMaterialConfig
export interface Arrow extends Material {}
export class Arrow extends GraphicArrow {
  constructor(arrowConfig: IArrowConfig) {
    super({
      ...arrowConfig,
    })
    mixinMaterial(this, arrowConfig)
  }
}
