import localforage from 'localforage'

// 学校管理后台中的学校信息
export const schoolInfoStorage = localforage.createInstance({
  name: 'schoolInfoStorage',
})

// 学校管理后台用的用户信息
export const schoolUserInfo = localforage.createInstance({
  name: 'schoolUserInfo',
})
