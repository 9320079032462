import {
  GraphicRegularPolygon,
  IGraphicRegularPolygonConfig,
} from '../../GraphicEditor/graphics/RegularPolygon'
import { IMaterialConfig, Material, mixinMaterial } from './Material'

export type IRegularPolygonConfig = IGraphicRegularPolygonConfig &
  IMaterialConfig
export interface RegularPolygon extends Material {}
export class RegularPolygon extends GraphicRegularPolygon {
  constructor(regularPolygonConfig: IRegularPolygonConfig) {
    super({
      ...regularPolygonConfig,
    })
    mixinMaterial(this, regularPolygonConfig)
  }
}
