import { Optional } from '@lanyan/type'
import { proxy } from 'comlink'
import { Image, ImageConfig } from 'konva/lib/shapes/Image'
import { omit } from 'lodash-es'

import { isWebWorker } from '../../env'
import { fileToUrl } from '../../file'
import { EGraphicEvent } from '../config'

export type IKonvaVideoConfig = ImageConfig & {
  url?: string
  file?: File
  createVideoHandler: (
    url: string,
    options: {
      needCaptureFirstFrame: boolean
      cbs: {
        onLoadFirstFrame: (params: { imageBitmap: ImageBitmap }) => void
        onImageBitmap: (params: { imageBitmap: ImageBitmap }) => void
        onPlay: () => void
        onPause: () => void
        onPlaying: () => void
        onWaiting: () => void
        onEnded: () => void
        onSeeked: () => void
        onCanPlay: () => void
        onSeeking: () => void
        onProgress: () => void
      }
    },
  ) => Promise<
    Optional<{
      play: () => Promise<boolean>
      pause: () => void
      stop: () => void
      seek: (time: number) => void
      getCurrentTime: () => number
      getDuration: () => number
      getPaused: () => boolean
      destroy: () => void
      getImageBitmap: () => Promise<{ imageBitmap: Optional<ImageBitmap> }>
    }>
  >
  getMediaUrl?: (file: File) => Promise<string>
}

export class KonvaVideo extends Image {
  imageBitmap?: ImageBitmap

  // 初次绘制是否完成。
  firstDrawIsComplete = false

  url?: string

  videoHandler?: Awaited<ReturnType<IKonvaVideoConfig['createVideoHandler']>>

  #createVideoHandler: IKonvaVideoConfig['createVideoHandler']

  constructor(videoConfig: IKonvaVideoConfig) {
    super({ width: 0, height: 0, ...omit(videoConfig, 'video') })

    this.#createVideoHandler = videoConfig.createVideoHandler

    this.load({ ...videoConfig, isFirstDraw: true })
  }

  // 视频宽高比。
  get ratio() {
    if (this.imageBitmap) {
      return this.imageBitmap.width / this.imageBitmap.height
    }
  }

  // 加载视频。
  async load({
    file,
    url,
    getMediaUrl,
    isFirstDraw,
  }: Pick<IKonvaVideoConfig, 'url' | 'file' | 'getMediaUrl'> & {
    isFirstDraw?: boolean
  }) {
    const sourceUrl = url || (file && fileToUrl(file))
    if (!sourceUrl) {
      return
    }

    const oldUrl = url
    this.url = url
    const options = {
      cbs: {
        onCanPlay: this.#onCanPlay,
        onEnded: this.#onEnded,
        onPause: this.#onPause,
        onPlay: this.#onPlay,
        onPlaying: this.#onPlaying,
        onProgress: this.#onProgress,
        onSeeked: this.#onSeeked,
        onSeeking: this.#onSeeking,
        onWaiting: this.#onWaiting,
        onImageBitmap: this.#onImageBitmap,
        onLoadFirstFrame: async ({
          imageBitmap,
        }: {
          imageBitmap: ImageBitmap
        }) => {
          this.#drawVideo(imageBitmap)
          this.fire(EGraphicEvent.LOAD_MEDIA_SUCCESS)
          if (isFirstDraw) {
            requestAnimationFrame(() => {
              this.firstDrawIsComplete = true
              this.fire(EGraphicEvent.FIRST_DRAW_COMPLETE)
            })
          }

          if (this.url) {
            if (oldUrl && oldUrl !== url) {
              this.fire(EGraphicEvent.URL_CHANGE)
            }
          } else if (file && getMediaUrl) {
            this.fire(EGraphicEvent.GETTING_URL)
            this.url = await getMediaUrl(file)
            if (this.url) {
              this.fire(EGraphicEvent.GET_URL_SUCCESS)
              this.fire(EGraphicEvent.URL_CHANGE)
            } else {
              this.fire(EGraphicEvent.GET_URL_FAIL)
            }
          }
        },
      },
      needCaptureFirstFrame: true,
    }

    this.fire(EGraphicEvent.START_LOAD_MEDIA)
    this.videoHandler = await this.#createVideoHandler.call(
      null,
      sourceUrl,
      isWebWorker() ? proxy(options) : options,
    )
  }

  #drawVideo(imageBitmap: ImageBitmap) {
    this.imageBitmap = imageBitmap
    this.image(imageBitmap)
  }

  #onImageBitmap = ({ imageBitmap }: { imageBitmap: ImageBitmap }) => {
    this.#drawVideo(imageBitmap)
  }

  #onPlay = () => {
    this.fire(EGraphicEvent.PLAY)
  }

  #onProgress = () => {
    this.fire(EGraphicEvent.PROGRESS)
  }

  #onSeeking = () => {
    this.fire(EGraphicEvent.SEEKING)
  }

  #onSeeked = () => {
    this.fire(EGraphicEvent.SEEKED)
  }

  #onCanPlay = () => {
    this.fire(EGraphicEvent.CAN_PLAY)
  }

  #onEnded = () => {
    this.fire(EGraphicEvent.ENDED)
  }

  #onPlaying = () => {
    this.fire(EGraphicEvent.PLAYING)
  }

  #onWaiting = () => {
    this.fire(EGraphicEvent.WAITING)
  }

  #onPause = () => {
    this.fire(EGraphicEvent.PAUSE)
  }

  // 播放。
  play(
    ...params: Parameters<
      NonNullable<
        Awaited<ReturnType<IKonvaVideoConfig['createVideoHandler']>>
      >['play']
    >
  ) {
    return this.videoHandler?.play(...params)
  }

  // 暂停。
  pause(
    ...params: Parameters<
      NonNullable<
        Awaited<ReturnType<IKonvaVideoConfig['createVideoHandler']>>
      >['pause']
    >
  ) {
    return this.videoHandler?.pause(...params)
  }

  // 停止。
  stop(
    ...params: Parameters<
      NonNullable<
        Awaited<ReturnType<IKonvaVideoConfig['createVideoHandler']>>
      >['stop']
    >
  ) {
    return this.videoHandler?.stop(...params)
  }

  // 定位。
  seek(
    ...params: Parameters<
      NonNullable<
        Awaited<ReturnType<IKonvaVideoConfig['createVideoHandler']>>
      >['seek']
    >
  ) {
    return this.videoHandler?.seek(...params)
  }

  // 当前播放时间。
  get currentTime() {
    return this.videoHandler?.getCurrentTime()
  }

  // 视频时长。
  get duration() {
    return this.videoHandler?.getDuration()
  }

  // 当前视频是否已经暂停。
  get paused() {
    return this.videoHandler?.getPaused()
  }

  // 删除。
  remove() {
    this.videoHandler?.destroy()

    return super.remove()
  }
}
